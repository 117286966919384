import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslationService } from './core/services/translation.service';

// language list
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';
import { ModalGenericService } from './services/modal-generic.service';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Subject} from "rxjs";
import {CheckMobileService} from "@app/core/check-mobile.service";
import {takeUntil} from "rxjs/operators";
import {ClassificationAddeditModalComponent} from "@app/classification-section/classification-list/classification-addedit-modal/classification-addedit-modal.component";
import {ModalCheckMobileComponent} from "@app/modal-check-mobile/modal-check-mobile.component";
import {MatDialog} from "@angular/material/dialog";
import {StorageService} from "@app/core/storage.service";
import {UserService} from "@app/core/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy{
  pagesWithCheckMobile: any[] = ['principal', 'login'];
  onDestroy: Subject<any> = new Subject<any>();
  constructor(
    private translationService: TranslationService,
    private afs: AngularFirestore,
    private events: ModalGenericService,
    private router: Router,
    private checkMobile: CheckMobileService,
    private  dialog:  MatDialog,
    private storageService: StorageService,
    private userService: UserService
  ) {

    // register translations
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
    console.log('Url actual', this.router.url);
    this.router.events.pipe(takeUntil(this.onDestroy)).subscribe((event) => {
        if (event instanceof NavigationStart) {
            if (this.pagesWithCheckMobile.find(item => event.url.includes(item))) {
                if (this.checkMobile.any()) {
                    console.log(this.checkMobile.othersModels());
                    this.showModalCheckMobile();
                }
            }
        }
    });
    if (!this.storageService.getListTimeZones()) {
        this.userService.getListTimeZones().pipe(takeUntil(this.onDestroy)).subscribe((response) => {
            if (response && response.status && response.status === 'ok') {
                this.storageService.setListTimeZones(response.data);
            }
        });
    }
  }

  showModalCheckMobile() {
      let dialog = this.dialog.open(ModalCheckMobileComponent, {
          data: {isIos: this.checkMobile.iOS(), isAndroid: this.checkMobile.android(), isOthers: this.checkMobile.othersModels()}
      });
  }

  ngOnDestroy() {
      this.onDestroy.next();
      this.onDestroy.unsubscribe();
  }
}
