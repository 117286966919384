import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import {BehaviorSubject, Subject, timer} from "rxjs";
import {Observable} from "rxjs/Observable";
import {map, shareReplay, takeUntil} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TimezoneConversionService {
  dataCurrentTime$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  clock$: Observable<Date>;
  constructor() { }

  public getTimeConvertedTimeZone(date, timeZoneRef) {
    let convertedDate = new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone: timeZoneRef}));
    const timeFormat12 = Moment((convertedDate.getHours() + '.' + convertedDate.getMinutes()), ["HH.mm"]).format("hh:mm a");
    const timeFormat24 = Moment((convertedDate.getHours() + '.' + convertedDate.getMinutes()), ["HH.mm"]).format("HH:mm:ss");
    const timeFormat242 = Moment((convertedDate.getHours() + '.' + convertedDate.getMinutes()), ["HH.mm"]).format("HH:mm");
    let dd = (convertedDate.getDate() < 10 ? '0' : '')
        + convertedDate.getDate();

    let MM = ((convertedDate.getMonth() + 1) < 10 ? '0' : '')
        + (convertedDate.getMonth() + 1);

    let formatResult = {
      fecha: dd + '/' + MM + '/' + convertedDate.getFullYear(),
      fecha2: convertedDate.getFullYear() + '-' + MM + '-' + dd,
      hora24: timeFormat24,
      hora24Ms: timeFormat242,
      hora12: timeFormat12,
      fechayhora: dd + '/' + MM + '/' + convertedDate.getFullYear() + ' ' + timeFormat24,
      formatDashboard: convertedDate.getFullYear() + '-' + MM + '-' + dd + ' ' + timeFormat24,
      timestamp: convertedDate.getTime()
    }
    //console.log('Formato final', formatResult);
    return formatResult;
  }

  public timezoneClientOffset(date, tz) {
    let convertedDate = typeof date === 'string' ? new Date(date.replace(/-/g, "/")) : date;
    const utc = new Date(convertedDate.getTime())
    const dHome = new Date(convertedDate.toLocaleString('en-US', { timeZone: tz }))
    const diff = Math.round((utc.valueOf() - dHome.valueOf()) / 60000) // 60*1000 => minutes
    convertedDate.setMinutes(convertedDate.getMinutes() + diff)
    return convertedDate
  }

  public initCurrentTime(onDestroy: Subject<any>) {
      this.clock$ = timer(0,1000).pipe(takeUntil(onDestroy),map(t => new Date()), shareReplay(1));
  }

  public getInfoCurrentTime$(tz, label): Observable<any> {
      this.clock$.subscribe(t => {
          let currentDatetime = this.getTimeConvertedTimeZone(t, tz);
          let result = {time: currentDatetime.hora24Ms, date: currentDatetime.fecha2, label: label}
          this.dataCurrentTime$.next(result);
      });
      return this.dataCurrentTime$.asObservable();
  }
}
