import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  from,
  throwError,
  of,
} from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
  HttpParams,
} from '@angular/common/http';

import 'rxjs/add/operator/catch';

import { Http } from '@angular/http';

import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { ordenesObj } from '../models/order';
import { ItemEvent } from '../models/evidence';
import { Router } from '@angular/router';
import { ConfigCustomer } from '../models/customer';
import { error } from 'util';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

interface itemsConfigObj {
  name: string;
  rows: itemsConfigDetails[];
}

interface itemsConfigDetails {
  id?: number;
  title: string;
  nextScreen: string;
  url_icon: string;
  questionary_id?: number;
  isExtraItem?: boolean;
  isDeleted?: boolean;
}

interface itemsExtra {
  doc?: string;
  items_extra: itemsConfigDetails[];
}

@Injectable()
export class UserService {
  public customerDB: firebase.database.Database = null;
  public items_config: itemsConfigObj = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = '';
  api_customer_key = '';

  constructor(
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router,
    private storage: AngularFireStorage,

  ) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = this.customerDB.app.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: value.name,
          photoURL: user.photoURL,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  getItemsConfiguration() {
    return new Promise((resolve, reject) => {
      let this_ = this;
      let firebase_tenan = this.getTenancyParams();
      //this.customerDB.app
        //.firestore()
        firebase_tenan
        .collection('items_config')
        .get()
        .then((querySnapshot) => {
          this_.items_config = null;
          querySnapshot.forEach((doc: any) => {
            this_.items_config = {
              name: doc.data().name,
              rows: doc.data().rows,
            };
          });

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getExtraItemsConfig(order_id: string) {
    return new Promise((resolve, reject) => {
      let this_ = this;

      var myObj: itemsExtra = null;
      var extra_items: itemsConfigDetails[] = [];
      var current_doc = '';

      let firebase_tenan = this.getTenancyParams();
      //this.customerDB.app
        //.firestore()
        firebase_tenan
        .collection('orders')
        .doc(order_id)
        .collection('items_config')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc: any) => {
            current_doc = doc.id;
            extra_items = doc.data().items_extra;
          });

          myObj = { doc: current_doc, items_extra: extra_items };
          resolve(myObj);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getInfoOrder(order_id: string) {
    return await new Promise(async (resolve, reject) => {
      var new_order: ordenesObj = null;
      let firebase_tenan = this.getTenancyParams();
      await firebase_tenan
        .collection('orders')
        .doc(order_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            new_order = {
              firebase_id: doc.id,
              order_number: doc.data().order_number.toString(),
              customer_name: doc.data().customer_name,
              order_name: doc.data().order_name,
              address: doc.data().address,
              rows: doc.data().rows,
              users_all: doc.data().users_all,
              start_date: doc.data().start_date,
              end_date: doc.data().end_date,
              latitude: doc.data().latitude,
              longitude: doc.data().longitude,
              users: doc.data().users,
              status: doc.data().status,
              user_uid: doc.data().user_uid,
              user_name: doc.data().user_name,
              geolocation: doc.data().geolocation,
              address_checkIn: doc.data().address_checkIn,
              scheduled: doc.data().scheduled,
              category: doc.data().category,
              scheduled_periodicity: doc.data().scheduled_periodicity,
              scheduled_hour_limit: doc.data().scheduled_hour_limit,
              scheduled_hour_since: doc.data().scheduled_hour_since,
              scheduled_date_programming: doc.data().scheduled_date_programming,
              scheduled_programming_date: doc.data().scheduled_programming_date,
              scheduled_address: doc.data().scheduled_address,
              scheduled_assigned_to: doc.data().scheduled_assigned_to,
              scheduled_map_latitude: doc.data().scheduled_map_latitude ? doc.data().scheduled_map_latitude : 0,
              scheduled_map_longitude: doc.data().scheduled_map_longitude ? doc.data().scheduled_map_longitude : 0,
              scheduled_user_uuid: doc.data().scheduled_user_uuid,
              scheduled_date_creation: doc.data().scheduled_date_creation,
              scheduled_expiration_date: doc.data().scheduled_expiration_date,
              
              classification_category_uid: doc.data().classification_category_uid ? doc.data().classification_category_uid : "",
              classification_subcategory_uid: doc.data().classification_subcategory_uid ? doc.data().classification_subcategory_uid: "",
              classification_category_name: doc.data().classification_category_name ? doc.data().classification_category_name : "",
              classification_subcategory_name: doc.data().classification_subcategory_name ? doc.data().classification_subcategory_name : "",
              
              history_transfer_names: doc.data().history_transfer_names ? doc.data().history_transfer_names : [],
              authorization_status: doc.data().authorization_status ? doc.data().authorization_status : null,
            };
          }

          resolve(new_order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getDataClassification(){
    return new Promise<any>((resolve, reject) => {
      let this_ = this;
      let data = [];
      //let query = this.firestore.firestore.collection("events_by_user").doc(this_.user_uid).collection('events').doc(id_event).collection('items')
      let firebase_tenan = this.getTenancyParams();
      let query = firebase_tenan.collection('classification');
      query.get()
      .then(querySnapshot2 => {
        console.log('querySnapshot2', querySnapshot2);
        if(querySnapshot2.docs.length > 0){
          querySnapshot2.forEach((doc: any) => {
            let mapData = doc.data();
            mapData['categories'] = [];

            let querycategories = firebase_tenan.collection('classification').
            doc(doc.id).collection('categories').where('deleted', '==', false);

            querycategories.get().then(resultCategories => {
              console.log('querySnapshot3', resultCategories);

              resultCategories.forEach((cat : any) => {
                let tmpCat = cat.data();
                tmpCat['subcategories'] = [];

                let querysubcategories = firebase_tenan.collection('classification').doc(doc.id).collection('categories').doc(cat.id).collection('subcategories').where('deleted', '==', false);

                querysubcategories.get().then(resultSubCategories => {
                  console.log('querySnapshot3', resultSubCategories);

                  resultSubCategories.forEach((sub : any) => {
                    let tmpSub = sub.data();
                    //console.log('resultados subclasificacion', tmpSub);
                    tmpCat['subcategories'].push(tmpSub);
                  });

                })
                .catch(error => {
                  console.log('categories', error);
                });

                mapData['categories'].push(tmpCat);
              });



            })
            .catch(error => {
              console.log('categories', error);
            });
            data.push(mapData);
          });
          resolve(data);
        }else{
          resolve(null);
        }
      })
      .catch(error => {
        console.log('getDataClassification', error);
      })

    });
  }

  getDataNameClassification(){
    return new Promise<any>((resolve, reject) => {
      //let query = this.firestore.firestore.collection("events_by_user").doc(this_.user_uid).collection('events').doc(id_event).collection('items')
      let firebase_tenan = this.getTenancyParams();
      let query = firebase_tenan.collection('classification');
      query.get()
      .then(querySnapshot2 => {
        console.log('querySnapshot2', querySnapshot2);
        if(querySnapshot2.docs.length > 0){
          querySnapshot2.forEach((doc: any) => {

            resolve(doc.data());

          });
        }else{
          resolve(null);
        }
      })
      .catch(error => {
        console.log('getDataClassification', error);
      })

    });
  }
  
  getSettingsLazy(){
    return new Promise((resolve, reject) => {
      let firebase_tenan = this.getTenancyParams();
      firebase_tenan
        .collection('settings')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc: any) => {

            resolve(doc.data());

          });

        })
        .catch((error) => {
          reject(error);
        });
        
    });
  }
  getEventInfoOrder(id:string){
    return new Promise((resolve, reject) => {
      
        let firebase_tenan = this.getTenancyParams();
        firebase_tenan
        .collection('modules')
        .doc(id)
        .get()
        .then((doc) => {

          let response = {
            name: doc.data().name,
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllEvidenceByOrder(order_id: string) {
    return new Promise((resolve, reject) => {
      var evidencia: ItemEvent[] = [];

        let firebase_tenan = this.getTenancyParams();
        firebase_tenan
        .collection('orders')
        .doc(order_id)
        .collection('evidence')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc: any) => {
            evidencia.push({
              id: doc.data().id,
              title: doc.data().title,
              description: doc.data().description,
              photos: doc.data().photos,
              last_signed_name: doc.data().last_signed_name,
              last_signed_customer_name: doc.data().last_signed_customer_name,
              signatures: doc.data().signatures,
              questions: doc.data().questions,
              maps: doc.data().maps,
              itemId_assigned: doc.data().itemId_assigned,
            });
          });

          resolve(evidencia);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createNewReport(template: string) {
    var data = {
      report_pdf: template,
    };

    return new Promise((resolve, reject) => {
        let firebase_tenan = this.getTenancyParams();
        firebase_tenan
        .collection('reports')
        .add(data)
        .then((res) => {
          resolve(res.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUser(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getUserByID';
    return this.http.post(url, body, { headers });
  }

  getAvatares(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getAvatares';
    return this.http.get(url, { headers });
  }

  getUsersList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getAllUsers';
    return this.http.get(url, { headers });
  }
  getUsersListBigquery(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getAllUsersBigquery';
    return this.http.get(url, { headers });
  }

  getUserLimit(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getUserLimit';
    return this.http.get(url, { headers });
  }

  getUsersListModuleBigquery(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getAllUsersBigquery';
    return this.http.post(url,body, { headers });
  }

  getFilterNameUsersBigquery(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getFilterNameUsersBigquery';
    return this.http.post(url, body, { headers });
  }

  deleteUserList(userlist: []): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    this.deleteImages(userlist);
    const url = environment.urlEndpointMaster + '/deleteUser';
    return this.http.post(url, userlist, { headers });
  }
  deleteUserListBigquery(userlist: []): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    this.deleteImages(userlist);
    const url = environment.urlEndpointMaster + '/deleteUserBigquery';
    return this.http.post(url, userlist, { headers });
  }

  unLinkUser(userlist: []): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/unLinkUser';
    return this.http.post(url, userlist, { headers });
  }

  createUser(body: any): Observable<any> {
    // current fields
    // "email"      : "ricardo.gaona@webpoint.mx",
    // "password"   : "Q1w2e3r$",
    // "displayName": "Ricardo Gaona Cardenas",
    // "disabled"   : false,
    // "profile"    : "SOPORTE"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url =
      environment.urlEndpointMaster +
      (!body.uid ? '/createUser' : '/updateUser');
    return this.http.post(url, body, { headers });
  }

  getDetailUser(uid: string): Promise<any> {
    let firebase_tenan = this.getTenancyParams();
    return firebase_tenan
      .collection('users_details')
      .where('uid', '==', uid)
      .get();
  }

  forgotpassword(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/forgotPassword';
    return this.http.post(url, body, { headers });
  }

  resendWelcomeEmail(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/resendWelcomeEmail';
    return this.http.post(url, body, { headers });
  }

  validJwtForResetPassword(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/validJwtForResetPassword';
    return this.http.post(url, body, { headers });
  }

  resetPassword(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/resetPassword';
    return this.http.post(url, body, { headers });
  }

  changePassword(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: environment.headerAuthorization,
      'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/changePassword';
    console.log("body nuevo campo");
    console.log(body);
    return this.http.post(url, body, { headers });
  }

  uploadPhoto(uid: string, photo: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const ref = this.customerDB.app.storage().ref(`imgprofile/${uid}`);
      const part1 = photo.split(';')[1];
      const base64 = part1.split(',')[1];
      const task = ref.putString(base64, 'base64', {
        contentType: 'image/jpeg',
      });
      task
        .then(async () => {
          const downloadURL = await ref.getDownloadURL();
          resolve(downloadURL);
        })
        .catch((err) => {
          if (err.code == 'storage/canceled') return resolve(null);
          console.log(err);
          reject(err.message);
        });
    });
  }

  deleteImages(uids: string[]): void {
    uids.forEach((uid) => {
      this.customerDB.app.storage().ref(`imgprofile/${uid}`).delete();
    });
  }

  getSettingsClient(): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/getSettings';
    return this.http.get(url, { headers });
  }

  uploadLogoClient(uid: string, photo: string): Promise<string> {

    return new Promise((resolve, reject) => {
      const ref = this.customerDB.app.storage().ref(`logo_client/${uid}`);
      const part1 = photo.split(';')[1];
      const base64 = part1.split(',')[1];
      const task = ref.putString(base64, 'base64', {
        contentType: 'image/jpeg',
      });
      task
        .then(async () => {
          const downloadURL = await ref.getDownloadURL();
          resolve(downloadURL);
        })
        .catch((err) => {
          if (err.code == 'storage/canceled') return resolve(null);
          console.log(err);
          reject(err.message);
        });
    });
  }

  saveConfigurationClient(body: any): Observable<any> {
    const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': environment.headerAuthorization,
        'api-customer-key': sessionStorage.getItem('api_customer_key'),
    });
    const url = environment.urlEndpointMaster + '/saveConfigurationClient';
    return this.http.put(url, body, { headers });
  }


  getTenancyParams(){
    console.log('this.customerSelected.multi_tenancy', this.customerSelected.multi_tenancy);
    if(this.customerSelected.multi_tenancy){
      return this.customerDB.app.firestore().collection('clients').doc(this.customerSelected.multi_tenancy_doc);
    }else{
      return this.customerDB.app.firestore();
    }
  }

  uploadPhotoReference(uid: string, photo: string, path: string = 'img_references'): Promise<string> {
    return new Promise((resolve, reject) => {
      const ref = this.customerDB.app.storage().ref(`${path}/${uid}`);
      if (photo.split(';').length > 1) {
        const part1 = photo.split(';')[1];
        const base64 = part1.split(',')[1];
        const task = ref.putString(base64, 'base64', {
          contentType: 'image/jpeg',
        });
        task
            .then(async () => {
              const downloadURL = await ref.getDownloadURL();
              resolve(downloadURL);
            })
            .catch((err) => {
              if (err.code == 'storage/canceled') return resolve(null);
              console.log(err);
              reject(err.message);
            });
      } else {
         resolve(null);
      }
    });
  }

  getListTimeZones(): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': environment.headerAuthorization,
      });
      const url = environment.urlEndpointMaster + '/getListTimeZones';
      return this.http.get(url, { headers });
  }

  setReadNotifications(body: any): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': environment.headerAuthorization,
        'api-customer-key': this.api_customer_key,
      });
      const url = environment.urlEndpointMaster + '/setReadNotification';
      return this.http.post(url,body, { headers });
  }

  reasignedUser(body): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/reasignedUser';
    return this.http.post(url,body, { headers });
  }

}
