import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ProgramingService } from "../../services/programing.service";
import { MapsAPILoader } from "@agm/core";

@Component({
  selector: "app-programming-address",
  templateUrl: "./programming-address.component.html",
  styleUrls: ["./programming-address.component.scss"],
})
export class ProgrammingAddressComponent implements OnInit {
  form: FormGroup;
  validation_msg;
  btn_save = false;
  private geoCoder;

  forms = {
    scheduled_street: "",
    scheduled_colony: "",
    scheduled_num_ext: "",
    scheduled_num_int: "",
    scheduled_postal_address: "",
    scheduled_state: "",
    scheduled_town: "",
  };
  address_: any;
  constructor(
    private dialogRef: MatDialogRef<ProgrammingAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private programingservice: ProgramingService,
    private mapsAPILoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.setForm();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      if (this.data.id != 0) {
        this.forms.scheduled_street = this.data.dir.scheduled_street;
        this.forms.scheduled_colony = this.data.dir.scheduled_colony;
        this.forms.scheduled_num_ext = this.data.dir.scheduled_num_ext;
        this.forms.scheduled_num_int = this.data.dir.scheduled_num_int;
        this.forms.scheduled_postal_address =
          this.data.dir.scheduled_postal_address;
        this.forms.scheduled_state = this.data.dir.scheduled_state;
        this.forms.scheduled_town = this.data.dir.scheduled_town;
        console.log("this.forms", this.forms);
      }
    });
  }
  setForm() {
    this.form = new FormGroup({
      scheduled_street: new FormControl(
        this.forms.scheduled_street,
        Validators.required
      ),
      scheduled_colony: new FormControl(
        this.forms.scheduled_colony,
        Validators.required
      ),
      scheduled_num_ext: new FormControl(
        this.forms.scheduled_num_ext,
        Validators.required
      ),
      scheduled_num_int: new FormControl(this.forms.scheduled_num_int),
      scheduled_postal_address: new FormControl(
        this.forms.scheduled_postal_address,
        Validators.required
      ),
      scheduled_state: new FormControl(
        this.forms.scheduled_state,
        Validators.required
      ),
      scheduled_town: new FormControl(
        this.forms.scheduled_town,
        Validators.required
      ),
    });
    this.validationMsg();
  }

  validationMsg() {
    this.validation_msg = {
      scheduled_street: [
        {
          type: "required",
          message: "Por favor, ingresa la calle.",
        },
      ],
      scheduled_colony: [
        {
          type: "required",
          message: "Por favor, ingresa la colonia.",
        },
      ],
      scheduled_num_ext: [
        {
          type: "required",
          message: "Por favor, ingresa el numero exterior.",
        },
      ],
      scheduled_num_int: [
        {
          type: "required",
          message: "Por favor, ingresa el numero interior.",
        },
      ],
      scheduled_postal_address: [
        {
          type: "required",
          message: "Por favor, ingresa el codigo postal.",
        },
      ],
      scheduled_state: [
        {
          type: "required",
          message: "Por favor, ingresa el estado.",
        },
      ],
      scheduled_town: [
        {
          type: "required",
          message: "Por favor, ingresa el municipio.",
        },
      ],
    };
  }

  public closeMe() {
    let parameters = { button: "cerrar" };
    this.dialogRef.close(parameters);
  }

  aceptar() {
    this.form.controls.scheduled_street.markAsTouched();
    this.form.controls.scheduled_colony.markAsTouched();
    this.form.controls.scheduled_num_ext.markAsTouched();
    this.form.controls.scheduled_postal_address.markAsTouched();
    this.form.controls.scheduled_state.markAsTouched();
    this.form.controls.scheduled_town.markAsTouched();

    if (this.form.valid) {
      this.programingservice
        .getLatLongByAddress(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            this.form.controls.scheduled_street.value +
            this.form.controls.scheduled_num_ext.value +
            this.form.controls.scheduled_colony.value +
            this.form.controls.scheduled_postal_address.value +
            this.form.controls.scheduled_town.value +
            this.form.controls.scheduled_state.value +
            "&key=AIzaSyDFczirJGLWnwglz7g-DxgZXRvMVkE_rGQ"
        )
        .subscribe(async (response) => {
          await this.getAddress(
            response["results"][0].geometry.location.lat,
            response["results"][0].geometry.location.lng
          );
          let parameters = null;
          let street = this.address_.address_components.find(
            (x) =>
              x.long_name.toUpperCase() ===
              this.form.controls.scheduled_street.value.toUpperCase()
          );
          let colony = this.address_.address_components.find(
            (x) =>
              x.long_name.toUpperCase() ===
              this.form.controls.scheduled_colony.value.toUpperCase()
          );
          let num_ext = this.address_.address_components.find(
            (x) =>
              x.long_name.toUpperCase() ===
              this.form.controls.scheduled_num_ext.value.toUpperCase()
          );
          let geometry = response["results"][0].geometry.location;
          if (
            street === undefined ||
            colony === undefined ||
            num_ext === undefined
          ) {
            this.address_.formatted_address =
              this.form.controls.scheduled_street.value +
              " " +
              this.form.controls.scheduled_num_ext.value +
              ", " +
              this.form.controls.scheduled_colony.value +
              ", " +
              this.form.controls.scheduled_postal_address.value +
              " " +
              this.form.controls.scheduled_town.value +
              ", " +
              this.form.controls.scheduled_state.value +
              " ";
            geometry = null;
          }

          parameters = {
            button: "aceptar",
            form: this.form.value,
            address: this.address_.formatted_address,
            latitudes: geometry,
          };
          this.dialogRef.close(parameters);
        });
    }
  }

  async getAddress(latitude, longitude) {
    await this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: any, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.address_ = results[0];
          } else {
            window.alert("No se encontraron resultados.");
          }
        } else {
          window.alert("La busqueda ha fallado: " + status);
        }
      }
    );
  }
}
