import { Component, OnInit } from "@angular/core";
import { FormsService } from "../../core/forms.service";
import { AuthService } from "../../core/auth.service";
import { UtilsService } from "../../core/helper/utils.helper";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../../core/storage.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ModalGenericService } from "@app/services/modal-generic.service";
import { take } from "rxjs/operators";
import { AddItemDragComponent } from "@app/events/add-item-drag/add-item-drag.component";

@Component({
  selector: "app-bulk-load",
  templateUrl: "./bulk-load.component.html",
  styleUrls: ["./bulk-load.component.scss"],
})
export class BulkLoadComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddItemDragComponent>,
    public formsService: FormsService,
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public modelGeneric: ModalGenericService
  ) {}

  ngOnInit() {}

  downloadLayout() {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/layout.xlsx?alt=media&token=986e192e-4ec7-4c58-bcab-9cdb0f8a0567"
    );
    link.setAttribute("download", `layout.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
