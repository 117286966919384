import { ChangeDetectorRef, Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissions : any = {};
  constructor(public storageService: StorageService) {
  }

  public canDo(route:string, module:string){
    this.permissions = JSON.parse(this.storageService.getPermissionGroupUser());   
    if(!this.permissions) return true;     
    return this.permissions && this.permissions[route] && this.permissions[route].includes(module);
  }

  public canMenu(route:string){
    this.permissions = JSON.parse(this.storageService.getPermissionGroupUser());     
    
    if(!this.permissions) return true;
    // && this.permissions[route].length > 0
    let permiso = this.permissions[route] ? true: false;
    let index = false;
    if(permiso == true){
      index = this.permissions[route].includes('index');
    }

    return this.permissions && permiso && index;
  }

  public getPermissions(){
    this.permissions = JSON.parse(this.storageService.getPermissionGroupUser());       
    return this.permissions;
  }
}
