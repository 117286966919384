import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../core/user.service";
import { AuthService } from "../core/auth.service";
import { StorageService } from "../core/storage.service";
import { Router } from "@angular/router";
import { UtilsService } from "../core/helper/utils.helper";
import { TranslateService } from "@ngx-translate/core";
import { SettingsAdminwebService } from "../core/settings-adminweb.service";
import { PermissionService } from "../core/permission.service";
import { ProgrammingTableComponent } from "./programming-table/programming-table.component";
import { MatDialog } from "@angular/material/dialog";
import { ProgrammingAddComponent } from "./programming-add/programming-add.component";
import { take } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ProgramingService } from "../services/programing.service";
import { BulkLoadComponent } from "./bulk-load/bulk-load.component";
import { ProgrammingBulkloadComponent } from './programming-bulkload/programming-bulkload.component';

@Component({
  selector: "app-programming-list",
  templateUrl: "./programming-list.component.html",
  styleUrls: ["./programming-list.component.scss"],
})
export class ProgrammingListComponent implements OnInit {
  public current_component: any;

  get currentProgramming() {
    return this.storageService.getCurrentDB();
  }

  @ViewChild("current_page") current_page;
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public storageService: StorageService,
    private router: Router,
    private dialog: MatDialog,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    private spinner: NgxSpinnerService,
    public settingsService: SettingsAdminwebService,
    public permisionService: PermissionService,
    private programingservice: ProgramingService
  ) {}

  ngOnInit() {}

  add() {
    const dialogRef = this.dialog
      .open(ProgrammingAddComponent, {
        data: {
          id: 0,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp == "aceptar") {
          this.programingservice.publishSomeData({
            data: "actualizar",
          });
        }
      });
  }

  bulkLoad() {
    console.log("GO ADD ITEM");
    const dialogRef = this.dialog.open(BulkLoadComponent, {
      height: "auto",
      width: "80%",
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("DATA RESPONSE " + data);
    });
  }

  salir() {
    this.authService
      .doLogout()
      .then(() => {
        if (this.storageService.getCurrentDB() != "") {
          const ruta = "/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      })
      .catch((err) => {
        // console.error('Error LogOut -> ' + err);
        if (this.storageService.getCurrentDB() != "") {
          const ruta = "/" + this.storageService.getCurrentDB() + "/login";
          this.storageService.logout();
          this.router.navigate([ruta]);
        }
      });
  }

  onActivate(componentRef) {
    this.current_component = null;
    console.log(
      "componentRef",
      componentRef instanceof ProgrammingTableComponent
    );
    if (componentRef instanceof ProgrammingTableComponent) {
      this.current_component = componentRef;
    }
  }
  goupload(){
    const dialogRef = this.dialog.open(ProgrammingBulkloadComponent, {
      height: 'auto',
      width: '80%',
    });
    dialogRef.afterClosed().subscribe((data) => {
      console.log('DATA RESPONSE ' + data);
      if (data == "aceptar") {
        this.programingservice.publishSomeData({
          data: "actualizar",
        });
      }
     
    });
  }
}
