// USA
const activity = "activity";
const activityFirstMayus = "Actividad";
const activitiesFirstMayus = "Activities";
const activityMayus = "ACTIVITY";
const activities = "activities";
const task = "task";
const tasks = "tasks";
export const locale = {
  lang: "en",
  data: {
    MESSAGE: {
      SUCCESS: "Success!",
      ERROR: "Error",
      KEEP_LOOKING: "Keep looking",
    },
    TITLE: {
      NO_RESULTS: "NO RESULTS",
    },
    HEADER: {
      TITLE: {
        FIELD_ACTIVITY: "Field Activity",
        CUSTOMERS: "Customers",
        USERS: "Users",
        FORMULARIOS: activitiesFirstMayus + " & Forms",
        CLASSIFICATION: "Categories",
      },
      MENU: {
        LOGOUT: "Logout",
        PROFILE: "Profile",
        FIELD_ACTIVITY: "Field Activity",
        PROGRAMMING: "Programming",
        USERS: "Users",
        FORMULARIOS: activitiesFirstMayus + " & Forms",
        CUSTOMERS: "Customers",
        LIST_CUSTOMERS: "Customer list",
        CONFIGURATION: "Configuration",
        LOGO_COMPANY: "Logo company",
        CLASSIFICATION: "Categories",
        DASBOARD_REPORT: "Dashboard",
        WEBHOOK: "Webhook",
        TIME_ZONE: "Timezone",
        HELP: "Help",
        LABOR_HOUR: "WORKING HOURS",
      },
    },
    MENU: {
      TYPE_1: {
        TODAY: "Today",
        LAST_WEEK: "Last Week",
        LAST_MONTH: "Last Month",
        DATE_RANGE: "Date Range",
      },
      TYPE_2: {
        USER_LIST: "User list",
        USER_ADD: "Add user",
        USER_DELETE: "Delete user",
        USER_SET_MAX: "Max users",
      },
      TYPE_3: {
        FORMULARIO_LIST: "Form List",
        ALTA_FORMULARIO: "Add Form",
        EVENTS_LIST: activitiesFirstMayus + " List",
        ALTA_EVENTS: "Add " + activitiesFirstMayus,
        ELIMINAR_SELECTION: "Delete selection",
      },
      TYPE_4: {
        CUSTOMER_LIST: "Customer list",
        CUSTOMER_ADD: "Add customer",
      },
      SEARCH: {
        LIMPIAR: "Clear",
        BUSCAR: "Search",
        NOMBRE: activityFirstMayus + " name",
        TIPO: activityFirstMayus + " type",
        OFICINA: "Office",
        ROL: "Role",
        USUARIO: "User",
        CLIENT: "Client",
        CATEGORY: "Categoria",
        SUBCATEGORY: "Subcategoria",
        NOMBREPLACEHOLDER: "Ingresa el nombre de la actividad",
        TIPOPLACEHOLDER: "Selecciona un tipo de actividad",
        OFICINAPLACEHOLDER: "Selecciona una oficina",
        ROLPLACEHOLDER: "Selecciona un rol",
        USUARIOPLACEHOLDER: "Selecciona un usuario",
        CLIENTPLACEHOLDER: "Select a client",
        CATEGORYPLACEHOLDER: "Select a category",
        SUBCATEGORYPLACEHOLDER: "Select a subcategory",
        ACTIVIDADES:
          "Selecciona un periodo de tiempo o realiza una búsqueda avanzada en el mapa",
      },
      RESUMEN: {
        DURACION: "Duración",
        ENTRE: "Entre actividades",
        DISTANCIA: "Distancia entre puntos",
      },
    },
    ERRORS: {
      RESPONSE:
        "Something happend, please share this message with the system administrator",
    },
    PAGES: {
      LOGIN: {
        ERROR: {
          EMAIL: "Please, enter valid email",
        },
      },
      DASHBOARD: {
        ARCHIVED: "Archived",
        ARCHIVED_CONFIRM:
          "Are you sure you want to archived this " + activity + "?",
        ARCHIVED_OK: "The " + activity + " has been archived.",
        ARCHIVED_ERROR:
          "Something happend, please share this message with the system administrator.",
        DELETED: "Delete",
        DELETED_CONFIRM: "Are you sure to delete this " + activity + "?",
        DELETED_OK: "The " + activity + " has been successfully deleted.",
        DELETED_ERROR:
          "Something happend, please share this message with the system administrator.",
        TRANSFER: "Transfer",
      },
      MODOULE_USERS: "Users",
      TERMS_CONDITIONS: {
        ACEPTED: "Accept",
        DECLAIN: "Decline",
      },
      TRANSFER_ACTIVITY: {
        ASIGNED: "ASSIIGN",
        CANCEL: "Cancel",
        REASSING: "Assign to:",
        LEYEND: "Select the user to whom you want to assign the activity",
        ERROR: "Error in reassign the " + activity + " at new user",
      },
      USER_LIST: {
        SEARCH: {
          PLACEHOLDER: "Search...",
          BUTTON: "Search",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Active",
            INACTIVE: "Inactive",
            DELETE_TITLE: "Are you sure you want to delete the user?",
            DELETE_SUCCESS: "The user has been deleted",
            CANCEL_DELETE: "No",
            CONFIRM_DELETE: "Yes",
            EDIT_PROFILE: "Editar perfil",
          },
          OPTIONS: {
            RESTOREPASS: "Password recovery",
            ACTIVATE: "Activate user",
            DEACTIVATE: "Inactivate user",
            DELETE: "Delete user",
            RESEND: "Resend invitation",
          },
        },
        PAGINATION: {
          PREVIOUS: "Previous",
          NEXT: "Next",
        },
        RESEND_EMAIL_RESTORE_PASS: "Email sent successfully",
        ERROR_EMAIL_RESTORE_PASS: "Something went wrong: {{ error }}",
        SET_MAX_USERS_MODAL: "Max records allowed:",
        SET_MAX_USERS_MODAL_ERROR: "You must enter a number",
        SET_MAX_USERS_ERROR: "Something went wrong: {{ error }}",
        SET_MAX_SUCCESSFUL: "Successful update",
      },
      USER_ADD: {
        FORM: {
          TITLE: "Add user",
          TITLEEDIT: "Edit user",
          PHOTO: "Photo",
          FIRST_NAME: "First name",
          LAST_NAME: "Last name",
          EMAIL: "Email",
          PHONE: "Phone",
          JOB: "Job",
          CITY: "City",
          ROLE: "Role",
          AVATAR: "Avatar",
          ROLES_TYPES: {
            ADMINISTRATOR: "Administrator",
            SUPERVISOR: "Supervisor",
            USER: "User",
            CUSTOMER: "Customer",
            SUPPORT: "Support",
            FIELD: "Field",
          },
          STATUS: "Status",
          STATUS_TYPES: {
            ACTIVE: "Active",
            INACTIVE: "Inactive",
            SUSPENDED: "Suspended",
            LOCKED: "Locked",
            BANNED: "Banned",
          },
          PASSWORD: "Password",
          REPEAT_PASSWORD: "Repeat password",
          BUTTONS: {
            SAVE: "Save",
            CANCEL: "Cancel",
            CLEAN: "Clean",
          },
          OFFICE: "Office",
          DEPARTMENT: "Department",
        },
        TITLENOTIFICATION: "Notification per client",
        TITLEACTIVITIES: activitiesFirstMayus + " per client",
        ERRORS: {
          "AUTH/EMAIL-ALREADY-EXISTS":
            "The email address is already in use by another account.",
          EMPTY_FIRST_NAME: "First name is empty",
          EMPTY_LAST_NAME: "Last name is empty",
          EMPTY_EMAIL: "Email is empty",
          WRONG_EMAIL: "Invalid Email",
          FORMAT_EMAIL: "Please, enter a valid email",
          EMPTY_ROLE: "Role is empty",
          EMPTY_STATUS: "Status is empty",
          EMPTY_PASSWORD: "Password is empty",
          DIFERENT_PASSWORD: "Please confirm the same password",
          GENERAL: "Please,provide the field ",
        },
        SUCCESS: {
          SAVED: "The user has been created successfully",
          UPDATED: "Successfully updated user",
          ENABLED: "The user has been activated",
          DISABLED: "The user has been deleted",
        },
      },
      USER_DELETE: {
        R_U_SURE: "Are you sure?",
        R_U_SURE_TEXT:
          "Are you sure that you want to delete the selected records?",
        DELETED: "The users have been successfully deleted",
        ERRORS: {
          RESPONSE:
            "Something happend, please share this message with the system administrator",
        },
      },
      FORMS_LIST: {
        SEARCH: {
          PLACEHOLDER: "Search...",
          BUTTON: "Search",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Active",
            INACTIVE: "Inactive",
          },
        },
        PAGINATION: {
          PREVIOUS: "Previous",
          NEXT: "Next",
        },
        TABLE: {
          NAME: "Name",
          STATUS: "Status",
          ACTION: "Action",
        },
      },
      FORM_ADD: {
        FORM: {
          DISPLAY_NAME: "Form title",
          QUESTIONS: "Questions",
          WRITE_HERE: "Write the question",
          INSTRUCTION_TITLE: "Please enter the title of the instruction.",
          INSTRUCTION_TEXT: "Please enter the instructions to be displayed.",
          URL: "URL",
          DEFINITION: "Definition",
          DEFINITION_VALIDATE: " Please enter the definition in JSON format.",
          DEFINITION_VALIDATE_JSON:
            "Please enter the definition in valid JSON format.",
          URL_VALIDATE: "Please enter the API URL information.",
          BODY: "Body",
          HEADER: "Headers",
          HEADER_VALIDATE:
            "Please enter the header information in JSON format.",
          HEADER_VALIDATE_JSON:
            "Please enter the header information in valid JSON format.",
          INFO_REFERENCE:
            "*Las fotos de referencia solo se visualizan teniendo acceso a internet (en caso de estar en modo off-line, podrá tomar las fotos pero no visualizará la foto de referencia).",
          INFO_INSTRUCTIONS:
            "Las imágenes de las instrucciones solo se visualizan teniendo acceso a internet (en caso de estar en modo off-line, podrá ver las instrucciones pero no visualizará las imágenes adjuntas). Maximum images allowed: 5",
          TYPE_OPTIONS: {
            TEXT: "Open (Text)",
            CHECK: "Binary (Yes / No)",
            DROPDOWN: "Dropdown (one option)",
            DROPDOWN_SEARCH: "Predicative search",
            DROPDOWN_MULTIPLE: "Dropdown (Multiple options)",
            TEXTAREA: "Comment area",
            TELEPHONE: "Telephone",
            EMAIL: "Email",
            PERCENTAGE: "Percentage",
            CALCULATOR: "Calculator",
            QUALIFICATION: "Qualification",
            QR: "QR Reader",
            BARCODE: "Barcode",
            INSTRUCTION: "Instruction",
            PHOTO: "Photo Evidence",
          },
          BUTTONS: {
            SAVE: "Save",
            ACCEPT: "Accept",
            CANCEL: "Cancel",
            CLEAN: "Clean",
            ADD: "Add",
            UPLOAD_IMAGES: "Upload Image",
            UPLOAD_THUMBNAIL: "Upload Thumbnail",
          },
        },
        ERRORS: {},
        SUCCESS: {
          SAVED: "Successfully created new form",
          UPDATED: "Successfully updated form",
        },
      },
      FORM_DELETE: {
        CLONED: "the custom " + task + "  was successfully cloned",
        R_U_SURE: "The form will be removed",
        R_U_SURE_TEXT:
          "The form will no longer be available for configuration in an " +
          activity,
        DELETED: "The selected form has been deleted",
        ERRORS: {
          RESPONSE:
            "Something happened, please share this message with the system administrator",
          CLONED: "Failed to clone custom " + task,
        },
      },
      EVENTS_LIST: {
        SEARCH: {
          PLACEHOLDER: "Search...",
          BUTTON: "Search",
        },
        CLEAN_FILTERS: {
          BUTTON: "Clean filters",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Active",
            INACTIVE: "Inactive",
          },
        },
        PAGINATION: {
          PREVIOUS: "Previous",
          NEXT: "Next",
        },
        TABLE: {
          NAME: "Name",
          FORMS: "Related forms",
          ORDER: "Order",
          STATUS: "Status",
          DATE: "Registration date",
          ACTION: "Action",
        },
      },
      EVENTS_ADD: {
        FORM: {
          DISPLAY_NAME: activityFirstMayus + " title",
          ORDER: "Order",
          CREATE_ORDERS: "Create Orders",
          SELECT_CUSTOMER: "Select customer",
          SELECT_EQUIPMENT: "Select equipment",
          SELECT_CARRET: "Select image gallery",
          QUESTIONS: "Questions",
          FORMS: "Choose forms",
          SELECT_AUTORIZE_EVENTS: "Allow to authorize" + activities,
          SELECT_TRANSFER_EVENTS: "Allow transfer " + activities,
          TOOLTIP_TRANSFER_EVENTS:
            "It will allow the activity to be transferred to other users from the application.",
          TOOLTIP_CARRET:
            "Allows adding photos from camera roll when adding a photo in the mobile app.",
          WRITE_HERE: "Write the question",
          REJECT: "Reject Assignment",
          TOOLTIP_REJECT: "Allows Reject Assignment",
          TYPE_OPTIONS: {
            TEXT: "Open (Text)",
            CHECK: "Binary (Yes / No)",
            DROPDOWN: "Dropdown (one option)",
            DROPDOWN_MULTIPLE: "Dropdown (Multiple options)",
            TEXTAREA: "Comment area",
          },
          BUTTONS: {
            SAVE: "Save",
            CANCEL: "Cancel",
            CLEAN: "Clean",
          },
        },
        ERRORS: {},
        XLSX: {
          DOWNLOAD: "Download report of",
          DOWNLOAD_BTN: "Download",
          DOWNLOAD_CANCEL: "Cancel",
          MESSAGE:
            "*The download of the report may take time, please do not close the pop-up window until the document is downloaded.",
          ERROR: {
            EMPTY: "Please enter date ranges.",
          },
        },
        SUCCESS: {
          SAVED: "Successfully created new " + activity,
          UPDATED: "Successfully updated " + activity,
        },
      },
      EVENTS_DELETE: {
        R_U_SURE: "The " + activity + " will be removed",
        R_U_SURE_TEXT:
          "Are you sure to delete the selected " +
          activity +
          "?, will not be able to recover",
        DELETED: "The selected " + activity + " has been deleted",
        ERRORS: {
          RESPONSE:
            "Something happened, please share this message with the system administrator",
        },
      },
      CUSTOMER_LIST: {
        TABLE: {
          USER: "Registered by",
          DATE: "Registration date",
        },
        ADD: {
          ADD_CUSTOMER: "Add customer",
          EDIT_CUSTOMER: "Edit Customer",
          PHOTO: "Photo",
          NAME: "Customer name",
          STATUS: "Status",
          EMPTY_NAME: "Please enter customer name",
          EMPTY_STATUS: "Please enter customer status",
          CUSTOMER_UPDATED: "The customer has been updated successfully",
          CUSTOMER_SAVED: "The customer has been saved successfully",
          CUSTOMER_DELETED: "The customer has been inactivated",
          CUSTOMER_R_SURE: "Are you sure you want delete the customer?",
          CUSTOMER_INACTIVATED: "The customer has been successfully deleted",
          R_U_SURE: "The customer will be deleted",
          ACTIVITY_NOTIFY:
            "Si es una " +
            activity +
            " programada, notificar al momento que se programe la " +
            activity,
          ACTIVITY_BEFORE:
            "Si es una " + activity + " programada notificar antes de un día.",
          ACTIVITY_ICS:
            "Si es una " +
            activity +
            " programada enviar por correo archivo tipo calendario (.ics)",
          ACTIVITY_START: "Cuando se inicia una " + activity + "",
          ACTIVITY_CLOSE: "Cuando se cierra una " + activity + "",
        },
      },
      CUSTOMERS: {
        EDIT: "Edit",
        VIEW: "View",
        RETURN: "Return",
      },
      RESET_PASSWORD: {
        NEW_PASSWORD: "New password",
        CONFIRM_PASSWORD: "Confirm password",
        BTN_SAVE_NEW_PASSWORD: "Save new password",
        SUCCESSUPDATED: "Your password has been changed successfully.",
        ERRORUPDATED:
          "The URL is invalid. Please, contact the system administrator.",

        ERROR_REQUIRED_PASSWORD: "Please enter the password.",
        ERROR_REQUIRED_CPASSWORD: "Please enter the confirm password.",
        ERROR_INVALID_UPPERLOWER_PASSWORD:
          "Your password must have a capital letter and a lower case letter.",
        ERROR_NUMBER_MISSING_PASSWORD: "Your password must have a number.",
        ERROR_MINLENGTH_PASSWORD:
          "Your password must have at least 8 characters.",

        ERROR_MATH_PASSWORD:
          "The password confirmation does not match with the new password.",
      },
      PROGRAMMING: {
        SCHEDULED_CREATING_DATE: "CREATION DATE AND TIME",
        TYPE_EVENT: "TYPE OF" + activityMayus,
        NAME_EVENT: "NAME OF THE" + activityMayus,
        ASSIGNEDTO: "ASSIGNED TO",
        CLIENT: "CLIENT",
        STATUS: "STATUS",
        TYPE_FORM_EVENT: "Type of " + activity,
        NAME_FORM_EVENT: "Name of the " + activity,
        ASIGNEDTO_FORM: "Assigned to",
        CLIENT_FORM: "Cliente",
        SCHEDULED_LIMIT_DATE: "DATE ​​AND TIME LIMIT",
        SCHEDULED_PROGRAMMING_DATE: "SCHEDULED DATE AND TIME",
        ACTIONS: "ACTIONS",
        FILTER_PER: "Filter by ...",
        STATUS_PER: "Status by...",
        PERIODICITY: "Periodicity",
        SCHEDULED_DATE_PROGRAMMING: "Date",
        HOUR_SICE: "Start time",
        HOUR_LIMIT: "Deadline",
        EXPIRATION: "Expiration in:",
        EXPIRATION_DATE: "Expiration date:",
        DAYS: "Days",
        LOCATION: "Add location",
        COMENTARYS: "Comments / Instructions",
        FILTER_DATE_CREATION: "Creation date",
        FILTER_FILTER_EVENT: "Type of " + activity,
        FILTER_FILTER_STATUS: "Status",
        FILTER_DATE_PROGRAMING: "Schedule date",
        FILTER_DATE_LIMIT: "Deadline date",
        DATE_INI: "Start date",
        DATE_FIN: "End date",
        TITLE_LIST: "Scheduled" + activities,
        TITLE_ADD: "Schedule " + activity,
        TITLE_DETAIL_MODAL_CALENDAR: activitiesFirstMayus + " of the day",
      },
      CLASSIFICATION_SECTION: {
        TITLE_LIST: "Categories",
        MODAL_TITLE_CAT: "Categories",
        MODAL_TITLE_SUB: "Subcategories",
        FORM: {
          NAME_CATEGORY: "Category Name ",
          NUMBER_CATEGORY: "Category {{number}} ",
          NAME_SUBCATEGORY: "Subcategory name",
          CATEGORY_SELECTED: "Category to which it belongs",
          NUMBER_SUBCATEGORY: "Subcategory {{number}} ",
          LABEL_NAME_MODAL_EDIT_CAT: "Category Name ",
          LABEL_TITLE_MODAL_EDIT_CAT: "Edit Category",
          LABEL_NAME_MODAL_EDIT_SUB: "Subcategory Name ",
          LABEL_TITLE_MODAL_EDIT_SUB: "Edit Subcategory",
        },
        MESSAGE_CONFIRM_DELETE_CAT:
          "Are you sure you want to delete this category?",
        MESSAGE_CONFIRM_DELETE_SUB:
          "Are you sure you want to delete this subcategory?",
      },
      CONFIGURATION: {
        WEBHOOK: "Webhook",
        HEADERS: "Headers",
        HELP: "* The system will send information when an ' + activity + ' changes status or is created.",
        ERROR: {
          URL: "Please enter the webhook URL information.",
          HEADER: "Please enter the header information in JSON format.",
        },
        TIME_ZONE: {
          TITLE: "Timezone",
          DESCRIPTION:
            "The selected timezone will be to govern the schedule of" +
            activities +
            ", their programming, expiration, start and end time.",
          ERROR: {
            MSG: "This field is required.",
          },
        },
        HOUR_LABOR: {
          TITLE: "Hour labor",
          DESCRIPTION:
            "The working hours selected will be to delimit hours and days of scheduled activities, such as the availability of users.",
        },
      },
      DASHBOARDREPORT: {
        COUNTEVENTS: "Number of " + activitiesFirstMayus,
        THEVENT: activityFirstMayus,
        THCLIENT: "Client",
        THUSER: "User",
        FILTERBYLABEL: "Group By",
        FILTERBYEVENTLABEL: "By  " + activityFirstMayus,
        FILTERBYCLIENTLABEL: "By Client",
        FILTERBYUSERLABEL: "By User",
        FILTERBYCATLABEL: "By Category",
        FILTERBYTIMELABEL: "Time range",
        TITLE: "Dashboard",
        TOTALCOUNT: "Total count",
        STATUS: "Status",
        ERRORSERVICES: "Something went wrong",
        FILTERBYDATES: "Date range",
        FILTERBYDATESSTARTDATE: "Start date",
        FILTERBYDATESENDDATE: "End date",
        INFOLEYEND: "Filtered by",
        DATERANGE: "Date range",
        TO: "to",
        OPTIONTODAY: "Today",
        OPTIONTOLASTWEEK: "last week ",
        OPTIONTO2LASTWEEK: "last 2 weeks",
        OPTIONLASTMONTH: "last month",
        OPTIONTORANGE: "Time range",
        DOFIND: "Find",
        DOCLEAN: "Clean",
        SELECTALL: "All",
        SELECTDETAIL: "Select",
        SELECTFILTERTYPE: "Select one",
        SELECTFILTERRANGE: "Select one",
        ERRORNORESULT:
          "No registered " +
          activities +
          " were found with the selected search filters. Please update the criteria and search again",
      },
    },
  },
};
