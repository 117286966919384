import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { HourlaborService } from '../../services/hourlabor.service';
import { AuthService } from '../../core/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ModalGenericService } from '../../services/modal-generic.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-configuration-hourlabor',
  templateUrl: './configuration-hourlabor.component.html',
  styleUrls: ['./configuration-hourlabor.component.scss']
})
export class ConfigurationHourlaborComponent implements OnInit {
  stylespan = "stylespan";
  d = false;
  l = true;
  ma = true;
  mi = true;
  j = true;
  v = true;
  s = false;
  errobtn = true;
  starttime = '09:00';
  endtime = '';
  rangohrs = 8;
  range = {
    init: '09:00',
    fin : '13:00',
    candelete:false, 
    canadd: true,
    inittooltip : '',
    fintooltip : '',
    cssbadinit: '',
    cssbadfin: ''
  }
  firebase_id = '';
  daysitems = [];
  ini = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',

  ];
  bad = '#fce8e6';
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef, 
    
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    private modalGeneric: ModalGenericService,
    public authService: AuthService, private hourservice: HourlaborService) { 
    this.endtime = moment(this.starttime, 'hh:mm').add(this.rangohrs, 'h').format("HH:mm");
    console.log(this.endtime);
  }

  ngOnInit() {
    if (this.authService.customerDB != null) {
      this.hourservice.customerDB = this.authService.customerDB;
      this.hourservice.customerSelected = this.authService.customerSelected;
      this.hourservice
      .getHourLabor()
      .subscribe(async (response) => {
        console.log(response);
        if(response.data == null){
          this.daysitems.push({
            title:"Domingo",
            range_hours:[
              {
                init: this.starttime,
                fin : this.endtime,
                candelete:false, 
                canadd: true,
                inittooltip : '',
                fintooltip : '',
                cssbadinit: '',
                cssbadfin: ''
              }
            ],
            required: false,
            view: false,
            abreviation: "d",
            abreviationTitle: "D",
            
          });
          this.daysitems.push({
            title:"Lunes",
            range_hours:[
              {
                init: this.starttime,
                fin : this.endtime,
                candelete:false, 
                canadd: true,
                inittooltip : '',
                fintooltip : '',
                cssbadinit: '',
                cssbadfin: ''
              }
            ],
            required: false,
            view: true,
            abreviation: "l",
            abreviationTitle: "L",
            
          });
          this.daysitems.push({
            title:"Martes",
            range_hours:[{
              init: this.starttime,
              fin : this.endtime,
              candelete:false, 
              canadd: true,
              inittooltip : '',
              fintooltip : '',
              cssbadinit: '',
              cssbadfin: ''
            }],
            required: false,
            view: true,
            abreviation: "ma",
            abreviationTitle: "M",
            
          });
          this.daysitems.push({
            title:"Miercoles",
            range_hours:[{
              init: this.starttime,
              fin : this.endtime,
              candelete:false, 
              canadd: true,
              inittooltip : '',
              fintooltip : '',
              cssbadinit: '',
              cssbadfin: ''
            }],
            required: false,
            view: true,
            abreviation: "mi",
            abreviationTitle: "M",
            
          });
          this.daysitems.push({
            title:"Jueves",
            range_hours:[{
              init: this.starttime,
              fin : this.endtime,
              candelete:false, 
              canadd: true,
              inittooltip : '',
              fintooltip : '',
              cssbadinit: '',
              cssbadfin: ''
            }],
            required: false,
            view: true,
            abreviation: "j",
            abreviationTitle: "J",
            
          });
          this.daysitems.push({
            title:"Viernes",
            range_hours:[{
              init: this.starttime,
              fin : this.endtime,
              candelete:false, 
              canadd: true,
              inittooltip : '',
              fintooltip : '',
              cssbadinit: '',
              cssbadfin: ''
            }],
            required: false,
            view: true,
            abreviation: "v",
            abreviationTitle: "V",
            
          });
          this.daysitems.push({
            title:"Sabado",
            range_hours:[{
              init: this.starttime,
              fin : this.endtime,
              candelete:false, 
              canadd: true,
              inittooltip : '',
              fintooltip : '',
              cssbadinit: '',
              cssbadfin: ''
            }],
            required: false,
            view: false,
            abreviation: "s",
            abreviationTitle: "S",
            
          });
        }
        else{
          this.daysitems = response.data.daysitems;
          this.firebase_id = response.data.firebase_id;
        }
      });
    }
    
  }
  clickday(position: number){
    /*switch(dia){
      case "d":        
      this.d = !this.d ;        
      break;
      case "l":  this.l = !this.l;  break;
      case "ma":  this.ma = !this.ma;  break;
      case "mi":  this.mi = !this.mi ;  break;
      case "j":  this.j = !this.j;  break;
      case "v":  this.v = !this.v ;  break;
      case "s":  this.s = !this.s;  break;
    }*/

    this.daysitems[position].required = !this.daysitems[position].required;
    this.daysitems[position].view = !this.daysitems[position].view;
    if(this.daysitems[position].view == false){
      this.daysitems[position].range_hours = [];
      this.daysitems[position].range_hours =[
        {
          init: this.starttime,
          fin : this.endtime,
          candelete:false, 
          canadd: true,
          inittooltip : '',
          fintooltip : '',
          cssbadinit: '',
          cssbadfin: ''
        }
      ];
    }
  }
  addRange(i: number, position:number){

   let original = this.daysitems[i].range_hours[position].fin;

   let posi = this.positionfindinit(this.daysitems[i].range_hours[position].fin);

   if(this.daysitems[i].range_hours[position].fin.indexOf('30') == 3){
    posi++;
   }
   else{
    posi += 2;
   }


   let ini =  this.ini[posi];
   let fin =  this.ini[posi+2];
   console.log(ini)
   console.log(fin)

   if(ini == undefined && fin == undefined)
   {
    ini = "23:30";
    fin = this.ini[0];
   } else if(fin == undefined){
    fin =  this.ini[0];
   }


   
   console.log(fin);
   
   let canadd = true;
   let candelete = true;

   if(fin == "00:00"){
    canadd = false;
   }
   

  let range = {
     init: this.starttime,
     fin : '13:00',
     candelete:false, 
     canadd: true,
     inittooltip : '',
     fintooltip : '',
     cssbadinit: '',
     cssbadfin: ''
  };
   range.canadd = canadd;
   range.candelete = candelete;
   range.init = ini;
   range.fin = fin;
   this.daysitems[i].range_hours.push(range);
   

   console.log(this.daysitems);
   this.daysitems[i].range_hours[position].canadd = false;
   console.log(this.daysitems);
   
  }
  positionfindinit(v): number{
    return this.ini.indexOf(v);
  }
  onChangefin(value, i, ai){
    let original = this.daysitems[i].range_hours[ai].fin;
    console.log('original', original);
    this.daysitems[i].range_hours[ai].fin = value;

    this.daysitems[i].range_hours[ai].inittooltip = "";
    this.daysitems[i].range_hours[ai].fintooltip = "";
    this.daysitems[i].range_hours[ai].cssbadinit = "";
    this.daysitems[i].range_hours[ai].cssbadfin = "";
    
    let posi = this.positionfindinit(this.daysitems[i].range_hours[ai].fin);
    console.log('original', original, this.daysitems[i].range_hours[ai].fin);


    
    let beginningTime = moment(this.daysitems[i].range_hours[ai].init, 'hh:mm');
    let endTime = moment(this.daysitems[i].range_hours[ai].fin, 'hh:mm');
 

    if(this.daysitems[i].range_hours[ai].init == this.daysitems[i].range_hours[ai].fin &&  this.daysitems[i].range_hours.length == 1){
      if(this.daysitems[i].range_hours[ai].fin.indexOf('30') == 3){
        posi--;
       }
       else{
        posi -= 2;
       }
       this.daysitems[i].range_hours[ai].init = this.ini[posi];
    }
    else if(this.daysitems[i].range_hours[ai].init == this.daysitems[i].range_hours[ai].fin &&  this.daysitems[i].range_hours.length > 1){      
      this.daysitems[i].range_hours[ai].inittooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].fintooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].cssbadinit = this.bad;
      this.daysitems[i].range_hours[ai].cssbadfin = this.bad;
      this.errobtn = false;
    }
    else if(beginningTime.isBefore(endTime) == false){    
      this.daysitems[i].range_hours[ai].fintooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].cssbadfin = this.bad;
      this.errobtn = false;
     /* let entro = false;
      this.daysitems[i].range_hours.forEach(element => {
        let beginningTime = moment(element.init, 'hh:mm');
        let endTime = moment(this.daysitems[i].range_hours[ai].fin, 'hh:mm');
        console.log(element.init);
        console.log(element.fin);

      });
      if(entro){
        this.errobtn = false;
      }
      else{
        this.errobtn = true;
      }*/
    }
    else{
      this.errobtn = true;
    }

    if(value == "23:00" || value == "23:30"){
      this.daysitems[i].range_hours[ai].canadd = false;
    }
    else{
      this.daysitems[i].range_hours[ai].canadd = true;
    }

    
  }
  onChangeInit(value, i, ai){
    this.daysitems[i].range_hours[ai].init = value;
    let posi = this.positionfindinit(this.daysitems[i].range_hours[ai].init);

    this.daysitems[i].range_hours[ai].inittooltip = "";
    this.daysitems[i].range_hours[ai].fintooltip = "";
    this.daysitems[i].range_hours[ai].cssbadinit = "";
    this.daysitems[i].range_hours[ai].cssbadfin = "";

    let beginningTime1 = moment(this.daysitems[i].range_hours[ai].init, 'hh:mm');
    let endTime1 = moment(this.daysitems[i].range_hours[ai].fin, 'hh:mm');

    if(this.daysitems[i].range_hours[ai].init == this.daysitems[i].range_hours[ai].fin &&  this.daysitems[i].range_hours.length == 1 ){
      if(this.daysitems[i].range_hours[ai].fin.indexOf('30') == 3){
        posi++;
       }
       else{
        posi += 2;
       }
       this.daysitems[i].range_hours[ai].fin = this.ini[posi];
    }else if(beginningTime1.isBefore(endTime1) == false){    
      this.daysitems[i].range_hours[ai].fintooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].cssbadfin = this.bad;
      this.errobtn = false;
    }     
    else if(this.daysitems[i].range_hours[ai].init == this.daysitems[i].range_hours[ai].fin &&  this.daysitems[i].range_hours.length > 1){      
      this.daysitems[i].range_hours[ai].inittooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].fintooltip = "No se pueden superponer los periodos de horario laboral.";
      this.daysitems[i].range_hours[ai].cssbadinit = this.bad;
      this.daysitems[i].range_hours[ai].cssbadfin = this.bad;
      this.errobtn = false;
    }

    else if(this.daysitems[i].range_hours[ai].init != this.daysitems[i].range_hours[ai].fin &&  this.daysitems[i].range_hours.length > 1){     
      let entro = false; 
      this.daysitems[i].range_hours.forEach((element, index ) => {
        
        if(index != ai)
        {
          if(element.init == this.daysitems[i].range_hours[ai].init){
            this.daysitems[i].range_hours[ai].inittooltip = "No se pueden superponer los periodos de horario laboral.";
            this.daysitems[i].range_hours[ai].cssbadinit = this.bad;
            entro = true;
          }

          let beginningTime = moment(this.daysitems[i].range_hours[ai].init, 'hh:mm');
          let endTime = moment(element.fin, 'hh:mm');
          console.log(endTime, beginningTime, endTime.isBefore(beginningTime) );
          if(endTime.isBefore(beginningTime)  == false){
            this.daysitems[i].range_hours[ai].inittooltip = "No se pueden superponer los periodos de horario laboral.";
            this.daysitems[i].range_hours[ai].cssbadinit = this.bad;
            entro = true;
          }
          
        }
      });

      if(entro){
        this.errobtn = false;
      }
      else{
        this.errobtn = true;
      }
    }
    else{
      this.errobtn = true;
    }

    
  }
  deleteRange(i, ai){
    console.log('ai', ai);
    this.daysitems[i].range_hours.splice(ai, 1);
    let count = this.daysitems[i].range_hours.length;
    let num = 1;
    console.log(count)
    
    this.daysitems[i].range_hours.forEach(element => {
      console.log(num)
      if(num == count){
        this.daysitems[i].range_hours[num-1].canadd = true;
      }
      num++;
    });
    
    console.log(this.daysitems[i].range_hours)
  }
  formathour(v):string{
 
    return moment(v, 'hh:mm').format("hh:mm a")
  }

  copy(){
    let i = 0;
    let entro = false;
    let rangehours = [];
    this.daysitems.forEach((e, index )  => {
      if(e.view == true && entro == false){
        i = index;
        entro = true;
        
      }
    });
     

    this.daysitems[i].range_hours.forEach((e, index ) => {
      rangehours.push({
          init: e.init,
          fin : e.fin,
          candelete: e.candelete, 
          canadd: e.canadd,
          inittooltip :e.inittooltip,
          fintooltip : e.fintooltip,
          cssbadinit: e.cssbadinit,
          cssbadfin: e.cssbadfin
      })
    });

    console.log(rangehours);
    for (let index = 0; index < this.daysitems.length; index++) {
      if(this.daysitems[index].view == true){
        this.daysitems[index].range_hours = [];
        this.daysitems[index].range_hours = rangehours;
      }
    }
    this.daysitems.forEach((e, index )  => {
      if(this.daysitems[index].view == true){
        this.daysitems[index].range_hours = [];
      }
    });
    this.daysitems.forEach((el, index )  => {
      if(el.view == true){
        rangehours.forEach((e ) => {
          this.daysitems[index].range_hours.push({
              init: e.init,
              fin : e.fin,
              candelete: e.candelete, 
              canadd: e.canadd,
              inittooltip :e.inittooltip,
              fintooltip : e.fintooltip,
              cssbadinit: e.cssbadinit,
              cssbadfin: e.cssbadfin
          })
        });
      }
    });


  }
  save(){
    let send = {
      firebase_id: this.firebase_id,
      daysitems: this.daysitems
    }
    this.spinner.show();
    this.hourservice.ws_post_create(send).subscribe(async (response) => {
      console.log(response);
      if(response.status == 'ok'){
        this.sendSuccess('Se actualizo con exito!')
      }
      else{
        this.sendModelError(response.message);
      }
    });
  }

  sendSuccess(text: string) {
    this.spinner.hide();
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
         
      });
  }
  sendModelError(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
      true, // info
      false //imagen info
    );
  }
}