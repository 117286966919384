import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/core/storage.service';
import { RecentActivitiesComponent } from './pages/recent-activities/recent-activities.component';
import { Router } from '@angular/router';
import { PermissionService } from '@app/core/permission.service';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  indexPage = 0;
  public current_component: any;
  constructor(
    public permisionService: PermissionService,
    public authService: AuthService,
    private router: Router, 
    public storageService: StorageService
  ) {}

  ngOnInit() {
  }

  onActivate(componentRef) {
    this.current_component = null;
    console.log('componentRef',componentRef instanceof RecentActivitiesComponent);
    if (componentRef instanceof RecentActivitiesComponent) {
      this.current_component = componentRef;
    }
  }

  public _selectOptionMenu(event: any, indexPage) {
    event.preventDefault();
    this.indexPage = indexPage;
    let ruta = '';
    if(this.storageService.getCurrentDB() != '') {
      switch(indexPage){
        case 0:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help';
          break;
        case 1:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/customers';
          break;
        case 2:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/types-of-events';
          break;
        case 3:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/programation';
          break;
        case 4:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/users';
          break;
        case 5:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/dashboard';
          break;
        case 6:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/configuration';
          break;
        case 7:   
          ruta = '/' + this.storageService.getCurrentDB() + '/help/data-sheet';
          break;
      }
      this.router.navigate([ruta]);
    }
  }
}
