import { Component, OnInit, ViewChild, ElementRef, Inject, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Marker } from '@agm/core/services/google-maps-types';
@Component({
  selector: 'app-programming-search-map',
  templateUrl: './programming-search-map.component.html',
  styleUrls: ['./programming-search-map.component.scss']
})
export class ProgrammingSearchMapComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  selectedMarker: Marker;
  
  @ViewChild('search')
  public searchElementRef: ElementRef;
  constructor(
    private  dialogRef:  MatDialogRef<ProgrammingSearchMapComponent>, @Inject(MAT_DIALOG_DATA) public  data:  any,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
  
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          console.log('place_changed', place)
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 12;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          console.log('results', results)
          this.address = results[0].formatted_address;
        } else {
          window.alert('No se encontraron resultados.');
        }
      } else {
        window.alert('La busqueda ha fallado: ' + status);
      }
  
    });
  }

  public  closeMe() {
    let parameters = {button: "cerrar"}
    this.dialogRef.close(parameters);
  }

  markerDragEnd($event: any) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  aceptar(){
    let parameters = {button: "aceptar", latitude: this.latitude, longitude: this.longitude, address: this.address}
    this.dialogRef.close(parameters);
  }



}
