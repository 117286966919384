import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { StorageService } from '../../core/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { take } from 'rxjs/operators';
import { PermissionService } from '../../core/permission.service';
import { Session } from '../../models/session.model';
import { MatDialog} from  '@angular/material/dialog';
import { ModalImageComponent } from '../modal-image/modal-image.component';
import {EncryptionDecryptionService} from "@app/services/encryption-decryption.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '../../validators/PasswordValidator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  datatmp: string = '';
  private sub: any;
  formulario: any = {
    password: '',
    repeat_password: '',
  };
  resetForm: FormGroup;
  error: string = '';
  showErrors: boolean = false;
  public dataEncript:any = 
  {
    uid:'',
    name:'',
  } 
  constructor(public userService: UserService,
    public authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private modalGeneric: ModalGenericService,
    public permisionService: PermissionService,
    private  dialog:  MatDialog,
    private encryptDecryptService: EncryptionDecryptionService,
    private fb: FormBuilder,) {
      if (authService.customerDB != null) {
        userService.customerDB = authService.customerDB;
        userService.customerSelected = authService.customerSelected;
        userService.getCurrentUser().then((user) => {});
      }
      this.createForm();
     }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.datatmp = params['id'] || '';
     this.dataEncript = JSON.parse(this.encryptDecryptService.decryptSimpleString(decodeURIComponent(this.datatmp)));

    });
    
  }

  hasErrorEmptyField(field): any {

    if (field.invalid && (field.dirty || field.touched)) {
      return true;
    }

    return false;
  }

  
  createForm() {
    this.resetForm = this.fb.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.mayusMinus,
          PasswordValidator.hasNum,
        ]),
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.mayusMinus,
          PasswordValidator.hasNum,
          PasswordValidator.match,
        ]),
      ],
    });
  }

  checkErrors() {
    if (this.resetForm.valid) {
      this.showErrors = false;
    } else {
      this.showErrors = true;
    }
  }
  tryReset() {
    if (this.resetForm.valid) {
      this.showErrors = false;
      // let dataToSend = this.resetForm.value
      
      // dataToSend['uid'] = encodeURIComponent(this.encryptDecryptService.encryptRerturnSimpleString(JSON.stringify(this.dataEncript)));
      // dataToSend['Password'] =  this.encryptDecryptService.encryptRerturnSimpleString(this.resetForm.controls['password'].value);
      // dataToSend['Password'] ="Pass";
      let dataToSend :any = 
      {
        uid : this.encryptDecryptService.encryptRerturnSimpleString(JSON.stringify(this.dataEncript.uid)),
        password : this.encryptDecryptService.encryptRerturnSimpleString(this.resetForm.controls['password'].value)
      }

      this.userService.changePassword(dataToSend).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.status == 'ok') {
            this.modalGeneric
              .onModelexito(
                false, //disableClose
                this.translateService.instant(
                  'PAGES.RESET_PASSWORD.SUCCESSUPDATED'
                ), //message
                false, // cancelButton
                this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                true, // info
                false //imagen info
              )
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                const ruta = '/' + this.storageService.getCurrentDB() + '/user-list';
                this.router.navigate([ruta]);
              });
          } else {
            this.modalGeneric.onModelerror(
              false, //disableClose
              this.translateService.instant(
                'PAGES.RESET_PASSWORD.ERRORUPDATED'
              ), //message
              false, // cancelButton
              this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
              true, // info
              false //imagen info
            );
          }
        },
        (err) => {
          this.error = err.error;
          this.spinner.hide();
        }
      );
      

    }
  }

  cancelSave() {
    this.location.back();
  }


}
