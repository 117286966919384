import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../core/auth.service';
import { UtilsService } from '../../core/helper/utils.helper';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { StorageService } from '../../core/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as mockUsers from '../../mocks/user-list.json';

import { TranslateService } from '@ngx-translate/core';

import { ModalGenericService } from '@app/services/modal-generic.service';
import { take } from 'rxjs/operators';
import { PermissionService } from '../../core/permission.service';
import {EncryptionDecryptionService} from "@app/services/encryption-decryption.service";

@Component({
  selector: 'app-user-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public user_list: any = [];
  public users_by_page: number = 10;
  public user_current_page: number = 1;
  totalitems: number;
  filter: any = {
    list: true,
    filter: "",
    users_by_block: this.users_by_page,
    pageActual: this.user_current_page
  }
  public user_filter: string = '';
  public dataEncript:any = 
  {
    uid:'',
    name:'',
  } 

  get getUserList() {
    return (
      this.user_list &&
      this.user_list
      .filter((item) => item.profile !== 'SUPERADMIN')        
      .sort(function (a, b) {
        if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
          return 1;
        }
        if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
          return -1;
        }
        return 0;
      })
    );
  }

  round(value) {
    return Math.ceil(value);
  }

  updateCurrentPage(value) {
    try {
      if (this.users_by_page !== 1) {
        if (value == 1) {
          this.user_current_page = 1;
          this.filter.pageActual = this.user_current_page;
        }
      }
    } catch (err) {}
  }

  active = 0;
  inactive = 0;
  limit = 10;
  disabledbtn = true;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public utilsService: UtilsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    public translateService: TranslateService,
    private modalGeneric: ModalGenericService,
    public permisionService: PermissionService,
    private encryptDecryptService: EncryptionDecryptionService,
  ) {
    if (authService.customerDB != null) {
      userService.customerDB = authService.customerDB;
      userService.customerSelected = authService.customerSelected;
      
      this.getUsers();
    }
  }

  getUsers(){
    this.spinner.show();
    this.userService.getUsersListModuleBigquery(this.filter).subscribe((response) => {
      console.log('response getUsersList>>>', response)
      this.totalitems = response.totalitems;
      
      this.user_list = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });
      this.spinner.hide();
      console.log("LLega aqui");
      console.log(this.user_list)
     
    });
    this.getuserlimit();
  }

  getuserlimit(){
    this.userService.getUserLimit().subscribe((response) => {
      console.log('aqui estoy list', response);
      this.active = response.active;
      this.inactive = response.inactive;
      this.limit = response.limit_user_creation;
      if(this.limit <= this.active){
        this.disabledbtn = true;
      }
      else{
        this.disabledbtn = false;
      }
    });
  }

  ngOnInit() {
    // this.user_list = mockUsers;
  }

  pageChanged($event) {
    this.user_current_page = $event;
    this.filter.pageActual = this.user_current_page;
    this.getUsers();
  }
  users_by_blockChanged(){
    this.user_current_page = 1;
    this.filter.pageActual = this.user_current_page;
    this.filter.users_by_block = this.users_by_page;
    this.getUsers()
  }
  search_filter(d: string){
    console.log(d);
    this.user_current_page = 1;
    this.filter.filter = d;
    this.filter.pageActual = this.user_current_page;
    this.filter.users_by_block = this.users_by_page;
    this.getUsers();
  }
  resendEmailRestorePassword(email: string, event) {
    event.preventDefault();
    this.spinner.show();
    let body = { email: email };
    this.userService.forgotpassword(body).subscribe(
        (response) => {
          console.log('forgotpassword', response);
          this.spinner.hide();
          if (response.status == 'ok') {
            this.modalGeneric.onModelexito(
                false, //disableClose
                this.translateService.instant(
                    'PAGES.USER_LIST.RESEND_EMAIL_RESTORE_PASS'
                ), //message
                false, // cancelButton
                this.translateService.instant(
                    'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
                ), //confimButtonText
                true, // info
                false //imagen info
            );
          } else {
            this.modalGeneric.onModelerror(
                false, //disableClose
                this.translateService.instant(
                    'PAGES.USER_LIST.ERROR_EMAIL_RESTORE_PASS',
                    { error: response.error }
                ), //message
                false, // cancelButton
                this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                true, // info
                false //imagen info
            );
          }
        },
        (err) => {
          console.log('resendinvitation error', err);
          this.spinner.hide();
        }
    );
    
  }

  sendModelError(msg: string) {
    this.modalGeneric.onModelerror(
        false, //disableClose
        msg, //message
        false,  // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'),  //confimButtonText
        true, // info
        false, //imagen info
    )
  }

  activateDeactivateUser(value) {

    
    if(this.disabledbtn == true && value.disabled == true){
      this.sendModelError('Llegaste al límite de usuarios contacta a tu gerente de cuenta para solicitar más.');
      return false;
    }

    
    value.disabled = value.disabled ? false : true;
    console.log('Deactivate: ' + value);
    this.spinner.show();
    this.userService.createUser(value).subscribe((response) => {
      console.log(JSON.stringify(response));
      this.spinner.hide();
      if (response.status == 'ok') {

        this.modalGeneric
          .onModelexito(
            false, //disableClose
            value.disabled
              ? this.translateService.instant('PAGES.USER_ADD.SUCCESS.DISABLED')
              : this.translateService.instant('PAGES.USER_ADD.SUCCESS.ENABLED'), //message
            false, // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false //imagen info
          )
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            console.log('aqui estoy para redireccionar');
            const ruta =
              '/' + this.storageService.getCurrentDB() + '/user-list';
            this.router.navigate([ruta]);
            window.location.reload();
            
          });
      } else if (response.status == 'fail') {
        this.modalGeneric.onModelerror(
          false, //disableClose
          this.translateService.instant(
            'PAGES.USER_ADD.ERRORS.' +
              (response.message.code || '').toUpperCase()
          ), //message
          false, // cancelButton
          this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
          true, // info
          false //imagen info
        );
      }
    });
  }

  deleteUser(value) {
    this.modalGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant('PAGES.USER_DELETE.R_U_SURE_TEXT'), //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        console.log('~ resp', resp);
        if (resp === 'aceptar') {
          let user: any = [];
          user.push(value.uid);
          this.spinner.show();
          this.userService.deleteUserList(user).subscribe((response) => {
            this.spinner.hide();
            if (response.status == 'ok') {
              this.modalGeneric
                .onModelexito(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.USER_LIST.LIST.THUMBNAIL.DELETE_SUCCESS'
                  ), //message
                  false, // cancelButton
                  this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                  true, // info
                  false //imagen info
                )
                .afterClosed()
                .pipe(take(1))
                .subscribe((resp) => {
                  const ruta =
                    '/' + this.storageService.getCurrentDB() + '/user-list';
                  this.router.navigate([ruta]);
                  window.location.reload();
                });
            } else if (response.status == 'fail') {
              this.modalGeneric.onModelerror(
                false, //disableClose
                this.translateService.instant(
                  'PAGES.USER_ADD.ERRORS.' +
                    (response.message.code || '').toUpperCase()
                ), //message
                false, // cancelButton
                this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                true, // info
                false //imagen info
              );
            }
          });
        }
      });
  }

  unlinkUserUser(user){

    this.modalGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant('¿Estás seguro de que deseas desvincular esta cuenta?'), //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        console.log('~ resp', resp);
        if (resp === 'aceptar') {
          this.spinner.show();
          this.userService.unLinkUser(user).subscribe(
            (response) => {
              console.log('unLinkUser', response);
              this.spinner.hide();
              if (response.status == 'ok') {
                this.modalGeneric.onModelexito(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.USER_LIST.UNLINK_USER'
                  ), //message
                  false, // cancelButton
                  this.translateService.instant(
                    'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
                  ), //confimButtonText
                  true, // info
                  false //imagen info
                )
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                  window.location.reload();
                });
              } else {
                this.modalGeneric.onModelerror(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.USER_LIST.ERROR_EMAIL_RESTORE_PASS',
                    { error: response.error }
                  ), //message
                  false, // cancelButton
                  this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                  true, // info
                  false //imagen info
                );
              }
            },
            (err) => {
              console.log('resendinvitation error', err);
              this.spinner.hide();
            }
          );

        }
      });    
  }

  resendInvitation(email: string, uid: string, displayName: string,  event) {
    event.preventDefault();
    this.spinner.show();
    let body = { email: email, uid: uid, displayName: displayName };
    this.userService.resendWelcomeEmail(body).subscribe(
      (response) => {
        console.log('forgotpassword', response);
        this.spinner.hide();
        if (response.status == 'ok') {
          this.modalGeneric.onModelexito(
            false, //disableClose
            this.translateService.instant(
              'PAGES.USER_LIST.RESEND_EMAIL_INVITATION'
            ), //message
            false, // cancelButton
            this.translateService.instant(
              'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
            ), //confimButtonText
            true, // info
            false //imagen info
          );
        } else {
          this.modalGeneric.onModelerror(
            false, //disableClose
            this.translateService.instant(
              'PAGES.USER_LIST.ERROR_EMAIL_RESTORE_PASS',
              { error: response.error }
            ), //message
            false, // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false //imagen info
          );
        }
      },
      (err) => {
        console.log('resendinvitation error', err);
        this.spinner.hide();
      }
    );
  }
  changePassword(uid:string,event: any,name:string)
  {
    event.preventDefault();
    this.dataEncript.uid = uid;
    this.dataEncript.name = name;
    let data = this.encryptDecryptService.encryptRerturnSimpleString(JSON.stringify(this.dataEncript));
    console.log("data change");
    console.log(data);
    if(this.storageService.getCurrentDB() != '') {
      console.log("entra a if");
      const ruta = '/' + this.storageService.getCurrentDB() + '/user-list/changepass/'+encodeURIComponent(data);
      console.log(ruta);
      this.router.navigate([ruta]);
    }
  }
}
