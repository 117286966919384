import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../core/user.service';
import { StorageService } from '../core/storage.service';
import { Session} from "../models/session.model";

import {TranslateService} from "@ngx-translate/core";
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import { MatDialog } from '@angular/material';
import { TermsConditionsComponent } from '@app/terms-conditions/terms-conditions.component';

@Component({
  selector: 'page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit ,OnDestroy{

  loginForm: FormGroup;
  emailMessage: string = '';
  errorMessage: string = '';
  errorMessagePass: string = '';
  buttonVisible: boolean = true;
  emailCorrect: boolean = true;
  emailIncorrect: boolean =  true;
  logoClient: string = '';

  validation_message

  form$: Subscription
  onDestroy: Subject<any> = new Subject<any>();
  acepted_terms_conditions: boolean = false;
  showErrorTermsConditions: boolean = false;
  constructor(
    public authService: AuthService,
    private router: Router,
    public userService: UserService,
    public storageService: StorageService,
    public translateService: TranslateService,
    public dialog: MatDialog

  ) { 
  }
  
  ngOnInit() {
    this.getLogo();
    this.createForm();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
    if (this.form$) this.form$.unsubscribe()
  }

  async getLogo(){
    await this.authService.getAsyncCustomerDB();
    await this.authService.customerDB.app
      .firestore()
      .collection('settings')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {

          this.logoClient = doc.data().logo_client;

        });

      })
      .catch((error) => {
      });
  }

  createForm() {
    // var patron = /^[a-z0-9_-]+(?:.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    // ^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$
    this.loginForm = new FormGroup({
      email: new FormControl('', 
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z0-9_-]+(?:.[a-z0-9_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')
        ])),
      password: new FormControl('', Validators.required)
    }, { updateOn: 'change' });
    this.setValidationMsg()
    this.watchForm()
  }

  setValidationMsg() {
    this.validation_message = {
      email: [
        { type: 'required', message: 'Por favor, ingresa un correo electrónico válido.' },
        { type: 'pattern', message: 'Por favor, ingresa un correo electrónico válido.' }
      ],
      password: [
        { type: 'required', message: 'Por favor, ingresa una contraseña.' },
      ]
    }
  }

  watchForm() {
    this.loginForm.valueChanges.subscribe(values => {
      this.errorMessage = ''
    })
  }

  tryFacebookLogin(){
    this.authService.doFacebookLogin()
    .then(res => {
      this.router.navigate(['/user']);
    })
  }

  tryTwitterLogin(){
    this.authService.doTwitterLogin()
    .then(res => {
      this.router.navigate(['/user']);
    })
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin()
    .then(res => {
      this.router.navigate(['/user']);
    })
  }

  onforgotpassword(){
    const ruta = '/' + this.authService.customer_route + '/recover-password';
    console.log(ruta);    
    this.router.navigate([ruta]);
  }

  tryLogin(value) {
    console.log("entra al login");

    if(this.acepted_terms_conditions){

      this.loginForm.controls.email.markAsTouched()
      this.loginForm.controls.email.markAsDirty()
      this.loginForm.controls.password.markAsTouched()
      this.loginForm.controls.password.markAsDirty()
      this.errorMessage = ''
      this.errorMessagePass = ''
      if (this.loginForm.invalid) {
        return
      }
      if(this.authService.getCustomerDB() != null) {
        
        this.storageService.removeFiltersDashboard();
        this.storageService.removeFiltersSheduledEvents();
        this.userService.customerDB = this.authService.customerDB;
        this.userService.customer_route = this.authService.customer_route;
        this.authService.doLogin(value).then(async res => {
          console.log('resta', res)
          if(this.authService.customer_route != '') {
            var ruta = "";
            if(this.authService.customer_route == "PrincipalAdmin")
            {
              ruta = '/trackpoint/' + this.authService.customer_route + '/dashboard-admin';
            }else
            {
              ruta = '/' + this.authService.customer_route + '/dashboard';
              //ruta = '/dashboard'
            }
            await this.authService.setHistoryDatesLogin(res.user.uid).subscribe(
              (response) => {
                console.log(ruta);
                const session = new Session();
                session.token = res.user.refreshToken;
                session.DB = this.authService.customer_route;
                session.uid = res.user.uid;
                session.photoUrl = res.user.photoURL;
                session.displayName = res.user.displayName;
                console.log('session', session);
                this.storageService.customerSelected = this.authService.customerSelected;
                this.storageService.setCurrentSession(session);
                this.storageService.setDetailDataCurrentUser(this.authService.customerDB).then(response => {
                  if (response) {              
                    let dataDetailUser: any = JSON.parse(this.storageService.getDetailDataCurrentUser());
                    if(dataDetailUser.profile == "CAMPO"){
                      this.storageService.logout();
                      this.errorMessage = 'El usuario no tiene permisos para acceder.';
                    }
                    else{
                    
                      this.setClientTimeZone().then((response) => {
                          this.router.navigate([ruta]);
                      });
                    }
                    console.log(dataDetailUser.profile);
                  }
                }).catch((error) => {
                  console.log('Error obteenr datos del usuario', error);
                });

            });
            
          }else{
            console.log("no entra al login");
          }
        }, err => {
          console.log(err);
          switch (err.code) {
            case 'auth/invalid-email':
              this.errorMessage = 'Por favor, ingresa un correo electrónico válido.';
              break
            case 'auth/user-disabled':
              this.errorMessage = 'El usuario ha sido deshabilitado, por favor, contacta al área de soporte técnico.';
              break
            case 'auth/user-not-found':
              this.errorMessage = 'Por favor, ingresa un correo electrónico válido.';
              break
            case 'auth/wrong-password':
              this.errorMessagePass = 'Por favor, ingresa una contraseña válida.';
              break
          }
        })
      }

    }else{
      this.showErrorTermsConditions = true;
    }
   
  }

  async setClientTimeZone() {
      return new Promise((resolve, reject) => {
          
        console.log('Esta entrando a la promesa');
        let data = this.storageService.getListTimeZones();
        console.log('Listado', data);
        let timeZoneConfig = data.find(item => item['abbreviation'] == 'CST');
        this.userService.getSettingsClient().pipe(takeUntil(this.onDestroy)).subscribe((response) => {
            if (response.data && response.data.length > 0) {
                if (response.data[0].timezone_id) {
                    console.log('Response data', response);
                    if (data) {
                        let timeZoneConfig2 = data.find(item => item['firebase_id'] == response.data[0].timezone_id);
                        console.log('Time zone found', timeZoneConfig2);
                        if (timeZoneConfig2) {
                            timeZoneConfig = timeZoneConfig2;
                        }
                    }
                }
            }
            this.storageService.setTimeZoneConfig(timeZoneConfig);
            resolve(true);
        }, error => {
            this.storageService.setTimeZoneConfig(timeZoneConfig);
            resolve(true);
        });
        
         
      })
  }

  showTermsConditions(){
    console.log(' CHOS TERM ', this.acepted_terms_conditions);

    this.authService.getTermsConditions().then(res => {

      const dialogRef = this.dialog.open(TermsConditionsComponent, {
        height: '80%',
        width: '80%',
        data: {
          title: res.title_terms_conditions,
          terms: res.terms_conditions
        },
      });
  
      dialogRef.afterClosed().subscribe((data) => {

        if(data){
          this.acepted_terms_conditions = true;
        }else{
          this.acepted_terms_conditions = false;
        }

      });


    });

  }

  changeBox(){
    this.showErrorTermsConditions = false;
    this.acepted_terms_conditions = !this.acepted_terms_conditions;
  }
}
