import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {StorageService} from "@app/core/storage.service";
import {ModalGenericService} from "@app/services/modal-generic.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-classification-editname-modal',
  templateUrl: './classification-editname-modal.component.html',
  styleUrls: ['./classification-editname-modal.component.scss']
})
export class ClassificationEditnameModalComponent implements OnInit {
  dataToEdit = {};
  constructor(public translateService: TranslateService,
              public storageService: StorageService,
              private modalGeneric: ModalGenericService,
              public  dialogRef:  MatDialogRef<ClassificationEditnameModalComponent>,
              @Inject(MAT_DIALOG_DATA) public  data:  any) {
      this.dataToEdit = JSON.parse(JSON.stringify(data));
  }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close({save: false, data: []});
  }

  saveData() {
    this.dialogRef.close({save: true, data: this.dataToEdit});
  }

}
