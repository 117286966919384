// Spain
const activity = "actividad";
const activityFirstMayus = "Actividad";
const activitiesFirstMayus = "Actividades";
const activityMayus = "ACTIVIDAD";
const activities = "actividades";
const task = "tarea";
const tasks = "tareas";

export const locale = {
  lang: "es",
  data: {
    MESSAGE: {
      SUCCESS: "¡Exito!",
      ERROR: "Error",
      ACEPTAR: "Aceptar",
      KEEP_LOOKING: "Seguir buscando",
    },
    TITLE: {
      NO_RESULTS: "SIN RESULTADOS",
    },
    HEADER: {
      TITLE: {
        FIELD_ACTIVITY: "Actividad de campo",
        USERS: "Usuarios",
        CUSTOMERS: "Clientes",
        FORMULARIOS: activitiesFirstMayus + "y Formularios",
        CLASSIFICATION: "Categorías",
        EQUIPMENT: "Equipos",
      },
      MENU: {
        LOGOUT: "Cerrar sesión",
        PROFILE: "Perfil de usuario",
        FIELD_ACTIVITY: "Actividades recientes",
        USERS: "Usuarios",
        EQUIPMENT: "Equipos",
        FORMULARIOS: "Tipos de " + activity,
        PROGRAMMING: "Programación",
        CUSTOMERS: "Clientes",
        CONFIGURATION: "Configuración",
        LOGO_COMPANY: "Logo empresa",
        LIST_CUSTOMERS: "Clientes",
        CLASSIFICATION: "Categorías",
        DASBOARD_REPORT: "Dashboard",
        WEBHOOK: "Webhook",
        TIME_ZONE: "Zona horaria",
        HELP: "Ayuda",
        LABOR_HOUR: "Horario laboral",
      },
    },
    MENU: {
      TYPE_1: {
        TODAY: "Hoy",
        LAST_WEEK: "Última Semana",
        LAST_MONTH: "Último Mes",
        DATE_RANGE: "Rango de fechas",
      },
      TYPE_2: {
        USER_LIST: "Lista de usuarios",
        USER_ADD: "Alta de usuario",
        USER_DELETE: "Eliminar usuario",
        USER_SET_MAX: "Máximo de suarios",
      },
      TYPE_3: {
        FORMULARIO_LIST: "Lista de formularios",
        ALTA_FORMULARIO: "Alta de formulario",
        EVENTS_LIST: "Lista de " + activities,
        ALTA_EVENTS: "Alta de " + activities,
        ELIMINAR_SELECTION: "Eliminar selección",
      },
      TYPE_4: {
        CUSTOMER_LIST: "Lista de clientes",
        CUSTOMER_ADD: "Agregar cliente",
      },
      SEARCH: {
        LIMPIAR: "Limpiar filtros",
        BUSCAR: "Buscar",
        NOMBRE: "Nombre de " + activity,
        TIPO: "Tipo de " + activity,
        OFICINA: "Oficina",
        ROL: "Rol",
        USUARIO: "Usuario",
        CLIENT: "Cliente",
        EQUIPMENT: "Equipo",
        CATEGORY: "Categoria",
        SUBCATEGORY: "Subcategoria",
        NOMBREPLACEHOLDER: "Ingresa el nombre de la " + activity,
        TIPOPLACEHOLDER: "Selecciona un tipo de " + activity,
        OFICINAPLACEHOLDER: "Selecciona una oficina",
        ROLPLACEHOLDER: "Selecciona un rol",
        USUARIOPLACEHOLDER: "Selecciona un usuario",
        CLIENTPLACEHOLDER: "Selecciona un cliente",
        EQUIPMENTPLACEHOLDER: "Selecciona un equipo",
        CATEGORYPLACEHOLDER: "Selecciona una categoria",
        ZONEPLACEHOLDER: "Selecciona una zona",
        SUBCATEGORYPLACEHOLDER: "Selecciona una subcategoria",
        ACTIVIDADES:
          "Selecciona un periodo de tiempo o realiza una búsqueda avanzada en el mapa",
      },
      RESUMEN: {
        DURACION: "Duración",
        ENTRE: "Entre " + activities,
        DISTANCIA: "Distancia entre puntos",
      },
    },
    ERRORS: {
      RESPONSE:
        "Un error ha ocurrido, favor de informar al administrador del sistema",
    },
    PAGES: {
      LOGIN: {
        ERROR: {
          EMAIL: "Por favor, ingrese un email valido.",
        },
      },
      DASHBOARD: {
        ARCHIVED: "Archivar",
        ARCHIVED_CONFIRM: "¿Estás seguro de archivar esta " + activity + "?",
        ARCHIVED_OK: "La " + activity + " ha sido archivada exitosamente.",
        ARCHIVED_ERROR:
          "No se pudo archivar la actividad, favor de informar al administrador del sistema.",
        DELETED: "Eliminar",
        DELETED_CONFIRM: "¿Estás seguro de eliminar esta " + activity + "?",
        DELETED_OK: "La " + activity + " ha sido eliminado exitosamente.",
        DELETED_ERROR:
          "No se pudo eliminar la actividad, favor de informar al administrador del sistema.",
        TRANSFER: "Reasignar " + activity,
        ACTION_MAP_SHOW: "Mostrar mapa",
        ACTION_MAP_HIDE: "Ocultar mapa",
      },
      MODOULE_USERS: "Usuarios",
      USER_LIST: {
        SEARCH: {
          PLACEHOLDER: "Buscar...",
          BUTTON: "Buscar",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Activo",
            INACTIVE: "Inactivo",
            DELETE_TITLE: "¿Estás seguro de eliminar el usuario?",
            DELETE_SUCCESS: "El usuario ha sido eliminado exitosamente.",
            CANCEL_DELETE: "No",
            CONFIRM_DELETE: "Si",
            EDIT_PROFILE: "Editar perfil",
          },
          OPTIONS: {
            RESTOREPASS: "Recuperar contraseña",
            ACTIVATE: "Activar usuario",
            DEACTIVATE: "Desactivar usuario",
            DELETE: "Eliminar usuario",
            RESEND: "Reenviar invitación",
            UNLINK: "Desvincular cuenta",
            RESETPASS: "Cambiar contraseña",
          },
        },
        PAGINATION: {
          PREVIOUS: "Anterior",
          NEXT: "Siguiente",
        },
        RESEND_EMAIL_RESTORE_PASS:
          "El correo electrónico de recuperación de contraseña se ha enviado exitosamente.",
        RESEND_EMAIL_INVITATION: "La invitación se ha enviado exitosamente.",
        UNLINK_USER: "La cuenta se desvinculó exitosamente.",
        ERROR_EMAIL_RESTORE_PASS: "Algo salió mal: {{ error }}",
        SET_MAX_USERS_MODAL: "Máximo de registros permitidos:",
        SET_MAX_USERS_MODAL_ERROR: "Debe de ingresar un número",
        SET_MAX_USERS_ERROR: "Algo salió mal: {{ error }}",
        SET_MAX_SUCCESSFUL: "Actualización correcta",
      },
      TERMS_CONDITIONS: {
        ACEPTED: "Aceptar",
        DECLAIN: "Rechazar",
      },
      TRANSFER_ACTIVITY: {
        ASIGNED: "Asignar",
        CANCEL: "Cancelar",
        REASSING: "Asignar a:",
        LEYEND: "Selecciona el usuario al cual deseas asignar la actividad:",
        ERROR: "Error al reasignar la " + activity + " al nuevo usuario",
      },
      USER_ADD: {
        FORM: {
          TITLE: "Agregar usuario",
          TITLEEDIT: "Editar usuario",
          TITLEVIEW: "Perfil de usuario",
          PHOTO: "Fotografía",
          FIRST_NAME: "Nombre(s)",
          LAST_NAME: "Apellido(s)",
          EMAIL: "Correo electrónico",
          PHONE: "Teléfono",
          JOB: "Posición",
          CITY: "Ciudad",
          ROLE: "Rol",
          AVATAR: "Avatar",
          ROLES_TYPES: {
            ADMINISTRATOR: "Administrador",
            SUPERVISOR: "Supervisor",
            USER: "Usuario",
            CUSTOMER: "Cliente",
            SUPPORT: "Soporte",
            FIELD: "Campo",
          },
          STATUS: "Estatus",
          STATUS_TYPES: {
            ACTIVE: "Activo",
            INACTIVE: "Inactivo",
            SUSPENDED: "Suspendido",
            LOCKED: "Bloqueado",
            BANNED: "Prohibido",
          },
          PASSWORD: "Contraseña",
          REPEAT_PASSWORD: "Repetir contraseña",
          BUTTONS: {
            SAVE: "Guardar",
            CANCEL: "Cancelar",
            CLEAN: "Limpiar",
            ADD: "Agregar",
          },
          OFFICE: "Oficina",
          DEPARTMENT: "Departamento",
        },
        TITLENOTIFICATION: "Notificaciones por cliente",
        TITLEACTIVITIES: activitiesFirstMayus + " por cliente",
        ERRORS: {
          "AUTH/EMAIL-ALREADY-EXISTS":
            "El correo electronico ya esta en uso en otra cuenta.",
          EMPTY_FIRST_NAME: "El nombre esta vacio",
          EMPTY_LAST_NAME: "El apellido esta vacio",
          EMPTY_EMAIL: "El correo electronico esta vacio",
          WRONG_EMAIL: "Correo electronico invalido",
          FORMAT_EMAIL: "Por favor, ingresa un correo electronico valido.",
          EMPTY_ROLE: "Tipo de Perfil esta vacio",
          EMPTY_STATUS: "Estatus esta vacio",
          EMPTY_PASSWORD: "La Contraseña esta vacia ",
          DIFERENT_PASSWORD:
            "Por favor, repita el mismo password para confirmar.",
          GENERAL: "Por favor, ingresa un ",
          GENERAL_FEMENINO: "Por favor, ingresa una ",
          VALID: " válido.",
          VALID_FEMENINO: " válida.",
        },
        SUCCESS: {
          SAVED: "El usuario ha sido registrado exitosamente.",
          UPDATED: "Usuario actualizado exitosamente.",
          ENABLED: "El usuario se ha habilitado exitosamente.",
          DISABLED: "El usuario se ha deshabilitado exitosamente.",
        },
      },
      USER_DELETE: {
        R_U_SURE: "¿Esta seguro?",
        R_U_SURE_TEXT: "¿Estás seguro de que deseas eliminar el usuario?",
        DELETED: "Los usuarios han sido eliminados exitosamente",
        ERRORS: {
          RESPONSE:
            "No se pudo dar de baja los registros, favor de informar al administrador del sistema",
        },
      },
      CONFIGURATION_CLIENT: {
        LOGOTIPO: "Configuración guardada exitosamente.",
        ERROR: "Error al guardar la configuración.",
      },
      FORMS_LIST: {
        SEARCH: {
          PLACEHOLDER: "Buscar...",
          BUTTON: "Buscar",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Activo",
            INACTIVE: "Inactivo",
          },
        },
        PAGINATION: {
          PREVIOUS: "Anterior",
          NEXT: "Siguiente",
        },
        TABLE: {
          NAME: "Nombre del cliente",
          DATE: "Fecha de creación",
          CHECK: "",
          STATUS: "Estatus",
          ACTION: "Acciones",
        },
      },
      FORM_ADD: {
        FORM: {
          DISPLAY_NAME: "Nombre",
          QUESTIONS: "Preguntas",
          INSTRUCTIONS_QUESTIONS:
            "Selecciona el tipo de pregunta y completa las opciones correspondientes.",
          WRITE_HERE: "Por favor, ingresa la pregunta o texto a mostrar.",
          WRITE_HERE_ANSWER: "Por favor, ingresa la opción",
          INSTRUCTION_TITLE: "Por favor, ingresa título de la instrucción.",
          INSTRUCTION_TEXT: "Por favor, ingresa las instrucciones a mostrar.",
          URL: "URL",
          DEFINITION: "Definición",
          DEFINITION_VALIDATE:
            " Por favor, ingresa la definición en formato JSON.",
          DEFINITION_VALIDATE_JSON:
            "Por favor, ingresa la definición en formato JSON valido.",
          URL_VALIDATE: "Por favor, ingresa la información de URL del API.",
          BODY: "Body",
          HEADER: "Headers",
          HEADER_VALIDATE:
            "Por favor, ingresa la información de header en formato JSON.",
          HEADER_VALIDATE_JSON:
            "Por favor, ingresa la información de header en formato JSON valido.",
          INFO_REFERENCE:
            "*Las fotos de referencia solo se visualizan teniendo acceso a internet (en caso de estar en modo off-line, podrá tomar las fotos pero no visualizará la foto de referencia).",
          INFO_INSTRUCTIONS:
            "Las imágenes de las instrucciones solo se visualizan teniendo acceso a internet (en caso de estar en modo off-line, podrá ver las instrucciones pero no visualizará las imágenes adjuntas). Máximo de imágenes permitidas: 5",
          TYPE_OPTIONS: {
            TEXT: "Abierta (texto)",
            DATE: "Fecha",
            DATE_HOUR: "Fecha y hora",
            HOUR: "Hora",
            NUMBER: "Numérico",
            CHECK: "Binaria (si / no)",
            DROPDOWN: "Desplegable (una opción)",
            DROPDOWN_SEARCH: "Búsqueda predicativa",
            DROPDOWN_MULTIPLE: "Desplegable (múltiple)",
            TEXTAREA: "Área de comentario",
            TELEPHONE: "Teléfono",
            EMAIL: "Correo",
            PERCENTAGE: "Porcentaje",
            CALCULATOR: "Calculadora",
            QUALIFICATION: "Calificación",
            QR: "Lector QR",
            BARCODE: "Código de barras",
            INSTRUCTION: "Instrucción",
            PHOTO: "Foto Evidencia",
          },
          BUTTONS: {
            SAVE: "Guardar",
            ACCEPT: "Aceptar",
            CANCEL: "Cancelar",
            CLEAN: "Limpiar",
            ADD: "Agregar",
            UPLOAD_IMAGES: "Subir Imágenes",
            UPLOAD_THUMBNAIL: "Subir Thumbnail",
          },
        },
        ERRORS: {},
        SUCCESS: {
          SAVED: "El tipo de " + activity + " ha sido registrada exitosamente.",
          UPDATED:
            "El tipo de " + activity + " ha sido actualizada exitosamente.",
        },
      },
      FORM_DELETE: {
        CLONED: "La " + task + " personalizada se clono correctamente",
        R_U_SURE: "El formulario será eliminado",
        R_U_SURE_TEXT:
          "El formulario dejará de estar disponible para su configuración en una " +
          activity,
        DELETED: "El formulario seleccionado ha sido eliminado",
        ERRORS: {
          RESPONSE:
            "No se pudo eliminar el formulario, favor de informar al administrador del sistema",
          CLONED: "No se pudo clonar la " + task + " personalizada",
        },
      },
      EVENTS_LIST: {
        SEARCH: {
          PLACEHOLDER: "Buscar...",
          BUTTON: "Buscar",
        },
        CLEAN_FILTERS: {
          BUTTON: "Limpiar filtros",
        },
        LIST: {
          THUMBNAIL: {
            ACTIVE: "Activo",
            INACTIVE: "Inactivo",
          },
        },
        PAGINATION: {
          PREVIOUS: "Anterior",
          NEXT: "Siguiente",
        },
        TABLE: {
          NAME: "Nombre",
          FORMS: "Formularios relacionados",
          CATEGORY: "Categoría",
          ORDER: "Orden",
          STATUS: "Estatus",
          DATE: "Fecha de creación",
          ACTION: "Acciones",
        },
      },
      EVENTS_ADD: {
        FORM: {
          TITLE_ELEMENT_PERSONALIZED: "Configurar " + task + " personalizada",
          SUBTITLE_ELEMENT_PERSONALIZED:
            "Ingresa las preguntas y opciones que conformarán la " + task + ".",
          TITLE_EVENTS_ADD_NAME: "Agregar " + task,
          TITLE_EVENTS_ADD_EVENT: "Nombre de la " + task,
          TITLE_PERSONALIZED: "Agregar " + tasks + "  personalizadas",
          SUBTITLE_PERSONALIZED:
            "Selecciona las " +
            tasks +
            "  personalizadas que se agregarán o crea una nueva.",
          CONFIGURATION: "Configuración",
          CONFIGURATION_SUB:
            "Arrastra o crea las " +
            tasks +
            "  que se mostrarán en las " +
            activities +
            " de este tipo en la app móvil. Considerar que el límite máximo de fotos recomendado es de 70 fotos, para poder visualizar el reporte PDF.",
          TITLE_EVENTS_ADD: "Configurar tipo de " + activity,
          TITLE_EVENTS_ADD_SUB: "",
          TITLE_EVENTS: "Tipos de " + activity,
          DISPLAY_NAME: "Nombre de la " + activity,
          ORDER: "Orden",
          CREATE_ORDERS: "Permitir crear " + activities,
          NEW_ORDERS: "Permitir agregar " + tasks + "  adicionales",
          SELECT_CATEGORY: "Permitir seleccionar categoría",
          SELECT_OUT_RANGE: "Permitir validar el rango del Check Out",
          SELECT_CUSTOMER: "Permitir seleccionar cliente",
          SELECT_EQUIPMENT: "Permite seleccionar equipo",
          SELECT_CARRET: "Permitir subir fotos del carrete",
          SELECT_AUTORIZE_EVENTS: "Permitir autorizar " + activities,
          SELECT_TRANSFER_EVENTS: "Permitir transferir " + activities,
          TOOLTIP_REJECT: "Permite Rechazar Asignación",
          TOOLTIP_TRANSFER_EVENTS:
            "Permitirá que se pueda transferir la actividad a otros usuarios desde la aplicación.",
          TOOLTIP_CARRET:
            "Permite agregar fotos del carrete cuando se va a agregar una foto en la aplicación móvil.",
          QUESTIONS: "Preguntas",
          FORMS: "Selecciona los formularios",
          WRITE_HERE: "Por favor, ingresa la pregunta o texto a mostrar.",
          REJECT: "Rechazar Asignación",
          TYPE_OPTIONS: {
            TEXT: "Abierta (Texto)",
            CHECK: "Binaria (Si / No)",
            DROPDOWN: "Desplegable (una opción)",
            DROPDOWN_MULTIPLE: "Desplegable (múltiple)",
            TEXTAREA: "Área de Comentario",
          },
          BUTTONS: {
            SAVE: "Guardar",
            CANCEL: "Cancelar",
            CLEAN: "Limpiar",
            ADD: "Agregar",
          },
        },
        ERRORS: {},
        XLSX: {
          DOWNLOAD: "Descargar reporte de",
          DOWNLOAD_BTN: "Descargar",
          DOWNLOAD_CANCEL: "Cancelar",
          MESSAGE:
            "*La descarga del reporte puede demorar ciertos segundos, favor de no cerrar la ventana emergente hasta que se descargue el documento.",
          ERROR: {
            EMPTY: "Por favor, ingrese el rango de fechas.",
          },
        },
        SUCCESS: {
          SAVED: "El tipo de " + activity + " ha sido registrado exitosamente.",
          UPDATED:
            "El tipo de " + activity + " ha sido actualizada exitosamente.",
        },
      },
      EVENTS_DELETE: {
        R_U_SURE: "Confirmación",
        R_U_SURE_TEXT: "¿Estás seguro de eliminar este tipo de actividad?",
        DELETED: "",
        ERRORS: {
          RESPONSE:
            "No se pudo eliminar la actividad, favor de informar al administrador del sistema",
        },
      },
      CUSTOMER_LIST: {
        TABLE: {
          USER: "Registrado por",
          DATE: "Fecha de registro",
        },
        ADD: {
          ADD_CUSTOMER: "Agregar cliente",
          EDIT_CUSTOMER: "Editar cliente",
          PHOTO: "Logo",
          NAME: "Cliente/empresa",
          STATUS: "Estatus",
          EMPTY_NAME: "Por favor, ingresa el nombre del cliente",
          EMPTY_STATUS: "Por favor, selecciona el estatus del cliente.",
          CUSTOMER_UPDATED: "El cliente ha sido actualizado exitosamente.",
          CUSTOMER_SAVED: "El cliente ha sido registrado exitosamente.",
          CUSTOMER_DELETED: "El cliente ha sido desactivado exitosamente.",
          CUSTOMER_INACTIVATED: "El cliente ha sido eliminado exitosamente.",
          CUSTOMER_R_SURE: "¿Estás seguro que deseas eliminar el cliente?",
          R_U_SURE: "El cliente será eliminado.",
          ACTIVITY_NOTIFY:
            "Si es una " +
            activity +
            " programada, notificar al momento que se programe la " +
            activity,
          ACTIVITY_BEFORE:
            "Si es una " + activity + " programada notificar antes de un día.",
          ACTIVITY_ICS:
            "Si es una " +
            activity +
            " programada enviar por correo archivo tipo calendario (.ics)",
          ACTIVITY_START: "Cuando se inicia una " + activity + "",
          ACTIVITY_CLOSE: "Cuando se cierra una " + activity + "",
        },
      },
      CUSTOMERS: {
        EDIT: "Editar",
        VIEW: "Ver",
        RETURN: "Regresar",
      },
      ADD_ITEM_DIALOG: {
        ERROR: {
          EMPTY: "Por favor, ingresa el nombre de la " + task + ".",
          DUPLCATE:
            "Por favor, agrega un nombre diferente a la " +
            task +
            " que esta copiando",
          NO_PHOTOS: "*Subir al menos una foto de referencia.",
        },
      },
      RESET_PASSWORD: {
        NEW_PASSWORD: "Nueva contraseña",
        CONFIRM_PASSWORD: "Confirmar contraseña",
        BTN_SAVE_NEW_PASSWORD: "Guardar nueva contraseña",
        SUCCESSUPDATED: "Su contraseña ha sido cambiada con éxito.",
        ERRORUPDATED:
          "La URL no es válida. Por favor, póngase en contacto con el administrador del sistema.",

        ERROR_REQUIRED_PASSWORD: "Por favor, introduzca la contraseña.",
        ERROR_REQUIRED_CPASSWORD:
          "Por favor, introduzca la confirmación de la contraseña.",
        ERROR_INVALID_UPPERLOWER_PASSWORD:
          "Su contraseña debe tener una letra mayúscula y una minúscula.",
        ERROR_NUMBER_MISSING_PASSWORD: "Su contraseña debe tener un número.",
        ERROR_MINLENGTH_PASSWORD:
          "Su contraseña debe tener al menos 8 caracteres.",

        ERROR_MATH_PASSWORD:
          "La confirmación de la contraseña no coincide con la nueva contraseña.",
      },
      EQUIPMENT: {
        TITLE_LIST: "Equipos",
        TEMPLATES: {
          TITLE: "Tipos de equipo",
        },
        ADD: {
          TITLE: "Configurar items",
          DESC: "Ingresa las preguntas y opciones para conformarán el equipo.",
          WRITE_HERE_NAME: "Por favor, ingresa el nombre.",
          WRITE_HERE: "Por favor, ingresa la respuesta.",
          VALIDATION_ID_EQUIPMENT:
            "El Id ingresado ya existe, por favor, ingresa uno diferente.",
          WRITE_HERE_NUMERIC: "Por favor, ingresa un valor con .",
          INSERT_IMAGE: "Ingresa al menos una imagen.",
          CONFIRM_SAVE_TEMPLATE: "¿DESEA GUARDAR EL TIPO DE EQUIPO?",
          CONFIRM_NAME_TEMPLATE: "INGRESA EL NOMBRE DEL TIPO DE EQUIPO",
          CONFIRM_DESC_TEMPLATE:
            "Puede guardar los campos seleccionados como un tipo de equipo para crear futuros equipos o puede guardar un equipo sin necesiidad de crear un tipo de equipo.",
        },
        EQUIPMENT_LIST: {
          ID: "ID DEL EQUIPO",
          TYPE: "TIPO DE EQUIPO",
          NAME: "NOMBRE DEL EQUIPO",
          BY: "REGISTRADO POR",
          CREATED: "FECHA DE REGISTRO",
          STATUS: "ESTATUS",
          ACTIONS: "ACCIONES",
          SEARCH: {
            ID: "Buscar por id...",
          },
        },
      },
      PROGRAMMING: {
        SCHEDULED_CREATING_DATE: "FECHA Y HORA DE CREACION",
        TYPE_EVENT: "TIPO DE  " + activityMayus,
        NAME_EVENT: "NOMBRE DE LA " + activityMayus,
        ASIGNEDTO: "ASIGNADO A",
        CLIENT: "CLIENTE",
        TYPE_FORM_EVENT: "Tipo de actividad",
        NAME_FORM_EVENT: "Nombre de actividad",
        ASIGNEDTO_FORM: "Asignado a",
        CLIENT_FORM: "Cliente",
        EQUIPMENT: "Equipo",
        STATUS: "ESTATUS",
        SCHEDULED_LIMIT_DATE: "FECHA Y HORA LIMITE",
        SCHEDULED_PROGRAMMING_DATE: "FECHA Y HORA PROGRAMADA",
        ACTIONS: "ACCIONES",
        FILTER_PER: "Filtrar por...",
        STATUS_PER: "Estatus por...",
        PERIODICITY: "Periodicidad",
        ESTIMATED_DURATION: "Duración estimada",
        ZONE: "Zona",
        ESTIMATED_DURATION_HOUR: "Horas",
        ESTIMATED_DURATION_MINUTES: "Minutos",
        SCHEDULED_DATE_PROGRAMMING: "Fecha",
        HOUR_SICE: "Hora de inicio",
        HOUR_LIMIT: "Hora límite",
        EXPIRATION: "Vencimiento en:",
        EXPIRATION_DATE: "Fecha Vencimiento:",
        DAYS: "Días",
        UBICATION: "Añadir ubicación",
        COMENTARYS: "Comentarios/Instrucciones",
        FILTER_DATE_CREATION: "Fecha de creación",
        FILTER_FILTER_EVENT: "Tipo de actividad",
        FILTER_FILTER_STATUS: "Estatus",
        FILTER_DATE_PROGRAMING: "Fecha de programacion",
        FILTER_DATE_LIMIT: "Fecha de limite",
        DATE_INI: "Fecha inicio",
        DATE_FIN: "Fecha fin",
        TITLE_LIST: activitiesFirstMayus + " programadas",
        TITLE_ADD: "Programar actividad",
        TITLE_DETAIL_MODAL_CALENDAR: activitiesFirstMayus + "del día",
      },
      CLASSIFICATION_SECTION: {
        TITLE_LIST: "Categorías",
        MODAL_TITLE_CAT: "Categorías",
        MODAL_TITLE_SUB: "Subcategorías",
        FORM: {
          NAME_CATEGORY: "Nombre de la categoría",
          NUMBER_CATEGORY: "Categoría {{number}} ",
          NAME_SUBCATEGORY: "Nombre de la subcategoría",
          CATEGORY_SELECTED: "Categoría a la que pertenece",
          NUMBER_SUBCATEGORY: "Subcategoría {{number}} ",
          LABEL_NAME_MODAL_EDIT_CAT: "Nombre de la Categoría ",
          LABEL_TITLE_MODAL_EDIT_CAT: "Editar Categoría",
          LABEL_NAME_MODAL_EDIT_SUB: "Nombre de la Subcategoría ",
          LABEL_TITLE_MODAL_EDIT_SUB: "Editar Subcategoría",
        },
        MESSAGE_CONFIRM_DELETE_CAT:
          "¿Esta seguro que desea eliminar esta categoría?",
        MESSAGE_CONFIRM_DELETE_SUB:
          "¿Esta seguro que desea eliminar esta subcategoría?",
      },
      CONFIGURATION: {
        WEBHOOK: "Webhook",
        HEADERS: "Headers",
        HELP:
          "* El sistema realizará los envíos de información cuando una " +
          activity +
          " cambie de estatus o sea creada.",
        ERROR: {
          URL: "Por favor, ingresa la información de URL del webhook.",
          HEADER:
            "Por favor, ingresa la información de header en formato JSON.",
        },
        TIME_ZONE: {
          TITLE: "Zona horaria",
          DESCRIPTION:
            "La zona horaria seleccionada será para regir el horario de las actividades, su programación, vencimiento, hora de inicio y fin.",
          ERROR: {
            MSG: "Este campo es requerido.",
          },
        },
        HOUR_LABOR: {
          TITLE: "Horario laboral",
          DESCRIPTION:
            "El horario laboral seleccionado será para delimitar horas y días de las actividades programadas, como la disponibilidad de los usuarios.",
        },
      },

      DASHBOARDREPORT: {
        COUNTEVENTS: "Cantidad de " + activitiesFirstMayus,
        THEVENT: "Tipo de actividad",
        THCLIENT: "Cliente",
        THUSER: "Usuario",
        THEQUIPMENT: "Equipo",
        FILTERBYLABEL: "Desglose de actividades por",
        FILTERBYEVENTLABEL: "Tipo de actividad",
        FILTERBYCLIENTLABEL: "Cliente",
        FILTERBYEQUIPMENTLABEL: "Equipo",
        FILTERBYUSERLABEL: "Usuario",
        FILTERBYCATLABEL: "Categoría",
        FILTERBYTIMELABEL: "Periodo de tiempo",
        TITLE: "Dashboard",
        TOTALCOUNT: "Recuento total",
        STATUS: "Estatus",
        ERRORSERVICES: "Algo salió mal",
        FILTERBYDATES: "Periodo de tiempo",
        FILTERBYDATESSTARTDATE: "Fecha inicio",
        FILTERBYDATESENDDATE: "Fecha fin",
        INFOLEYEND: "Desglose por",
        DATERANGE: "Periodo del",
        TO: "al",

        OPTIONTODAY: "Hoy",
        OPTIONTOLASTWEEK: "Última semana",
        OPTIONTO2LASTWEEK: "Últimas 2 semanas",
        OPTIONLASTMONTH: "Último mes",
        OPTIONTORANGE: "Rango de fechas",
        DOFIND: "Aplicar",
        DOCLEAN: "Limpiar filtros",
        SELECTALL: "Todos",
        SELECTDETAIL: "Selecciona detalle",
        SELECTFILTERTYPE: "Selecciona desglose",
        SELECTFILTERRANGE: "Selecciona periodo",
        ERRORNORESULT:
          "No se encontraron " +
          activities +
          " registradas con los filtros de búsqueda seleccionados. Favor de actualizar los criterios y realizar la búsqueda nuevamente",
      },
    },
  },
};
