import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DepartamentosMarker } from '@app/models/departamentos_marker.model';
import { SucursalesMarker } from '@app/models/sucursales_marker.model';
import { TiposMarker } from '@app/models/tipos_marker.model';
import { UserMarker } from '@app/models/user_marker.model';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { ClienteMarker } from '@app/models/cliente_marker.model';
import { Equipment } from '@app/models/equipment.model';
import { CategoryMarker } from '@app/models/category_marker.model';
import { SubcategoryMarker } from '@app/models/subcategory_marker.model';
import { StorageService } from '@app/core/storage.service';


@Component({
  selector: 'app-search-map',
  templateUrl: './search-map.component.html',
  styleUrls: ['./search-map.component.scss']
})
export class SearchMapComponent implements OnInit {

  
  @Input() users: UserMarker[] = [];
  @Input() tipos: TiposMarker[] = [];
  @Input() sucursales: SucursalesMarker[] = [];
  @Input() departamentos: DepartamentosMarker[] = [];
  @Input() clientes: ClienteMarker[] = [];
  @Input() equipments: Equipment[] = []
  @Input() categories: CategoryMarker[] = [];
  @Input() subcategories: SubcategoryMarker[] = [];

  @Input() isRange: boolean;
	@Input() name_category: string = '';
	@Input() name_subcategory: string = '';

  @Output() apply_search = new EventEmitter<string>();
  
  @Output() goDateRange = new EventEmitter();
  @Output() type1_select = new EventEmitter<string>();

  @Output() type1_selectUser = new EventEmitter();
  @Output() type1_selectTipo = new EventEmitter();
  @Output() type1_selectSucursal = new EventEmitter();
  @Output() type1_selectDepartamento = new EventEmitter();
  @Output() type1_selectCliente = new EventEmitter();
  @Output() type1_selectEquipment = new EventEmitter();
  @Output() type1_selectCategory = new EventEmitter();
  @Output() type1_selectSubcategory = new EventEmitter();

  @Output() close = new EventEmitter();

  @ViewChild('searchMarkes') searchMarkes: ElementRef;

  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  depSel: string[] = []
  tipoSel: string[] = []
  usersSel: string[] = []
  sucursalSel: string[] = []
  clientsSel: string[] = []
  equipmentsSel: string[] = []
  categoriesSel: string[] = []
  subcategoriesSel: string[] = []
  form: FormGroup;
  constructor(public storageService: StorageService) { }

  ngOnInit() {
    let filtersDashboard = JSON.parse(this.storageService.getFiltersDashboard());
      if(filtersDashboard && filtersDashboard.isSearching){
        if (filtersDashboard.hasOwnProperty("nameEvent"))
        this.searchMarkes.nativeElement.value = filtersDashboard.nameEvent;  
        if (filtersDashboard.hasOwnProperty("typeEvent"))
          this.tipoSel = filtersDashboard.typeEvent;
        if (filtersDashboard.hasOwnProperty("oficce"))
          this.sucursalSel = filtersDashboard.oficce;
        if (filtersDashboard.hasOwnProperty("role"))
          this.depSel = filtersDashboard.role;
        if (filtersDashboard.hasOwnProperty("user"))
          this.usersSel = filtersDashboard.user;
        if (filtersDashboard.hasOwnProperty("client"))
          this.clientsSel = filtersDashboard.client;
        if(filtersDashboard.hasOwnProperty('equipment'))
          this.equipmentsSel = filtersDashboard.equipment;  
        if (filtersDashboard.hasOwnProperty("category"))
          this.categoriesSel = filtersDashboard.category;
        if (filtersDashboard.hasOwnProperty("subcategory"))
          this.subcategoriesSel = filtersDashboard.subcategory;
        this.applySearch();
      }
  }

  applySearch() {
    this.apply_search.emit(this.searchMarkes.nativeElement.value);

    if (!this.isRange) {
      this.type1_select.emit();
    } else {
      this.goDateRange.emit();
    }
  }

  
  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.applySearch();
    }
  }

  clearSearch() {
    this.depSel = []
    this.tipoSel = []
    this.usersSel = []
    this.sucursalSel = []
    this.clientsSel = []
    this.equipmentsSel = []
    this.categoriesSel = []
    this.subcategoriesSel = []
    this.searchMarkes.nativeElement.value = '';
    this.apply_search.emit('');
    this.type1_selectTipo.emit(null);
    this.type1_selectUser.emit(null);
    this.type1_selectSucursal.emit(null);
    this.type1_selectDepartamento.emit(null);
    this.type1_selectCliente.emit(null);
    this.type1_selectEquipment.emit(null)
    this.type1_selectCategory.emit(null);
    this.type1_selectSubcategory.emit(null);
    if (!this.isRange) {
      this.type1_select.emit();
    } else {
      this.goDateRange.emit();
    }
    this.storageService.removeFiltersDashboard();
  }

  selectedSucursal(event) {
    this.sucursalSel = event;
    this.type1_selectSucursal.emit(this.sucursalSel);
  }

  selectedTipos(event) {
    this.tipoSel = event;
    this.type1_selectTipo.emit(this.tipoSel);
  }

  selectedUsers(event) {
    this.usersSel = event;
    this.type1_selectUser.emit(this.usersSel);
  }

  selectedDepartamentos(event) {
    this.depSel = event;
    this.type1_selectDepartamento.emit(this.depSel);
  }

  selectedClientes(event) {
    this.clientsSel = event;
    this.type1_selectCliente.emit(this.clientsSel);
  }

  selectedEquipments(event){
    this.equipmentsSel = event;
    this.type1_selectEquipment.emit(this.equipmentsSel)
  }

  selectedCategories(event) {
    this.categoriesSel = event;
    this.type1_selectCategory.emit(this.categoriesSel);
  }

  selectedSubcategories(event) {
    this.subcategoriesSel = event;
    this.type1_selectSubcategory.emit(this.subcategoriesSel);
  }

  endSearch() {
    this.close.emit();
  }

}
