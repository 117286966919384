import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { EventsService } from "../../core/events.service";
import { FormsService } from "../../core/forms.service";
import { AuthService } from "../../core/auth.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  NgModel,
} from "@angular/forms";
import { StorageService } from "../../core/storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  MatDialog,
  MatOption,
  MatSelect,
  MatSlideToggleChange,
} from "@angular/material";
import { AddItemDragComponent } from "../add-item-drag/add-item-drag.component";
import { FormularioListComponent } from "@app/formularios/formulario-list/formulario-list.component";
import { ModalGenericService } from "@app/services/modal-generic.service";
import { Location } from "@angular/common";
import { map, startWith, take, takeUntil } from "rxjs/operators";
import { FormularioAddComponent } from "@app/formularios/formulario-add/formulario-add.component";
import * as Moment from "moment";
import { ENTER, COMMA, I } from "@angular/cdk/keycodes";
import { UserService } from "@app/core/user.service";
import { UtilsService } from "@app/core/helper/utils.helper";
import { MatSelectModule } from "@angular/material";
import { ClassificationService } from "@app/core/classification.service";
import { Observable, ReplaySubject, Subject } from "rxjs";

interface UserSearch {
  uid: string;
  name: string;
}

@Component({
  selector: "app-events-add",
  templateUrl: "./events-add.component.html",
  styleUrls: ["./events-add.component.scss"],
})
export class EventsAddComponent implements OnInit, AfterViewInit {
  listUsersNotifications = [];
  listUSersConfig = [];

  id_count_form_control = 1;
  id_confirmed_count_form_control = 1;
  url_icon_mapa: string =
    //'https://firebasestorage.googleapis.com/v0/b/trackpoint-demos.appspot.com/o/icons_default%2Ficons_default_location.png?alt=media&token=7a42a154-1eb4-4061-ad11-532df9efd33f';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FCheckIn.svg?alt=media&token=ec658a11-2fb4-46bc-91fa-1ca4616a3a0d";
  url_icon_fotos: string =
    //'https://firebasestorage.googleapis.com/v0/b/trackpoint-demos.appspot.com/o/icons_default%2Ficons_default_insert_photo.png?alt=media&token=4b4ea0de-1c04-484e-9300-9e2a5a26fb34';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FFotoEvidencia.svg?alt=media&token=23b9090a-ae53-4014-a3eb-44007d8297b2";
  url_icon_firma: string =
    //'https://firebasestorage.googleapis.com/v0/b/trackpoint-demos.appspot.com/o/icons_default%2Ficons_default_firma.png?alt=media&token=6afce37f-c7d1-410d-95fa-aeb0ce3bd0fb';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FFirma.svg?alt=media&token=15133192-6a7d-4d10-a6ca-057774977721";
  url_icon_preguntas: string =
    //'https://firebasestorage.googleapis.com/v0/b/andamios-app.appspot.com/o/icons_default%2Fdone.png?alt=media&token=c56d75c1-baf3-4b37-9e95-3d9f337d57c9';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FTareasFormulario.svg?alt=media&token=79eab3ec-d09e-4c2e-8b51-2961cfa67dfa";
  url_icon_comentarios: string =
    //'https://firebasestorage.googleapis.com/v0/b/andamios-app.appspot.com/o/icons_default%2Fdrive.png?alt=media&token=c072c32c-2b02-4f26-bbc4-58eeeb70b78d';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FNotas.svg?alt=media&token=0d8c779d-0629-47cf-969a-fb377bba39bb";
  url_icon_fotos_referencia: string =
    //'https://firebasestorage.googleapis.com/v0/b/trackpoint-demos.appspot.com/o/icons_default%2Ficons_default_insert_photo.png?alt=media&token=4b4ea0de-1c04-484e-9300-9e2a5a26fb34';
    "https://firebasestorage.googleapis.com/v0/b/trackpoint-demos-webpoint.appspot.com/o/icons_default%2Fv2%2FFotoReferencia.svg?alt=media&token=63368127-19c9-434d-93aa-1008105850a9";
  event = new FormGroup({
    title: new FormControl("", [Validators.required]),
    order: new FormControl(""),
    date_created: new FormControl(""),
    active: new FormControl(true),
    selectCustomer: new FormControl(false),
    selectEquipment: new FormControl(false),
    active_category: new FormControl(false),
    active_out_range: new FormControl(false),
    active_autorize_events: new FormControl(false),
    active_transfer: new FormControl(false),
    reject_assignment: new FormControl(false),
    active_carret: new FormControl(false),
    createOrders: new FormControl(false),
    createElements: new FormControl(false),
    forms: new FormArray([]),
    configuration: new FormArray([]),

    configuration_doc: new FormControl(""),
  });

  zoneUserActive: string[] = [];
  filterAllUsers = true;
  filterZoneUsers = false;

  array_elements = [
    {
      id: this.id_count_form_control++,
      nextScreen: "MAPA",
      title: "Confirmar Ubicación",
      url_icon: this.url_icon_mapa,
      confirmed: false,
      photos_reference: [{}],
    },
    {
      id: this.id_count_form_control++,
      nextScreen: "FOTOS",
      title: "Foto evidencia",
      url_icon: this.url_icon_fotos,
      confirmed: false,
      photos_reference: [{}],
    },
    {
      id: this.id_count_form_control++,
      nextScreen: "FIRMA",
      title: "Firma de recibido",
      url_icon: this.url_icon_firma,
      confirmed: false,
      photos_reference: [{}],
    },
    {
      id: this.id_count_form_control++,
      nextScreen: "COMENTARIOS",
      title: "Comentarios",
      url_icon: this.url_icon_comentarios,
      confirmed: false,
      photos_reference: [{}],
    },
    {
      id: this.id_count_form_control++,
      nextScreen: "FOTOS DE REFERENCIA",
      title: "Imágenes de Referencia",
      url_icon: this.url_icon_fotos_referencia,
      confirmed: false,
      photos_reference: [{}],
    },
  ];

  confirmed_array_elements = [
    {
      id: this.id_confirmed_count_form_control++,
      nextScreen: "MAPA",
      title: "Ubicación de Check In",
      url_icon: this.url_icon_mapa,
      confirmed: true,
      questionary_id: "",
      required: true,
      photos_reference: null,
      additional: null,
      carret: null,
    },
  ];

  auxArray = [
    {
      id: this.id_confirmed_count_form_control++,
      nextScreen: "MAPA",
      title: "Ubicación de Check In",
      url_icon: this.url_icon_mapa,
      confirmed: true,
      questionary_id: "",
      required: true,
      photos_reference: null,
      additional: null,
      carret: null,
    },
  ];

  uid: string = "";
  private sub: any;
  errorTitleEmpty = false;
  is_new = false;
  // formList = [];
  // confirmed = [];
  array_add_questionaries = [];
  imgSrc: string = "/assets/lupa.jpg";

  today: number;
  id_question = "";
  name_question = "";

  auxDialog: boolean = true;
  create_orders = "Se permitirá crear actividades desde la aplicación móvil.";
  errorAuthorizersEmpty: boolean = false;

  get isEdit(): Boolean {
    return this.uid !== "";
  }

  get forms() {
    return this.event.get("forms") as FormArray;
  }

  get optionsFilterUser() {
    return [
      {id: '01', text: 'Todos los usuarios'},
      {id: '02', text: 'Usuarios en la zona'},
    ]
  }

  // get availabelForms() {
  //   return this.formList.filter(f=> !f.deleted);
  // }

  get titleHasError(): boolean {
    return (
      this.event.get("title").invalid &&
      (this.event.get("title").dirty || this.event.get("title").touched)
    );
  }

  get questionsHasError(): boolean {
    return this.forms.invalid;
  }

  get data() {
    return {
      name: this.event.get("title").value,
      forms: [],
      active: this.event.get("active").value,
      order: this.event.get("order").value,
      date_created: this.event.get("date_created").value,
      createOrders: this.event.get("createOrders").value,
      createElements: this.event.get("createElements").value,
      selectCustomer: this.event.get("selectCustomer").value,
      selectEquipment: this.event.get("selectEquipment").value,
      active_category: this.event.get("active_category").value,
      active_out_range: this.event.get("active_out_range").value,
      active_autorize_events: this.event.get("active_autorize_events").value,
      active_transfer: this.event.get("active_transfer").value,
      reject_assignment: this.event.get("reject_assignment").value,
      active_carret: this.event.get("active_carret").value,
      configuration: (this.event.get("configuration") as FormArray).controls
        .filter((f) => f.get("selected").value)
        .map((m) => m.value),
      configuration_doc: this.event.get("configuration_doc").value,
      users_autorized: {
        all: true,
        user_uid: [],
      },
    };
  }

  get PreguntasOption(): FormControl | any {
    return (
      (this.event.get("configuration") as FormArray).controls.find(
        (c) => c.get("id").value === 2
      ) || {}
    );
  }

  get getUserList() {
    return (
      this.user_list &&
      this.user_list
        .filter((f) => {
          return (
            this.utilsService.search(f, "displayName", "") ||
            this.utilsService.search(f, "email", "") ||
            this.utilsService.search(f, "role", "")
          );
        })
        .filter((item) => {
          return (  item.email != 'admin@trackpoint.com' );
        })
      //.filter((item) => item.profile !== 'SUPERADMIN')
    );
  }

  get getUserListConfig() {
    return (
      this.user_list &&
      this.user_list.filter((f) => {
        if(this.filterAllUsers)
        return (
          this.utilsService.search(f, "displayName", "") ||
          this.utilsService.search(f, "email", "") ||
          this.utilsService.search(f, "role", "")
        );
        if (this.filterZoneUsers)
          return (
          this.utilsService.search(f, "displayName", "") ||
          this.utilsService.search(f, "email", "") ||
          this.utilsService.search(f, "role", "")
        ) && this.zoneUserActive.includes(f.uid);
      })
      //.filter((item) => item.profile !== 'SUPERADMIN')
    );
  }

  onchangeFilterUsers() {
    this.filterAllUsers = !this.filterAllUsers;
    this.filterZoneUsers = !this.filterZoneUsers;

    if (this.filterZoneUsers) {
      this.listUSersConfig = this.listUSersConfig.filter(item => {
        return this.zoneUserActive.includes(item.uid);
      });
    } else {
      this.listUSersConfig = this.getUserList;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  formularioNotify = {
    checkinit: false,
    checkclose: false,
    checkscheduled: false,
    checkscheduledcalendar: false,
    inputvalue: "",
    selecttype: "",
  };

  formularioNotifydisabled = {
    inputvalue: true,
    selecttype: true,
  };

  formularioPush = {
    checkinit: false,
    checkclose: false,
    checkscheduled: false,
    userselected: [],
  };

  formuserconfig = {
    all: true,
    userselected: [],
  };
  quantityUsers: number = 0;
  userSelect = [];

  firebase_uid_notify = "";
  firebase_uid_push = "";
  firebase_uid_autorize = "";
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  correo_invalido = "";
  cel_invalido = "";
  emails = [];
  celphones = [];

  onlyNumbers = /^\d+$/;
  max = 24;
  public user_list: any = [];
  onDestroy: Subject<any> = new Subject<any>();

  @ViewChild("allSelected") private allSelected: MatOption;

  userCtrl = new FormControl();
  filteredUsersAutorize: Observable<string[]>;
  usersAutorize: string[] = [];
  usersAutorizeUids: string[] = [];
  autorize = false;
  @ViewChild("userInput") userInput: ElementRef<HTMLInputElement>;

  constructor(
    public eventsService: EventsService,
    public formsService: FormsService,
    public authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public modelGeneric: ModalGenericService,
    private location: Location,
    public userService: UserService,
    public utilsService: UtilsService,
    private clasifications: ClassificationService
  ) {
    if (authService.customerDB != null) {
      eventsService.customerDB = authService.customerDB;
      eventsService.customerSelected = authService.customerSelected;
      formsService.customerDB = authService.customerDB;
      formsService.customerSelected = authService.customerSelected;
    }

    var todayDate = new Date().getTime();
    this.today = todayDate;

    this.formsService.getSettings().subscribe((response) => {
      if (response.data[0].comentarios) {
        var id_questionary = parseInt(response.data[0].comentarios);

        this.formsService
          .getQuestionaryById({ id: id_questionary })
          .subscribe((response_id) => {
            this.id_question = response_id.data[0].id_doc;
            console.log("ID CUESTION " + this.id_question);
            // this.name_question = response_id.data[0].questionnaire_name;
          });
      } else {
        console.log("NO TIENE ID DE CUESTIONARIO ASIGNADO");
      }
    });

    this.filteredUsersAutorize = this.userCtrl.valueChanges.pipe(
      startWith(""),
      map((user: string | null) =>
        user ? this._filter(user) : this.getUserList.slice()
      )
    );
    setTimeout(() => {
      this.getZoneByUserId();
    }, 2000)
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.usersAutorize.push(value);
    }

    // Clear the input value
    // Clear the input value
    if (event.input) {
      event.input.value = "";
    }

    this.userCtrl.setValue(null);
  }

  async getZoneByUserId() {
    const session = this.storageService.getCurrentSession();
    const uid = session.uid;
    let firebase_tenan = await this.authService.getTenancyParams();
    const querySnapshot = await firebase_tenan.collection('zones').where('users', 'array-contains', uid).get();
    console.log(`query data ${!querySnapshot.empty}`)
    querySnapshot.forEach((doc) => {
      const { users = [] } = doc.data();
      users.forEach(item => {
        if (!this.zoneUserActive.includes(item)) {
          console.log(`user ${item}`);
          this.zoneUserActive.push(item);
        }
      });
    });
  }

  remove(user: string): void {
    const index = this.usersAutorize.indexOf(user);

    if (index >= 0) {
      this.usersAutorize.splice(index, 1);
      this.usersAutorizeUids.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //alert(event.option.value.uid);
    this.errorAuthorizersEmpty = false;
    this.usersAutorize.push(event.option.viewValue);
    this.usersAutorizeUids.push(event.option.value.uid);
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }

  private _filter(value: string): any[] {
    const filterValue = value["displayName"]
      ? value["displayName"].toLowerCase()
      : value.toLowerCase();
    return this.getUserList.filter((user) =>
      new RegExp(value, "gi").test(user["displayName"])
    );
  }
  protected _onDestroy = new Subject<void>();
  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.spinner.show();
      this.formsService.getFormsList().subscribe((response) => {
        this.uid = params["id"] || "";
        if (this.uid) {
          console.log("THIS UID " + this.uid);

          this.formsService
            .getConfigITems({ id: this.uid })
            .subscribe((response_) => {
              let items_config = response_.data;

              console.log("ITEMS CONFIG :__ " + JSON.stringify(items_config));
              /*
              Se deshabilito la función de ordenamiento, ya que tomaba el Id para odernar los items, en este caso
              se ordenan conforme fueron agregados
              */

              var copy_array_items = this.clone(items_config.rows);

              var array_sort = copy_array_items.sort(function (a, b) {
                if (a.id > b.id) {
                  return -1;
                }
                if (a.id < b.id) {
                  return 1;
                }
              });

              this.id_count_form_control = array_sort[0].id + 1;
              console.log(
                "id_count_form_control :__ " + this.id_count_form_control
              );

              //this.setCountId(items_config.rows);
              this.auxArray = [];
              this.auxArray = items_config.rows;

              for (let i = 0; i < this.auxArray.length; i++) {
                if (
                  this.auxArray[i].questionary_id != "" &&
                  this.auxArray[i].nextScreen != "COMENTARIOS"
                ) {
                  this.formsService
                    .getForm({
                      id: this.auxArray[i].questionary_id,
                    })
                    .subscribe((response) => {
                      let selected_form = response.data;
                      if (selected_form) {
                        this.auxArray[i].title =
                          selected_form.questionnaire_name;
                      }
                    });
                }
                if (this.auxArray[i].nextScreen === "MAPA") {
                  this.auxArray[i].url_icon = this.url_icon_mapa;
                } else if (this.auxArray[i].nextScreen === "FOTOS") {
                  this.auxArray[i].url_icon = this.url_icon_fotos;
                } else if (this.auxArray[i].nextScreen === "FIRMA") {
                  this.auxArray[i].url_icon = this.url_icon_firma;
                } else if (this.auxArray[i].nextScreen === "COMENTARIOS") {
                  this.auxArray[i].url_icon = this.url_icon_comentarios;
                } else if (
                  this.auxArray[i].nextScreen === "FOTOS DE REFERENCIA"
                ) {
                  this.auxArray[i].url_icon = this.url_icon_fotos_referencia;
                } else if (this.auxArray[i].nextScreen === "PREGUNTAS") {
                  this.auxArray[i].url_icon = this.url_icon_preguntas;
                }
              }

              this.confirmed_array_elements = this.auxArray;
            });

          this.eventsService
            .getEvent({ uid: this.uid })
            .subscribe((response) => {
              console.log("response LOG " + JSON.stringify(response.data));
              let selected_event = response.data;
              console.log("SELECT EVENT LOG " + JSON.stringify(selected_event));
              if (selected_event) {
                if (selected_event.createOrders == false) {
                  this.create_orders =
                    "Se permitirá buscar actividades desde la aplicación móvil.";
                }
                this.event.patchValue({
                  title: selected_event.name,
                  order: selected_event.order,
                  active: selected_event.active,
                  uid: selected_event.uid,
                  createOrders: selected_event.createOrders,
                  createElements: selected_event.createElements,
                  selectCustomer: selected_event.selectCustomer,
                  selectEquipment: selected_event.selectEquipment,
                  active_category: selected_event.active_category,
                  active_out_range: selected_event.active_out_range,
                  active_autorize_events: selected_event.active_autorize_events,
                  active_transfer: selected_event.active_transfer
                    ? selected_event.active_transfer
                    : false,
                  reject_assignment: selected_event.reject_assignment
                    ? selected_event.reject_assignment
                    : false,
                  active_carret: selected_event.active_carret
                    ? selected_event.active_carret
                    : false,
                  date_created: selected_event.date_created,
                });

                console.log(response);

                if (selected_event.users_autorized != undefined) {
                  this.formuserconfig.all = selected_event.users_autorized.all;
                  if (this.formuserconfig.all) {
                    this.formuserconfig.userselected = this.user_list.map(
                      (u) => {
                        return u.uid;
                      }
                    );
                  } else {
                    this.formuserconfig.userselected =
                      selected_event.users_autorized.user_uid;
                  }

                  console.log(
                    "selected_event.users_autorized 1",
                    this.formuserconfig,
                    this.user_list
                  );
                }

                if (response.modules_notify) {
                  console.log(response.modules_notify);
                  this.emails = [];
                  this.celphones = [];
                  response.modules_notify.data.emails.forEach((element) => {
                    this.emails.push({ name: element });
                  });

                  //this.celphones  = response.modules_notify.data.phones;
                  response.modules_notify.data.phones.forEach((element) => {
                    this.celphones.push({ name: element });
                  });

                  this.formularioNotify.checkinit =
                    response.modules_notify.data.options[0].status;
                  this.formularioNotify.checkclose =
                    response.modules_notify.data.options[1].status;
                  this.formularioNotify.checkscheduled =
                    response.modules_notify.data.options[2].status;
                  this.formularioNotify.checkscheduledcalendar =
                    response.modules_notify.data.options.length > 3
                      ? response.modules_notify.data.options[3].status
                      : false;
                  this.firebase_uid_notify =
                    response.modules_notify.firebase_uid;
                }

                if (response.modules_push) {
                  console.log(response.modules_push);
                  this.formularioPush.userselected = [];
                  this.formularioPush.userselected =
                    response.modules_push.data.push_notifications;

                  this.formularioPush.checkinit =
                    response.modules_push.data.options[0].status;
                  this.formularioPush.checkclose =
                    response.modules_push.data.options[1].status;
                  this.formularioPush.checkscheduled =
                    response.modules_push.data.options[2].status;

                  this.firebase_uid_push = response.modules_push.firebase_uid;
                }

                if (response.modules_autorize) {
                  //this.usersAutorizeUids = response.modules_autorize.data.users_autorize;
                  response.modules_autorize.data.users_autorize.forEach(
                    (userUid) => {
                      const resultado = this.getUserList.find(
                        (user) => user.uid === userUid
                      );
                      if (resultado != undefined) {
                        this.usersAutorize.push(resultado.displayName);
                        this.usersAutorizeUids.push(resultado.uid);
                      }
                    }
                  );
                  this.firebase_uid_autorize =
                    response.modules_autorize.firebase_uid;
                }

                this.autorize = selected_event.active_autorize_events;

                this.spinner.hide();
              } else {
                this.cancelSave();
                this.spinner.hide();
              }
            });
        } else {
          this.spinner.hide();
        }
      });

      this.userService.customerDB = this.authService.customerDB;
      this.userService.customerSelected = this.authService.customerSelected;
      this.userService.getUsersList().subscribe((response) => {
        console.log("users", response);
        console.log("selected_event.users_autorized 2", this.formuserconfig);
        this.user_list = response.data.map((r) => {
          return { ...r, ...{ selected: false } };
        });
        this.quantityUsers = this.user_list.length;

        this.formuserconfig.userselected = this.user_list.map((u) => {
          return u.uid;
        });
        console.log(
          "this.formuserconfig.userselected ",
          this.formuserconfig.userselected,
          this.quantityUsers
        );

        this.listUsersNotifications = this.getUserList;
        console.log('this.getUserList',this.getUserList);
        this.listUSersConfig = this.getUserList.filter((item) => item.email != 'admin@trackpoint.mx' && item.disabled == false);
      });
    });
  }

  onKeySearchListNotifications(value) {
    this.listUsersNotifications = this.searchListNotifications(value);
  }

  onKeySearchListConfig(value) {
    this.listUSersConfig = this.searchListNotifications(value);
  }

  searchListConfig(value: string) {
    let filter = value.toLowerCase();
    const filteredFirst = this.getUserList.filter((option) =>
      option.displayName.toLowerCase().startsWith(filter)
    );

    let newList = [];

    filteredFirst.forEach((element) => {
      newList.push(element);
    });

    this.getUserList.forEach((element) => {
      if (!newList.includes(element)) {
        newList.push(element);
      }
    });

    return newList;
  }

  searchListNotifications(value: string) {
    let filter = value.toLowerCase();
    const filteredFirst = this.getUserList.filter((option) =>
      option.displayName.toLowerCase().startsWith(filter)
    );

    let newList = [];

    filteredFirst.forEach((element) => {
      newList.push(element);
    });

    this.getUserList.forEach((element) => {
      if (!newList.includes(element)) {
        newList.push(element);
      }
    });

    return newList;
  }

  clone(Obj) {
    let buf; // the cloned object
    if (Obj instanceof Array) {
      buf = []; // create an empty array
      var i = Obj.length;
      while (i--) {
        buf[i] = this.clone(Obj[i]); // recursively clone the elements
      }
      return buf;
    } else if (Obj instanceof Object) {
      buf = {}; // create an empty object
      for (const k in Obj) {
        if (Obj.hasOwnProperty(k)) {
          // filter out another array's index
          buf[k] = this.clone(Obj[k]); // recursively clone the value
        }
      }
      return buf;
    } else {
      return Obj;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  hideErrorTittle() {
    this.errorTitleEmpty = false;
  }

  cancelSave() {
    const ruta = "/" + this.storageService.getCurrentDB() + "/events-list";
    this.router.navigate([ruta]);
  }

  private modules_notify_structure(type) {
    let emails = [];
    this.emails.forEach((e) => {
      emails.push(e.name);
    });

    let options = [
      {
        status: this.formularioNotify.checkinit,
        type_id: 1,
      },
      {
        status: this.formularioNotify.checkclose,
        type_id: 2,
      },
      {
        status: this.formularioNotify.checkscheduled,
        type_id: 3,
      },
      {
        status: this.formularioNotify.checkscheduledcalendar,
        type_id: 4,
      },
    ];

    let phones = [];
    this.celphones.forEach((e) => {
      phones.push(e.name);
    });

    let modules_notify = {
      data: {
        emails: emails,
        options: options,
        phones: phones,
      },
      filters_uid: this.uid ? this.uid : "",
      firebase_uid: this.firebase_uid_notify,
      status: true,
      type_id: type,
    };
    return modules_notify;
  }
  private modules_push_structure(type) {
    let emails = [];
    let options = [
      {
        status: this.formularioPush.checkinit,
        type_id: 1,
      },
      {
        status: this.formularioPush.checkclose,
        type_id: 2,
      },
      {
        status: this.formularioPush.checkscheduled,
        type_id: 3,
      },
    ];
    let phones = [];
    let modules_notify = {
      data: {
        emails: emails,
        options: options,
        phones: phones,
        push_notifications: this.formularioPush.userselected,
      },
      filters_uid: this.uid ? this.uid : "",
      firebase_uid: this.firebase_uid_push,
      status: true,
      type_id: type,
    };
    return modules_notify;
  }

  private modules_users_autorize(type) {
    let modules_notify = {
      data: {
        users_autorize: this.usersAutorizeUids,
      },
      filters_uid: this.uid ? this.uid : "",
      firebase_uid: this.firebase_uid_autorize,
      status: true,
      type_id: type,
    };
    return modules_notify;
  }

  ngAfterViewInit() {}

  async saveForm() {
    if (this.event.get("title").value == "") {
      return (this.errorTitleEmpty = true);
    }

    if (this.event.get("active_autorize_events").value == true) {
      console.log("SI ENTRO A VAKIDAR ");
      if (this.usersAutorize.length == 0) {
        this.errorAuthorizersEmpty = true;
        return null;
      }
    }

    let data: any = this.data;

    if (this.uid) {
      data.uid = this.uid;
    } else {
      data.date_created = this.today;
    }

    if (this.quantityUsers != this.formuserconfig.userselected.length) {
      data.users_autorized.all = false;
      data.users_autorized.user_uid = this.formuserconfig.userselected;
    }

    for (let config in this.confirmed_array_elements) {
      if (
        this.confirmed_array_elements[config]["nextScreen"] ==
        "FOTOS DE REFERENCIA"
      ) {
        for (let photo in this.confirmed_array_elements[config][
          "photos_reference"
        ]) {
          if (
            this.confirmed_array_elements[config]["photos_reference"][photo][
              "photo_demo"
            ].indexOf("firebasestorage") == -1
          ) {
            console.log(this.confirmed_array_elements[config]);
            const downloadUrl = await this.userService.uploadPhotoReference(
              Moment().format("DD-MM-YYYY_hh:mm:ss") +
                this.confirmed_array_elements[config]["photos_reference"][
                  photo
                ]["photo_number"],
              this.confirmed_array_elements[config]["photos_reference"][photo][
                "photo_demo"
              ]
            );
            console.log("URL IMAGEN DE PHOTO REFERENCE ", downloadUrl);
            this.confirmed_array_elements[config]["photos_reference"][photo][
              "photo_demo"
            ] = downloadUrl;
          }
        }
      }
    }
    data.configuration = this.confirmed_array_elements;

    var array_forms = [];
    for (let item of this.confirmed_array_elements) {
      if (item.questionary_id != "") {
        array_forms.push(item.questionary_id);
      }
    }
    data.forms = array_forms;
    if (!data.createElements) {
      data.createElements = false;
    }
    let modules_notify = this.modules_notify_structure(2);
    let modules_push = this.modules_push_structure(2);
    let modules_autorize = this.modules_users_autorize(2);
    console.log("saveForm", data);
    this.spinner.show();
    this.eventsService.saveEvent({ ...data }).subscribe((response) => {
      this.spinner.hide();
      if (response.status == "ok") {
        this.eventsService
          .saveEventConfiguration(data, data.uid || response.last_id)
          .subscribe(() => {
            modules_notify.filters_uid = data.uid || response.last_id;
            modules_push.filters_uid = data.uid || response.last_id;
            this.eventsService
              .saveAddUpdateNotify(modules_notify)
              .subscribe((response) => {});
            this.eventsService
              .saveAddUpdateNotify(modules_push)
              .subscribe((response) => {});
            this.eventsService
              .saveAddUpdateNotify(modules_autorize)
              .subscribe((response) => {});

            this.modelGeneric
              .onModelexito(
                false, //disableClose
                this.uid
                  ? this.translateService.instant(
                      "PAGES.EVENTS_ADD.SUCCESS.UPDATED"
                    )
                  : this.translateService.instant(
                      "PAGES.EVENTS_ADD.SUCCESS.SAVED"
                    ), //message
                false, // cancelButton
                this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
                true, // info
                false //imagen info
              )
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                const ruta =
                  "/" + this.storageService.getCurrentDB() + "/events-list";
                this.router.navigate([ruta]);
              });
          });
      } else if (response.status == "fail") {
        this.modelGeneric.onModelerror(
          false, //disableClose
          this.translateService.instant(
            "PAGES.EVENTS_ADD.ERRORS." +
              (response.message.code || "").toUpperCase()
          ), //message
          false, // cancelButton
          this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
          true, // info
          false //imagen info
        );
      }
    });
  }

  cleanForm() {
    while (this.forms.length) {
      this.forms.removeAt(0);
    }

    this.event.patchValue({
      title: "",
      order: "",
      active: true,
      createOrders: false,
      createElements: false,
      selectCustomer: false,
      selectEquipment: false,
      active_category: false,
      active_out_range: false,
      active_autorize_events: false,
      active_transfer: false,
      reject_assignment: false,
      active_carret: false,
    });
  }

  cancelForm() {
    // const ruta = '/' + this.storageService.getCurrentDB() + '/events-list';
    // this.router.navigate([ruta]);
    this.location.back();
  }

  validForm() {
    return this.event.valid && this.confirmed_array_elements.length > 0;
  }

  changeCreateOrder($event) {
    this.event.get("createOrders").setValue($event);
  }

  changeCreateElements($event) {
    this.event.get("createElements").setValue($event);
  }

  toggleSelection(control: FormControl) {
    if (!control.disabled) {
      control.setValue(control.value === true ? false : true);
    }
  }

  drop_(event: CdkDragDrop<string[]>) {
    var drog_move = event.previousContainer.data[event.previousIndex];
    if (event.previousContainer === event.container) {
      console.log("ENTER IF");
      console.log(drog_move);
      if (drog_move["confirmed"]) {
        if (drog_move["id"] != 1)
          moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
      }
    } else {
      console.log("ENTER ELSE");

      if (!drog_move["confirmed"]) {
        var param_name_send: string = drog_move["title"];
        param_name_send = param_name_send.toLowerCase();
        if (param_name_send == "imágenes de referencia") {
          param_name_send = "fotos de referencia";
        }
        const dialogRef = this.dialog.open(AddItemDragComponent, {
          height: "auto",
          width: "40%",
          data: {
            param_name_send,
          },
        });

        dialogRef.afterClosed().subscribe((data) => {
          var param_name = dialogRef.componentInstance.paramName_;
          console.log("drog_move " + drog_move["title"]);
          var url_icon_ = this.url_icon_mapa;

          if (drog_move["title"] == "Comentarios") {
            url_icon_ = this.url_icon_comentarios;
          } else if (drog_move["title"] == "Firma de recibido") {
            url_icon_ = this.url_icon_firma;
          } else if (drog_move["title"] == "Foto evidencia") {
            url_icon_ = this.url_icon_fotos;
          } else if (drog_move["title"] == "Imágenes de Referencia") {
            url_icon_ = this.url_icon_fotos;
          }

          if (param_name != undefined) {
            switch (drog_move["nextScreen"]) {
              case "COMENTARIOS":
                this.confirmed_array_elements.push({
                  id: this.id_count_form_control++,
                  nextScreen: drog_move["nextScreen"],
                  // title: this.name_question,
                  title: param_name,
                  url_icon: url_icon_,
                  confirmed: true,
                  questionary_id: this.id_question,
                  required: false,
                  photos_reference: null,
                  additional: null,
                  carret: null,
                });
                break;
              case "FOTOS DE REFERENCIA":
                this.confirmed_array_elements.push({
                  id: this.id_count_form_control++,
                  nextScreen: drog_move["nextScreen"],
                  // title: this.name_question,
                  title: param_name,
                  url_icon: url_icon_,
                  confirmed: true,
                  questionary_id: "",
                  required: false,
                  photos_reference: dialogRef.componentInstance.photos_,
                  additional: null,
                  carret: null,
                });
                break;
              default:
                this.confirmed_array_elements.push({
                  id: this.id_count_form_control++,
                  nextScreen: drog_move["nextScreen"],
                  title: param_name,
                  url_icon: url_icon_,
                  confirmed: true,
                  questionary_id: "",
                  required: false,
                  photos_reference: null,
                  additional: null,
                  carret: null,
                });
            }
            console.log(this.confirmed_array_elements);
          }
        });
      }
    }
  }

  goAddItem() {
    console.log("GO ADD ITEM");
    const dialogRef = this.dialog.open(FormularioListComponent, {
      height: "auto",
      width: "80%",
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log("DATA RESPONSE " + data);

      if (data != undefined) {
        var form_list = dialogRef.componentInstance.forms_list;
        var enter = false;
        var mensaje =
          "La o las tareas personalizadas han sido agregadas exitosamente.";
        let mensaje_sub = "";
        let mensaje_sub_foo =
          'Por lo que se recomienda activar la opción de "Tarea Adicional" para que estás tareas puedan ser agregadas las veces que se necesiten desde la aplicación. <br><br> Las demás tareas fueron agregadas exitosamente.';
        let not_add = [];

        if (data == "3") {
          mensaje = "La tarea personalizada ha sido registrada exitosamente.";
        }

        console.log("FONRM LIST ADD ", form_list);

        for (let item of form_list) {
          if (item.check) {
            enter = true;
            // this.array_add_questionaries.push(item);

            let find_list = this.confirmed_array_elements.find(
              (x) => x.questionary_id === item.uid
            );
            if (find_list) {
              mensaje_sub =
                "Esta actividad ya tiene asignadas las siguientes tareas:";
              not_add.push(item);
            } else {
              this.confirmed_array_elements.push({
                id: this.id_count_form_control++,
                nextScreen: "PREGUNTAS",
                title: item.displayName,
                questionary_id: item.uid,
                url_icon: this.url_icon_preguntas,
                confirmed: true,
                required: false,
                photos_reference: null,
                additional: false,
                carret: false,
              });
            }
          }
        }

        if (enter) {
          const dialogRef = this.modelGeneric.onModelexito(
            true,
            mensaje,
            false,
            "Aceptar",
            true,
            false,
            mensaje_sub,
            not_add,
            mensaje_sub_foo
          );
          dialogRef.afterClosed().subscribe((response) => {
            if (response == "aceptar") {
            }
          });
        }
      }
    });
  }

  editTitleItem(item) {
    console.log("EDIT TILE");
    console.log(item);

    var name_item = item.title;
    var photos_item = item.photos_reference;
    var param_name_send: string = item["nextScreen"];

    if (item["nextScreen"] == "MAPA") {
      param_name_send = "mapa";
    } else if (item["nextScreen"] == "FIRMA") {
      param_name_send = "firma de recibido";
    } else if (item["nextScreen"] == "PREGUNTAS") {
      param_name_send = "preguntas";
    } else if (item["nextScreen"] == "FOTOS") {
      param_name_send = "foto evidencia";
    } else if (item["nextScreen"] == "FOTOS DE REFERENCIA") {
      param_name_send = "fotos de referencia";
    }

    if (item.nextScreen == "PREGUNTAS") {
      const dialogRef = this.dialog.open(FormularioAddComponent, {
        height: "80%",
        width: "80%",
        data: {
          id: item["questionary_id"],
          event_id: this.uid,
          confirmed_array_elements: this.confirmed_array_elements,
        },
      });

      dialogRef.afterClosed().subscribe((data) => {
        console.log(data);

        this.formsService
          .getForm({
            id: item["questionary_id"],
          })
          .subscribe((response) => {
            let selected_form = response.data;
            if (selected_form) {
              for (let i = 0; i < this.confirmed_array_elements.length; i++) {
                if (
                  this.confirmed_array_elements[i].questionary_id ==
                  item["questionary_id"]
                ) {
                  this.confirmed_array_elements[i].title =
                    selected_form.questionnaire_name;
                }
              }
            }
          });
      });
    } else {
      param_name_send = param_name_send.toLowerCase();
      const dialogRef = this.dialog.open(AddItemDragComponent, {
        height: "auto",
        width: "40%",
        data: {
          name_item,
          param_name_send,
          photos_item,
        },
      });

      dialogRef.afterClosed().subscribe((data) => {
        console.log("Dialog GG");
        console.log(data);
        var param_name = dialogRef.componentInstance.paramName_;
        console.log("param_name " + param_name);

        item.title = param_name;

        console.log(
          "CONFIRMER " + JSON.stringify(this.confirmed_array_elements)
        );
      });
    }

    console.log("Open GG");
  }

  deletedItem(item) {
    const dialogRef = this.modelGeneric.onModelconfirmacion(
      true,
      "¿Estás seguro de desvincular este tarea?",
      true
    );

    dialogRef.afterClosed().subscribe((data) => {
      if (data == "aceptar") {
        let index = this.confirmed_array_elements
          .map((item) => item.id)
          .indexOf(item.id);
        if (index > -1) {
          this.confirmed_array_elements.splice(index, 1);
          console.log("Result", this.confirmed_array_elements);
        }
        dialogRef.close();
      } else {
        dialogRef.close();
      }
    });
  }

  toggle(event: MatSlideToggleChange) {
    console.log("toggle", event.checked);
    if (event.checked) {
      this.create_orders =
        "Se permitirá crear actividades desde la aplicación móvil.";
    } else {
      this.create_orders =
        "Se permitirá buscar actividades desde la aplicación móvil.";
    }
  }

  toggle_category(event: MatSlideToggleChange) {
    console.log("toggle_category", event.checked);
    if (event.checked === false) return false;
    this.clasifications
      .getDataClassification()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((response: any) => {
        this.spinner.hide();
        console.log(response);
        if (response && response["status"] && response["status"] == "ok") {
          if (response.data) {
            if (response.data.length === 0) {
              this.event.controls.active_category.setValue(false);
              const dialogRef = this.modelGeneric.onModelconfirmacion(
                true,
                "Asegúrate de tener configuradas categorías",
                true,
                "Ir a configuración de categorías",
                true,
                false,
                "Advertencia"
              );

              dialogRef.afterClosed().subscribe((data) => {
                console.log("data>>>>", data);
                if (data == "aceptar") {
                  if (this.storageService.getCurrentDB() != "") {
                    const ruta =
                      "/" +
                      this.storageService.getCurrentDB() +
                      "/classification-list";
                    this.router.navigate([ruta]);
                  }
                  dialogRef.close();
                } else {
                  this.event.controls.active_category.setValue(false);
                  dialogRef.close();
                }
              });

              return false;
            }
          } else {
            this.event.controls.active_category.setValue(false);
            const dialogRef = this.modelGeneric.onModelconfirmacion(
              true,
              "Asegúrate de tener configuradas categorías",
              true,
              "Ir a configuración de categorías",
              true,
              false,
              "Advertencia"
            );

            dialogRef.afterClosed().subscribe((data) => {
              console.log("data>>>>", data);
              if (data == "aceptar") {
                if (this.storageService.getCurrentDB() != "") {
                  const ruta =
                    "/" +
                    this.storageService.getCurrentDB() +
                    "/classification-list";
                  this.router.navigate([ruta]);
                }
                dialogRef.close();
              } else {
                this.event.controls.active_category.setValue(false);
                dialogRef.close();
              }
            });

            return false;
          }
          let categories = response.data[0].categories;
          console.log(response.data[0].categories);
          let find = categories.find((x) => x.deleted === false);
          console.log(find);
          if (!find) {
            const dialogRef = this.modelGeneric.onModelconfirmacion(
              true,
              "Asegúrate de tener configuradas categorías",
              true,
              "Ir a configuración de categorías",
              true,
              false,
              "Advertencia"
            );

            dialogRef.afterClosed().subscribe((data) => {
              console.log("data>>>>", data);
              if (data == "aceptar") {
                if (this.storageService.getCurrentDB() != "") {
                  const ruta =
                    "/" +
                    this.storageService.getCurrentDB() +
                    "/classification-list";
                  this.router.navigate([ruta]);
                }
                dialogRef.close();
              } else {
                this.event.controls.active_category.setValue(false);
                dialogRef.close();
              }
            });
          }
        }
      });
  }

  toggle_autorize_events(event: MatSlideToggleChange) {
    this.autorize = event.checked;
    if (!event.checked) {
      this.usersAutorize = [];
      this.usersAutorizeUids = [];
      this.userCtrl.setValue(null);
    }
  }

  toggle_reject_assignment(event: MatSlideToggleChange) {
    this.autorize = event.checked;
  }

  onChangueToggle(item) {
    if (item.required) {
      item.required = false;
    } else {
      item.required = true;
    }

    console.log("item");
    console.log(item);

    console.log("CONFIRMED ARRAY ");
    console.log(this.confirmed_array_elements);
  }

  onChangueToggleAdditional(item) {
    if (item.additional) {
      item.additional = false;
    } else {
      item.additional = true;
      // this.event.get('createElements').setValue(true);
    }

    console.log("item");
    console.log(item);

    console.log("CONFIRMED ARRAY ");
    console.log(this.confirmed_array_elements);
  }

  onChangueToggleCarret(item) {
    if (item.carret) {
      item.carret = false;
    } else {
      item.carret = true;
      this.event.get("createElements").setValue(true);
    }

    console.log("item");
    console.log(item);

    console.log("CONFIRMED ARRAY ");
    console.log(this.confirmed_array_elements);
  }

  setCountId(rows) {
    rows = rows.sort((n1, n2) => n1.id - n2.id);
    this.id_count_form_control = rows[rows.length - 1].id + 1;
  }

  checkedPushstart() {
    this.formularioPush.checkinit = !this.formularioPush.checkinit;
  }
  checkedPushclose() {
    this.formularioPush.checkclose = !this.formularioPush.checkclose;
  }
  checkedPushscheduled() {
    this.formularioPush.checkscheduled = !this.formularioPush.checkscheduled;
  }

  checkedstart() {
    this.formularioNotify.checkinit = !this.formularioNotify.checkinit;
  }

  checkedclose() {
    this.formularioNotify.checkclose = !this.formularioNotify.checkclose;
  }

  checkedscheduled() {
    this.formularioNotify.checkscheduled =
      !this.formularioNotify.checkscheduled;
  }

  checkedscheduledcalendar() {
    this.formularioNotify.checkscheduledcalendar =
      !this.formularioNotify.checkscheduledcalendar;
  }

  removeEmails(f: any): void {
    const index = this.emails.indexOf(f);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  addEmails(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    var EMAIL_REGEX =
      /^[a-zA-Z0-9.!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.correo_invalido = "";
    const found = this.emails.find((element) => element.name === value.trim());

    if (value.trim() === "") {
      return;
    }

    if (found) {
      if (input) {
        input.value = "";
      }
      this.correo_invalido =
        "Por favor, ingresar un correo diferente este ya se encuentra registrado.";
      return;
    }

    if (!value.trim().match(EMAIL_REGEX)) {
      this.correo_invalido = "Por favor, ingresar un correo valido.";
      return;
    }

    // Add our fruit
    if ((value || "").trim()) {
      this.emails.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  removePhone(f: any): void {
    const index = this.celphones.indexOf(f);

    if (index >= 0) {
      this.celphones.splice(index, 1);
    }
  }

  addPhone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    var phone_REGEX = /^((\\+91-?)|0)?[0-9]{10}$/;
    this.cel_invalido = "";
    const found = this.celphones.find(
      (element) => element.name === value.trim()
    );

    if (value.trim() === "") {
      return;
    }

    if (found) {
      if (input) {
        input.value = "";
      }
      this.cel_invalido =
        "Por favor, ingresar un teléfono diferente este ya se encuentra registrado.";
      return;
    }
    if (!value.trim().match(phone_REGEX)) {
      this.cel_invalido = "Por favor, ingresar un teléfono valido.";
      return;
    }

    // Add our fruit
    if ((value || "").trim()) {
      this.celphones.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  validarselectvalue() {
    let value = "";
    if (this.formularioNotify.selecttype == "days") {
      if (parseInt(this.formularioNotify.inputvalue) > 24) {
        this.formularioNotify.inputvalue = "24";
      }
    } else {
      if (parseInt(this.formularioNotify.inputvalue) > 7) {
        this.formularioNotify.inputvalue = "7";
      }
    }
  }

  validarinputvalue() {
    let input = this.formularioNotify.inputvalue;
    this.formularioNotify.inputvalue = input.replace(/\D/g, "");
    console.log();
    if (parseInt(this.formularioNotify.inputvalue) < 0) {
      this.formularioNotify.inputvalue = "";
    }
    if (parseInt(this.formularioNotify.inputvalue) > this.max) {
      if (this.formularioNotify.selecttype == "days") {
        this.formularioNotify.inputvalue = "24";
      } else {
        this.formularioNotify.inputvalue = "7";
      }
    }
    if (this.formularioNotify.selecttype == "days") {
      this.max = 24;
    } else {
      this.max = 7;
    }
  }
}
