import { programmingObj } from '@app/models/programming';
import { Component, OnInit, Input, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { ActivatedRoute, Router } from '@angular/router';
import { ItemEvent, Photos, questions } from '../models/evidence';
import * as firebase from 'firebase';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map'
import { UserService } from '../core/user.service';
import { ordenesObj } from '../models/order';
import { AuthService } from '../core/auth.service';
import { StorageService } from '../core/storage.service';
import * as Moment from 'moment';

import * as jspdf from 'jspdf';
declare let html2canvas: any;

import * as pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { ShareDialogComponent } from './components/share-dialog/share-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramingService } from '../services/programing.service';


interface itemsConfigObj{
  name: string;
  rows: itemsConfigDetails[];
}

interface itemsConfigDetails {
  id?: number;
  title: string;
  nextScreen: string;
  url_icon: string;
  questionary_id?: number;
  isExtraItem?: boolean;
  isDeleted?: boolean;
  order_item?: number;
}

interface itemsExtra {
  doc?: string;
  items_extra: itemsConfigDetails[];
}

interface ordenes {
  id: string;
  order_number: string;
  customer_name: string;
  order_name: string;  
  address: string;
  users_all: boolean;
  start_date: string;
  end_date: string;
  latitude: string;
  longitude: string;
  users: any[];
  status: string; //1 -> pendiente, 2 -> proceso, 3 -> completado 
  user_uid: string;
  user_name: string;
  geolocation: any;
  address_checkIn?: string;
  evidence: ItemEvent[];
  photo_evidence: Photos[];
  scheduled?: boolean;
}

export interface PhotosTmp {
  item_evidence_id: string;
  photo_name: string;
  thumb_name: string;
  url_photo: string;
  url_thumb: string;
  caption?: string;
}

export interface SignaturesTmp {
  item_evidence_id: string;
  signature_name: string;
  signed_customer_name?: string;
  url: string;
}

interface SignaturesObj {
  id?: string;
  title: string;
  itemId_assigned?: number;
  firmas: SignaturesTmp[];
}

interface MultiPhotosObj {
  id: string;
  title: string;
  descripcion: string;
  itemId_assigned?: Number;
  evidencia: Photos[];
}

interface PreguntasObj{
  id?: number;
  title: string;
  itemId_assigned?: number;
  questions: questions[];
}




@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.scss']
})
export class DetailOrderComponent implements OnInit, AfterViewInit {

  sub;
  nombre = '';
  counts_items = 0;

  order: ordenes = {
    id: ' ',
    order_number: ' ',
    customer_name: ' ',
    order_name: ' ',  
    address: ' ',
    users_all: false,
    scheduled: false,
    start_date: ' ',
    end_date: ' ',
    latitude: ' ',
    longitude: ' ',
    users: [],
    status: ' ', //1 -> pendiente, 2 -> proceso, 3 -> completado 
    user_uid: ' ',
    user_name: ' ',
    geolocation: null,
    address_checkIn: ' ',
    evidence: [],
    photo_evidence: []
  };

  array_evidence: MultiPhotosObj[] = [];
  //array_evidence: Observable<any[]>;
  items_rows_temp_3: itemsConfigDetails[] = [];

  private usersSubject = new BehaviorSubject([]);
  private users: MultiPhotosObj[];
  private users$: Observable<any[]>;

  list_photos: PhotosTmp[] = [];
  list_signatures: SignaturesTmp[] = [];
  array_questionaries: PreguntasObj[] = [];
  array_comments: any[] = [];
  url_map = '';
  url_link_map = '';
  url_link = '';
  url_download = '';
  url_logo_client = '';

  //public items_config: itemsConfigObj = null;
  public items_config: itemsConfigDetails[] = [];
  loading: any;

  array_signatures: SignaturesObj[] = [];
  private programmingList: programmingObj[];
  constructor(
    private route: ActivatedRoute, 
    private sanitized: DomSanitizer, 
    public userService: UserService, 
    public authService: AuthService, 
    public storageService: StorageService, 
    public dialog: MatDialog,
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    private programingservice: ProgramingService 
  ) {
    this.spinner.show();
  }

  ngOnInit() {
    const customer$ = this.authService.customerSelected$.subscribe(customer => {
      if (customer) {
        customer$.unsubscribe()
        this.sub = this.route.queryParams.subscribe(params => {
          if (this.authService.customer_route != '') {
            this.userService.customerDB = this.authService.customerDB;
            this.userService.customerSelected = this.authService.customerSelected;
            var order_uuid = params['order_uui'];
            this.url_link = `${location.protocol}//${location.host}/` + this.authService.customer_route + '/detail-order?order_uui=' + order_uuid;
            this.url_download = `${location.protocol}//${location.host}/` + this.authService.customer_route + '/report-order/pdf?order_id=' + order_uuid;
            let Parameters = null;
            Parameters = {
              type: 6,
              id: order_uuid
            };
            this.programingservice.getScheduledEventsFilters(Parameters).subscribe(
              (response) => {
                console.log('response', response);
                this.programmingList =  response.data.map((r) => {
                  if(r.length == 0){
                    this.searchInfoOrder(order_uuid);
                  }
                  return { ...r, ...{ selected: false } };
                });
                this.spinner.hide();
                console.log('this.programmingList', this.programmingList);
                if(this.programmingList.length == 0){
                  
                }
              },
              (error) => {
                console.log('error', error);
                this.spinner.hide();
              }
            );
           
            this.url_logo_client = this.authService.customerSelected.url_logo;
          }
        });
      }
    })
  }

  searchInfoOrder(order_uuid: string){
    this.userService.getInfoOrder(order_uuid).then((order_data: ordenesObj) => {
        if(order_data != null){
          
          this.order.id              = order_data.firebase_id;
          this.order.order_number    = order_data.order_number;
          this.order.customer_name   = order_data.customer_name;
          this.order.order_name      = order_data.order_name;
          this.order.address         = order_data.address;
          this.order.users_all       = order_data.users_all;
          this.order.start_date      = order_data.start_date;
          this.order.end_date        = order_data.end_date;
          this.order.latitude        = order_data.latitude;
          this.order.longitude       = order_data.longitude;
          this.order.users           = order_data.users;
          this.order.status          = order_data.status;
          this.order.user_uid        = order_data.user_uid;
          this.order.user_name       = order_data.user_name;
          this.order.geolocation     = order_data.geolocation;
          this.order.address_checkIn = order_data.address_checkIn;
          this.order.scheduled  = order_data.scheduled;
          console.log(this.order.scheduled);
          
                        
        this.url_map = "https://maps.googleapis.com/maps/api/staticmap?center=" + this.order.latitude + "," + this.order.longitude + "&zoom=18&size=600x400&maptype=roadmap&markers=color:green|label:Site|" + this.order.latitude + "," + this.order.longitude + "&key=AIzaSyDw4PN72JS0z_pPNNofyHvMdlIDEveb63Q";
        this.url_link_map = `https://www.google.com/maps/search/?api=1&query=${this.order.latitude},${this.order.longitude}`;

        this.loadEvidenOrder();

        }
        else{
          console.log('No se encontro la orden.');
        }
    });
  }

  loadEvidenOrder(){
    this.userService.getAllEvidenceByOrder(this.order.id).then((evidencia: ItemEvent[]) => {
      if(evidencia.length > 0){
        this.order.evidence = evidencia;

        var items_rows_temp_1: itemsConfigDetails[] = [];
        var items_rows_temp_2: itemsConfigDetails[] = [];
        this.userService.getItemsConfiguration().then(() => {
          this.userService.getExtraItemsConfig(this.order.id).then(async (extra_items:itemsExtra) => {

            for(let obj1 of this.userService.items_config.rows){
              items_rows_temp_1.push({id: obj1.id, title: obj1.title, nextScreen: obj1.nextScreen, url_icon: obj1.url_icon,
              questionary_id: obj1.questionary_id, isExtraItem: false, isDeleted: false});
            }

            for(let obj2 of extra_items.items_extra){
              if(!obj2.isDeleted){
                this.items_rows_temp_3.push({id: obj2.id, title: obj2.title, nextScreen: obj2.nextScreen, url_icon: obj2.url_icon,
                  questionary_id: obj2.questionary_id, isExtraItem: obj2.isExtraItem, isDeleted: obj2.isDeleted, order_item: obj2.order_item});

                items_rows_temp_2.push({id: obj2.id, title: obj2.title, nextScreen: obj2.nextScreen, url_icon: obj2.url_icon,
                  questionary_id: obj2.questionary_id, isExtraItem: obj2.isExtraItem, isDeleted: obj2.isDeleted, order_item: obj2.order_item});
              }
            }

            
             /////////Descartar los items default, si se encuentran en los extra///////////////
            for(let item_df of items_rows_temp_1){
              var obj = items_rows_temp_2.find(item => item.id === item_df.id);
              if(obj == null){
                items_rows_temp_2.push(item_df);
              }
            }

            this.items_config = items_rows_temp_2;
            //////////////////////////////////////////////////////////////////////////////////
            this.items_config = this.items_config.sort((n1,n2) => n1.id - n2.id);

             //this.items_config = this.userService.items_config;

              await this.getAllUrlPhotosByOrder().then((res) => {
                if(res){
                  for(let item of this.order.evidence){
                    if(item.photos.length > 0){

                      if(item.itemId_assigned != undefined){
                        if(item.itemId_assigned == 0){

                            var photos: Photos[] = [];
                            for(let obj of item.photos){
                              console.log('----- photos ' + JSON.stringify(obj));
                              console.log('----- photos url_photo ' + obj.url_photo);
                                photos.push({photo_name: obj.photo_name, thumb_name: obj.thumb_name, url_photo: obj.url_photo, url_thumb: obj.url_thumb, caption: obj.caption,  photo_url: obj.photo_url,});
                            }
            
                            this.array_evidence.push({id: item.id, title: 'Foto Evidencia', descripcion: item.description, evidencia: photos});  
                        }else{
                          
                          for(let obj of this.items_config){
                            if(item.itemId_assigned == obj.id){

                              var photos: Photos[] = [];
                              for(let obj of item.photos){
                                console.log('----- photos ' + JSON.stringify(obj));
                                console.log('----- photos url_photo ' + obj.photo_url);
                                  photos.push({photo_name: obj.photo_name, thumb_name: obj.thumb_name, url_photo: obj.url_photo, url_thumb: obj.url_thumb, caption: obj.caption, photo_url: obj.photo_url,});
                              }
              
                              this.array_evidence.push({id: item.id, title: obj.title, itemId_assigned: item.itemId_assigned, descripcion: item.description, evidencia: photos}); 
                            }
                          }
                        }
                      }else{
                        var photos: Photos[] = [];
                        for(let obj of this.list_photos){
                          if(item.id == obj.item_evidence_id){
                            photos.push({photo_name: obj.photo_name, thumb_name: obj.thumb_name, url_photo: obj.url_photo, url_thumb: obj.url_thumb, caption: obj.caption});
                          }
                        }
        
                        this.array_evidence.push({id: item.id, title: 'Foto Evidencia', descripcion: item.description, evidencia: photos});
                      }

                    }
                  }
      
                  this.setSignatures();
                  this.spinner.hide();
                }
              })
              .catch((err) => {
                this.spinner.hide();
                console.log('Error al obtener las photos.');
              });
              
          })
          .catch((err1) => {
            this.spinner.hide();
            console.log('Err 1 -> ' + err1);
          })

        })
        .catch((error) => {
          this.spinner.hide();
          console.log('Error al obtener los items -> ' + error);
        });
        
      }
      else{
        this.spinner.hide();
        console.log('No se encontro evidencia para la orden.');
      }
    })
    .catch((err) => {
      this.spinner.hide();
      console.log(err);
    });
  }

  setSignatures(){
    this.list_signatures = [];
    this.getAllUrlSignaturesByOrder().then((res) => {
      if(res){
        console.log('Firmas cargadas correctamente.');
        this.array_questionaries = [];

        this.loadAllQuestionariesByOrder().then((result) => {
          console.log('RESULT ', result);
          if(result){
            console.log('Preguntas cargadas correctamente.');
            console.log('Preguntas -> ' + JSON.stringify(this.array_questionaries));

            this.array_comments = [];
            this.loadAllCommentsByOrder().then((result1) => {
              if(result1){
                console.log('Comentarios cargadas correctamente.');
                console.log('Comentarios -> ' + JSON.stringify(this.array_comments));
              }
            })
            .catch((err) => {
              console.log('Error al obtener las preguntas. -> ' + err);
            });
          }
        })
        .catch((err) => {
          console.log('Error al obtener las preguntas. -> ' + err);
        });
        
      }
    })
    .catch(() => {
      console.log('Error al obtener las photos.');
    });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    console.log('destroy');
    this.sub.unsubscribe();
  }

  getUsers(): Observable<MultiPhotosObj[]> {
    return this.usersSubject.asObservable();
  }

  private refresh() {
    this.usersSubject.next(this.users);
  }

  loadUrlPhotos(evidence: ItemEvent){
    if(evidence.photos.length > 0){  
      var contador = 0;
      var photos_result: Photos[] = [];

      for(let photo of evidence.photos){
        firebase.app().storage().ref(this.order.user_uid + '/orders/' + this.order.id + '/evidence/' + evidence.id + '/')
        .child(photo.photo_name).getDownloadURL().then((URL) => {
          contador = contador + 1
          photos_result.push({
              photo_name: photo.photo_name,
              thumb_name: photo.thumb_name,
              url_photo: URL,
              url_thumb: '' 
          });

        })
        .catch((err) => {
          console.log('Error al obtener foto url: ' + err);
        });
      }
     
    }
  }

  getAllUrlPhotosByOrder(){
   return new Promise(async (resolve, reject) =>{

    this.list_photos = [];
    var contador = 0;
    var num_photos = 0;

    for(let item of this.order.evidence){
      if(item.photos.length > 0){
        num_photos += item.photos.length;
      }
    }

    console.log('Total de fotos -> ' + num_photos);

    for(let item of this.order.evidence){
      if(item.photos.length > 0){
        for(let photo of item.photos){
          await this.userService.customerDB.app.storage().ref(this.order.user_uid + '/orders/' + this.order.id + '/evidence/' + item.id + '/')
          .child(photo.photo_name).getDownloadURL().then((URL) => {
            
            contador += 1;
            this.list_photos.push({
                item_evidence_id: item.id,
                photo_name: photo.photo_name,
                thumb_name: photo.thumb_name,
                url_photo: URL,
                url_thumb: '',
                caption: photo.caption
            });

            if(contador == num_photos){
              resolve(true);
            }
          })
          .catch((err) => {
            console.log('Error al obtener foto url: ' + err);
          });
        }
      }else{              
        resolve(true);      
      }
    }

   });
    
  }

  getAllUrlSignaturesByOrder(){
    return new Promise((resolve, reject) =>{
 
     var contador = 0;
     var num_firmas = 0;
 
     for(let item of this.order.evidence){
       if(item.last_signed_name != ''){
         num_firmas += 1;
       }
     }
 
     console.log('Total de firmas -> ' + num_firmas);
     if(num_firmas > 0){

      this.array_signatures = [];
      for(let item of this.order.evidence){
       
        if(item.last_signed_name != ''){
         console.log('Item Asignado -> ' + item.itemId_assigned);
         if(item.itemId_assigned != undefined){
           if(item.itemId_assigned == 0){
             this.userService.customerDB.app.storage().ref(this.order.user_uid + '/orders/' + this.order.id + '/evidence/' + item.id + '/')
             .child(item.last_signed_name).getDownloadURL().then((URL) => {
         
               contador += 1;
               this.list_signatures.push({
                   item_evidence_id: item.id,
                   signature_name: item.last_signed_name,
                   signed_customer_name: item.last_signed_customer_name,
                   url: URL 
               });

               this.array_signatures.push({title: 'Firmas', firmas: this.list_signatures});
               console.log('111111 ', this.array_signatures);

               if(contador == num_firmas){
                 resolve(true);
               }
             })
             .catch((err) => {
               console.log('Error al obtener firma url: ' + err);
             });
           }else{
             for(let obj of this.items_config){
          
               if(item.itemId_assigned == obj.id){
                 this.userService.customerDB.app.storage().ref(this.order.user_uid + '/orders/' + this.order.id + '/evidence/' + item.id + '/')
                 .child(item.last_signed_name).getDownloadURL().then((URL) => {
             
                   contador += 1;
                   this.list_signatures.push({
                       item_evidence_id: item.id,
                       signature_name: item.last_signed_name,
                       signed_customer_name: item.last_signed_customer_name,
                       url: URL 
                   });
 
                   console.log('List Signatures -> ' + JSON.stringify(this.list_signatures));
                   this.array_signatures.push({id: item.id, title: obj.title, itemId_assigned: item.itemId_assigned, firmas: this.list_signatures});
                   console.log('222222 ', this.array_signatures);

                   this.list_signatures = [];

                   if(contador == num_firmas){
                     console.log('Array Signatures -> ' + JSON.stringify(this.array_signatures));
                     console.log('***End***')
                     resolve(true);
                   }
                 })
                 .catch((err) => {
                   console.log('Error al obtener firma url: ' + err);
                 });
               }
             }
           }
         }else{
           this.userService.customerDB.app.storage().ref(this.order.user_uid + '/orders/' + this.order.id + '/evidence/' + item.id + '/')
           .child(item.last_signed_name).getDownloadURL().then((URL) => {
       
             contador += 1;
             this.list_signatures.push({
                 item_evidence_id: item.id,
                 signature_name: item.last_signed_name,
                 signed_customer_name: item.last_signed_customer_name,
                 url: URL 
             });

             this.array_signatures.push({title: 'Firmas', firmas: this.list_signatures});
             console.log('333333 ', this.array_signatures);

             if(contador == num_firmas){
               resolve(true);
             }
           })
           .catch((err) => {
             console.log('Error al obtener firma url: ' + err);
           });
         }
       }
      }
     }else{
      resolve(true);
     }
         
    
    });
     
  }

  loadAllQuestionariesByOrder(){
    return new Promise((resolve, reject) => {

      var contador = 0;
      for(let item of this.order.evidence){
        console.log('Item Cuestionario -> ' + JSON.stringify(item));
        if(item.title != ''){
          if(item.questions.length > 0){
            contador = contador + 1;
          }
        }
      }

      console.log('Total Cuestionarios -> ' + contador);

      for(let item of this.order.evidence){
        if(item.questions.length > 0){
          if(item.itemId_assigned != undefined){
            if(item.itemId_assigned == 0){
              this.array_questionaries.push({title: 'Cuestionario', questions: item.questions});
                if(contador == this.array_questionaries.length){
                   resolve(true);
                }
            }else{
              console.log('ELSEEE ', this.items_config);
              for(let obj of this.items_config){
                if(item.itemId_assigned == obj.id){
                  if(item.title != ''){
                    this.array_questionaries.push({id: obj.id, title: obj.title, itemId_assigned: item.itemId_assigned, questions: item.questions});
                    if(contador == this.array_questionaries.length){
                      //ordenar ids de menor a mayor
                      this.array_questionaries = this.array_questionaries.sort((n1,n2) => n1.id - n2.id);
                      resolve(true);
                    }
                  }
                }
              }
            }
          }else{
            this.array_questionaries.push({title: 'Cuestionario', questions: item.questions});
            resolve(true);
          }
        }
      }

    });
  }

  loadAllCommentsByOrder(){
    return new Promise((resolve, reject) => {

      var contador = 0;
      for(let item of this.order.evidence){
        if(item.title == 'COMENTARIOS'){
          if(item.questions.length > 0){
            contador = contador + 1;
          }
        }
      }

      console.log('Total Comentarios -> ' + contador);

      for(let item of this.order.evidence){
        if(item.questions.length > 0){
          if(item.itemId_assigned != undefined){
            if(item.itemId_assigned == 0){
              this.array_comments.push({title: 'Comentarios', questions: item.questions});
                if(contador == this.array_comments.length){
                   resolve(true);
                }
            }else{
              for(let obj of this.items_config){
                console.log('OBJ DE ITEMS ' + JSON.stringify(obj));
                if(item.itemId_assigned == obj.id){
                  if(item.title == 'COMENTARIOS' || item.title == 'NOTAS' || item.title == 'Notas'){
                    this.array_comments.push({id: obj.id, title: obj.title, itemId_assigned: item.itemId_assigned, questions: item.questions});
                    if(contador == this.array_comments.length){
                      //ordenar ids de menor a mayor
                      this.array_comments = this.array_comments.sort((n1,n2) => n1.id - n2.id);
                      resolve(true);
                    }
                  }
                }
              }
            }
          }else{
            this.array_comments.push({title: 'Comentarios', questions: item.questions});
            resolve(true);
          }
        }
      }

    });
  }

  makeMultiPage() {
    /*
    var quotes = document.getElementById('page');
    var doc = new jspdf('p', 'pt','a4',true);
    doc.setFontSize(16);
    doc.setTextColor(80, 77, 78);
    doc.text(15, 2, 'should be an image under here'); 
    html2canvas((quotes), {
        useCORS : true,
        onrendered: function(canvas) {
          var imgData = canvas.toDataURL('image/jpeg');

          doc.addImage(imgData, 'JPEG', 15, 0, 34, 37);
          console.log(imgData);
          quotes.append(canvas);
          doc.save('Spec_Sheet.pdf');
        }
    });
    */
    
    var quotes = document.getElementById('page');
    html2canvas(quotes, {useCORS: true, allowTaint: true}).then(canvas => {
      //! MAKE YOUR PDF
      var pdf = new jspdf('p', 'pt', 'letter');
  
      for (var i = 0; i <= quotes.clientHeight/980; i++) {
          //! This is all just html2canvas stuff
          var srcImg  = canvas;
          var sX      = 0;
          var sY      = 980*i; // start 980 pixels down for every new page
          var sWidth  = 900;
          var sHeight = 980;
          var dX      = 0;
          var dY      = 0;
          var dWidth  = 900;
          var dHeight = 980;
  
          let onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', '900');
          onePageCanvas.setAttribute('height', '980');
          var ctx = onePageCanvas.getContext('2d');
          // details on this usage of this function: 
          // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
          ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);
  
          // document.body.appendChild(canvas);
          var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);
  
          var width         = onePageCanvas.width;
          var height        = onePageCanvas.clientHeight;
  
          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
              pdf.addPage(612, 791); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          pdf.setPage(i+1);
          //! now we add content to that page!
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, (width*.62), (height*.62));
  
      }
      //! after the for loop is finished running, we save the pdf.
      pdf.save('Test.pdf');
    });
    
  }

  testPdf(){
    this.getBase64ImageFromURL("https://images.pexels.com/photos/209640/pexels-photo-209640.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=300").then((dataURL) => {
      let logo_b64 = dataURL;
      //this.capturedImage = logo_b64;
      const documentDefinition = this.getDocumentDefinition(logo_b64);
      pdfMake.createPdf(documentDefinition).download();
    });  
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      //img.setAttribute("crossOrigin", "anonymous");
      img.crossOrigin = "anonymous";
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  async getDocumentDefinition(logo){
    return {

      content: [
        {
          text: 'PDF Generated with Image from external URL',
          fontSize : 20
        },
        {
          image: logo
        }        
      ]
      /*
      content: [
        {
          text: 'RESUME',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        {
          columns: [
            [{
              text: 'Ricardo Gaona C',
              style: 'name'
            },
            {
              text: 'En algun lugar del mundo'
            },
            {
              text: 'Email : ' + 'email@email.com',
            },
            {
              text: 'Contant No : ' + 'this.resume.contactNo',
            },
            {
              text: 'GitHub: ' + 'this.resume.socialProfile',
              link: 'this.resume.socialProfile',
              color: 'blue',
            }
            ],
            [
              {
                image: logo,
              }        
            ]
          ]
        }],
        styles: {
          name: {
            fontSize: 16,
            bold: true
          }
        }
        */
    };
      
  }

  toDuration(start_date: string, end_date: string) {
    let start_moment = Moment(start_date), end_moment = Moment(end_date);

    if (!end_moment.isValid() || !start_moment.isValid()) return "0 minutes";

    let total_minutes = 78; // end_moment.diff(start_moment, 'minutes');
    
    let secs = end_moment.diff(start_moment, 'seconds');
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
        "h": hours || 0,
        "m": minutes || 0,
        "s": seconds || 0
    };

    return (obj.h > 0 ? `${obj.h} hours `:'') + (obj.m > 0 ? `${obj.m} minutes ` : '') + (obj.s > 0 ? `${obj.s} seconds` : '');
  }

  openShareEvent() {
    this.dialog.open(ShareDialogComponent, {
      width: '600px',
      data: {
        urlActividades: this.url_link
      }
    });
  }

  openDownloadPDF() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = this.url_download;
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  openGoogleMap() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = this.url_link_map;
    link.setAttribute('visibility', 'hidden');
    link.click();
  }

  downloadGallery(order) {
    console.log('ORDER TO DOWNLOAD ');
    console.log(order);
    (order.evidencia || []).forEach((g, g1) => {
      const link = document.createElement('a');
      // link.target = '_blank';
      if (g.photo_url) {
       this.forceDownload(g.photo_url, g.photo_name);    
      }
    })
  }

  forceDownload(url, fileName){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window["URL"] || window["webkitURL"];
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

addQuestionaries(){
  return this.counts_items + 1;
}

}
