import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-types-of-events',
  templateUrl: './types-of-events.component.html',
  styleUrls: ['./types-of-events.component.scss']
})
export class TypesOfEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
