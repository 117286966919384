import { Pipe } from '@angular/core';

@Pipe({
    name: 'reverseDate',
})

export class ReverseDate {
    constructor() {}

    transform(date: string) {
        const parts = date.split('-')
        return `${parts[2]}-${parts[1]}-${parts[0]}`
    }
}