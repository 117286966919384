import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth.service';
import { PermissionService } from '@app/core/permission.service';
import { StorageService } from '@app/core/storage.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  public current_component: any;

  constructor(
    public permisionService: PermissionService,
    public authService: AuthService,
    public storageService: StorageService,
    public router: Router,
  ) { }

  ngOnInit() {
  }

  onActivate(componentRef) {
    this.current_component = null;
  }

  salir() {
    this.authService.doLogout().then(() => {
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    })
    .catch((err) => {
      // console.error('Error LogOut -> ' + err);
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    });
  }

}
