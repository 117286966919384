import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

import { TranslateService } from "@ngx-translate/core";
import { FormsService } from "../../core/forms.service";
import { AuthService } from "../../core/auth.service";
import * as cloneDeep from "lodash/cloneDeep";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { StorageService } from "../../core/storage.service";
import { NgxSpinnerService } from "ngx-spinner";

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ModalGenericService } from "@app/services/modal-generic.service";
import { take } from "rxjs/operators";
import { formatDate } from "@angular/common";
import { ModalImageComponent } from "@app/user-list/modal-image/modal-image.component";
import * as Moment from "moment";
import { UserService } from "@app/core/user.service";
import {
  ButtonsConfig,
  ButtonsStrategy,
  Description,
  DescriptionStrategy,
  Image,
  KS_DEFAULT_BTN_CLOSE,
  PreviewConfig,
} from "@ks89/angular-modal-gallery";
import { SlideConfig } from "@ks89/angular-modal-gallery/lib/model/slide-config.interface";

@Component({
  selector: "app-formulario-add",
  templateUrl: "./formulario-add.component.html",
  styleUrls: ["./formulario-add.component.scss"],
})
export class FormularioAddComponent implements OnInit {
  buttonsConfigCustom: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      // KS_DEFAULT_BTN_ROTATE,
      // KS_DEFAULT_BTN_FULL_SCREEN,
      // KS_DEFAULT_BTN_DOWNLOAD,
      KS_DEFAULT_BTN_CLOSE,
    ],
  };

  customDescription: Description = {
    strategy: DescriptionStrategy.ALWAYS_HIDDEN,
    imageText: "",
    numberSeparator: "",
    beforeTextDescription: "",
  };

  previewConfigCustomSize: PreviewConfig = {
    number: 5,
    visible: false,
    size: { width: "90px", height: "90px" },
  };

  slideConfig: SlideConfig = {
    sidePreviews: {
      show: false,
      size: {
        width: "100px",
        height: "100px",
      },
    },
  };
  height: number; //Para calcular el tamaño del item
  public paramResponse: string;
  public new_name = "";
  public id_doc = "";

  formularioNombre: FormControl;
  form = new FormGroup({
    title: new FormControl("", [Validators.required]),
    questions: new FormArray([], [Validators.required]),
  });

  formulario: any = {
    displayName: "",
    date_created: "",
    questions: [],
  };

  uid: string = "";
  private sub: any;
  validate_definition = true;
  type: string = "input";
  validate_url = true;
  validate_json = true;
  confirmed_array_elements = [];
  validation = false;
  public photos_: any[] = [];
  previewUrl: any = "";
  includeInvalidField = false;
  isurl(str) {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return true;
  }

  isjson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  get isEdit(): Boolean {
    return this.uid !== "";
  }

  get questions() {
    return this.form.get("questions") as FormArray;
  }

  get orderQuestions() {
    return (this.form.get("questions") as FormArray).controls.sort((a, b) => {
      if (a.get("order").value < b.get("order").value) {
        return -1;
      }
      if (a.get("order").value > b.get("order").value) {
        return 1;
      }
      return 0;
    });
  }

  get titleHasError(): boolean {
    return (
      this.form.get("title").invalid &&
      (this.form.get("title").dirty || this.form.get("title").touched)
    );
  }

  get questionsHasError(): boolean {
    return this.questions.invalid;
  }
  bodies: any[] = [];
  event_id = "";
  thumbnailToShow: any[] = [];
  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;
  @ViewChild("customList")
  private customList!: ElementRef<HTMLElement>;
  @ViewChildren("question") questionss: QueryList<ElementRef>;
  constructor(
    public formsService: FormsService,
    public authService: AuthService,
    public translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public storageService: StorageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private modalGeneric: ModalGenericService,
    private dialogRef: MatDialogRef<FormularioAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modelGeneric: ModalGenericService,
    public userService: UserService,
    private cd: ChangeDetectorRef
  ) {
    if (this.data != "") {
      console.log("data passed in is:", this.data.type);
      if (this.data.type != "") {
        this.type = this.data.type;
      }
      this.uid = this.data.id;
      this.event_id = this.data.event_id;
      console.log("data passed event_id:", this.event_id);
      if (this.event_id == "") {
        if (this.data.confirmed_array_elements) {
          this.additemsbodies(this.data.confirmed_array_elements);
        }
      }
    }

    if (authService.customerDB != null) {
      formsService.customerDB = authService.customerDB;
      formsService.customerSelected = authService.customerSelected;
    }

    this.initConfig();
  }

  private initConfig() {
    if (this.uid != "") {
      this.spinner.show();
      this.formsService.getForm({ id: this.uid }).subscribe((response) => {
        let selected_form = response.data;
        if (selected_form) {
          this.formulario.displayName = selected_form.questionnaire_name;
          this.new_name = selected_form.questionnaire_name;

          this.formulario.date_created = selected_form.date_created;
          (selected_form.questions || []).forEach((q, qi) => {
            let answers = q.dropdown_answers.map((a) => {
              return new FormControl(a, Validators.required);
            });
            if (q.type === "api_questions") {
              this.type = "api_questions";
              this.data.type = "api_questions";
            } else {
              this.data.type = "";
            }

            let textValidators = [Validators.required];

            if (q.type == "instruction") {
              this.photos_[qi] = q.dropdown_answers;
              textValidators = null;
            }
            this.questions.push(
              new FormGroup({
                dropdown_answers: new FormArray(answers),
                input_type: new FormControl(q.input_type),
                text: new FormControl(q.text, textValidators),
                api_url: new FormControl(q.api_url),
                api_body_id: new FormControl(q.api_body_id),
                api_header: new FormControl(q.api_header),
                api_definition: new FormControl(q.api_definition),
                type: new FormControl(q.type),
                required: new FormControl(q.required ? q.required : false),
                mask: new FormControl(q.mask ? q.mask : 0),
                show_message: new FormControl(
                  q.show_message ? q.show_message : false
                ),
                message: new FormControl(
                  q.message ? q.message : "Tu respuesta es requerida.",
                  q.type == "instruction" ? [Validators.required] : []
                ),
                order: new FormControl(qi + 1),
                thumbnail: new FormControl(q.thumbnail),
                thumbnail_url: new FormControl(q.thumbnail_url),
              })
            );
          });

          this.form.get("title").patchValue(selected_form.questionnaire_name);
          this.spinner.hide();
        } else {
          this.cancelSave();
          this.spinner.hide();
        }
      });
    } else {
      let orderArray = this.questions.controls.map((c) => c.get("order").value);
      let text = this.type != "input" ? "SN" : "";
      this.questions.push(
        new FormGroup({
          dropdown_answers: new FormArray([]),
          input_type: new FormControl("text"),
          text: new FormControl(text, [Validators.required]),
          api_url: new FormControl(""),
          api_body_id: new FormControl(""),
          api_header: new FormControl(""),
          api_definition: new FormControl(""),
          required: new FormControl(true),
          mask: new FormControl(0),
          show_message: new FormControl(false),
          message: new FormControl("Tu respuesta es requerida."),
          type: new FormControl(this.type),
          order: new FormControl(
            (isFinite(Math.max.apply(null, orderArray))
              ? Math.max.apply(null, orderArray)
              : 0) + 1
          ),
          thumbnail: new FormControl(false),
          thumbnail_url: new FormControl(""),
        })
      );
    }
    if (this.event_id != "") {
      this.formsService
        .getConfigITems({ id: this.event_id })
        .subscribe((response: any) => {
          if (response.status === "ok") {
            if (response.data.rows) {
              this.additemsbodies(response.data.rows);
            }
          }
        });
    }
  }

  ngOnInit() {
    // this.sub = this.route.params.subscribe(params => {
    // this.uid = this.uid;
    // });
  }
  private additemsbodies(data) {
    data.forEach((e) => {
      if (e.questionary_id != "") {
        if (e.questionary_id != this.uid || this.uid == "") {
          this.bodies.push(e);
        }
      }
    });
  }
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  cancelSave() {
    const ruta = "/" + this.storageService.getCurrentDB() + "/formularios-list";
    this.router.navigate([ruta]);
  }

  addQuestion() {
    let orderArray = this.questions.controls.map((c) => c.get("order").value);
    this.questions.push(
      new FormGroup({
        dropdown_answers: new FormArray([]),
        input_type: new FormControl("text"),
        text: new FormControl("", [Validators.required]),
        api_url: new FormControl(""),
        api_body_id: new FormControl(""),
        api_header: new FormControl(""),
        api_definition: new FormControl(""),
        required: new FormControl(true),
        mask: new FormControl(0),
        show_message: new FormControl(false),
        message: new FormControl("Tu respuesta es requerida."),
        type: new FormControl("input"),
        order: new FormControl(
          (isFinite(Math.max.apply(null, orderArray))
            ? Math.max.apply(null, orderArray)
            : 0) + 1
        ),
        thumbnail: new FormControl(false),
        thumbnail_url: new FormControl(""),
      })
    );
    //this.mainContentDiv.nativeElement.scrollTop = this.customList.nativeElement.offsetHeight + 150;
    //this.mainContentDiv.nativeElement.scrollIntoView();
    console.log(this.questions.controls);
    this.cd.detectChanges();
    this.questionss.last.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  cloneQuestion(field, index) {
    let searchIndex = this.questions.controls.findIndex(
      (f) => f.get("order").value == field.get("order").value
    );
    let indexnew = index + 1;
    let copytmp = this.questions.controls[searchIndex];
    if (copytmp.value["type"] == "instruction") {
      this.photos_[indexnew] = this.photos_[index];
    }
    let copy = cloneDeep(copytmp); //se realiza una copia ya que si metemos el orginal se modifican los 2
    this.questions.push(copy);
    this.cd.detectChanges();
    this.questionss.last.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  removeQuestion(field, index) {
    let removeIndex = this.questions.controls.findIndex(
      (f) => f.get("order").value == field.get("order").value
    );
    this.questions.removeAt(removeIndex);
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  moveUpQuestion(field, index) {
    let lastOrder = field.get("order").value;
    const nextControl = this.questions.controls.find(
      (f) => f.get("order").value === lastOrder - 1
    );
    if (nextControl) {
      field.get("order").value = nextControl.get("order").value;
      nextControl.get("order").patchValue(lastOrder);
    }
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  moveDownQuestion(field, index) {
    let lastOrder = field.get("order").value;
    const nextControl = this.questions.controls.find(
      (f) => f.get("order").value === lastOrder + 1
    );
    if (nextControl) {
      field.get("order").value = nextControl.get("order").value;
      nextControl.get("order").patchValue(lastOrder);
    }
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  isApi(field) {
    return field.get("type").value == "api_dropdown";
  }

  isTextInput(field) {
    return field.get("type").value == "input";
  }

  isPhotoInput(field) {
    return field.get("type").value == "photo";
  }

  isDateInput(field) {
    return field.get("type").value == "date";
  }

  isDateHourInput(field) {
    return field.get("type").value == "datehour";
  }

  isHourInput(field) {
    return field.get("type").value == "hour";
  }

  isNumericInput(field) {
    return field.get("type").value == "numeric";
  }

  isDropDown(field) {
    return field.get("type").value == "dropdown";
  }
  isDropDownWithSearch(field) {
    return field.get("type").value == "dropdown_search";
  }
  isDropDownMultiple(field) {
    return field.get("type").value == "multiple";
  }

  isCheckbox(field) {
    return field.get("type").value == "check";
  }

  isTextarea(field) {
    return field.get("type").value == "textarea";
  }

  isHeader(field) {
    return field.get("type").value == "header";
  }

  isTelephone(field) {
    return field.get("type").value == "telephone";
  }

  isEmail(field) {
    return field.get("type").value == "email";
  }

  isPercentage(field) {
    return field.get("type").value == "percentage";
  }

  isCalculator(field) {
    return field.get("type").value == "calculator";
  }

  isQualification(field) {
    return field.get("type").value == "qualification";
  }

  isQR(field) {
    return field.get("type").value == "qr";
  }

  isBarcode(field) {
    return field.get("type").value == "barcode";
  }

  isInstruction(field) {
    return field.get("type").value == "instruction";
  }

  removeAnswer(field, index) {
    (field.get("dropdown_answers") as FormArray).removeAt(index);
  }

  addAnswer(field, index) {
    (field.get("dropdown_answers") as FormArray).push(new FormControl(""));
  }

  validateDefinition() {
    let form_json = this.form.getRawValue();
    let definit = null;
    let api_header = null;
    let api_url = null;
    let ok: boolean = false;
    form_json.questions.map((q) => {
      definit = JSON.parse(q.api_definition);
      api_header = JSON.parse(q.api_header);
      api_url = q.api_url;
      console.log(this.isValidHttpUrl(api_url));
      console.log(definit, "\t", api_header, "\t", api_url);
    });

    if (this.isValidHttpUrl(api_url)) {
      this.formsService
        .validateApiElement({ page: 1, pagerows: 1 }, api_header, api_url)
        .subscribe(
          (response: any) => {
            console.log("response", response.success);
            if (response.success) {
              if (response.data) {
                if (response.data.length > 0) {
                  ok = true;
                }
              }
            }

            console.log("ok", ok);
            var myVar = response.data;

            for (var key1 in myVar) {
              for (var key2 in myVar[key1]) {
                const resultado = definit.find(
                  (x) => x.title === key2 || x.id === key2
                );
                console.log(resultado);
                if (resultado === undefined) {
                  ok = false;
                }
              }
            }

            if (ok) {
              this.validate_definition = true;
              this.modalGeneric.onModelexito(
                true, //disableClose
                "La definicion es correcta.", //message
                false, // cancelButton
                this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
                true, // info
                false //imagen info
              );
            } else {
              this.modalGeneric.onModelerror(
                true, //disableClose
                "La definicion no concuerda.", //message
                false, // cancelButton
                this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
                true, // info
                false //imagen info
              );
              this.validate_definition = false;
            }
          },
          (error) => {
            this.modalGeneric.onModelerror(
              true, //disableClose
              "Error al obtener información de la URL.", //message
              false, // cancelButton
              this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
              true, // info
              false //imagen info
            );
            this.validate_definition = false;
          }
        );
    } else {
      this.modalGeneric.onModelerror(
        true, //disableClose
        "No es una URL valida.", //message
        false, // cancelButton
        this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
        true, // info
        false //imagen info
      );
    }
  }

  private isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  validateApiQuestion() {
    let form_json = this.form.getRawValue();
    let returns = true;
    form_json.questions.map((q) => {
      console.log("validateApiQuestion", q);
      if (q.api_definition == "" || q.api_header == "" || q.api_url == "") {
        returns = false;
      }

      if (q.api_url != "") {
        if (this.isValidHttpUrl(q.api_url) == false) {
          this.modalGeneric.onModelerror(
            true, //disableClose
            "No es una URL valida.", //message
            false, // cancelButton
            this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
            true, // info
            false //imagen info
          );
          returns = false;
        }
      }
    });

    if (this.validate_definition == false) returns = false;

    return returns;
  }

  async processImages() {
    this.spinner.show();

    if (
      (this.data.type == "api_questions" &&
        this.validateApiQuestion() == false) ||
      !this.validForm()
    ) {
      this.spinner.hide();
    }

    for (let photo in this.photos_) {
      if (this.photos_[photo] && this.photos_[photo].length > 0) {
        for (let dataPhoto in this.photos_[photo]) {
          if (
            this.photos_[photo][dataPhoto]["photo_instruction"].indexOf(
              "firebasestorage"
            ) == -1
          ) {
            const downloadUrl = await this.userService.uploadPhotoReference(
              Moment().format("DD-MM-YYYY_hh:mm:ss") +
                this.photos_[photo][dataPhoto]["photo_number"],
              this.photos_[photo][dataPhoto]["photo_instruction"],
              "img_instructions"
            );
            this.photos_[photo][dataPhoto]["photo_instruction"] = downloadUrl;
          }
        }
      }
    }
    for (let question of this.questions.controls) {
      if (question.get("thumbnail").value === true) {
        if (
          question.get("thumbnail_url").value &&
          question.get("thumbnail_url").value.indexOf("firebasestorage") == -1
        ) {
          const urlFilestorage = await this.userService.uploadPhotoReference(
            Moment().format("DD-MM-YYYY_hh:mm:ss") +
              "_" +
              question.get("type").value,
            question.get("thumbnail_url").value,
            "thumbnails_questions"
          );
          question.get("thumbnail_url").setValue(urlFilestorage);
        }
      }
    }
    this.saveForm();
  }

  saveForm() {
    this.validation = true;
    const validateForm = this.validForm();
    console.log(`includeInvalidField => ${this.includeInvalidField}, ${validateForm}`);
    // debugger
    if (
      (
        ( this.data.type == "api_questions" &&
        this.validateApiQuestion() == false) ||
        !validateForm )
      || this.includeInvalidField
    ) {
      return false;
    }
    console.log(`includeInvalidField => ${this.includeInvalidField}, ${validateForm}`);
    // debugger

    this.spinner.show();
    let form_json = this.form.getRawValue();

    let api_id = 0;
    let data: any = {
      questionnaire_name: form_json.title,
      date_created: formatDate(new Date(), "dd/MM/yyyy", "en"),
      questions: form_json.questions.map((q, index) => {
        if (!q.required) {
          q.required = false;
        }
        if (q.type === "api_dropdown") {
          api_id += 1;
        }
        return {
          dropdown_answers:
            q.type === "instruction"
              ? this.photos_[index]
                ? this.photos_[index]
                : []
              : q.dropdown_answers,
          input_type: q.input_type,
          text: q.text,
          required: q.type === "qualification" ? false : q.required,
          mask: q.mask,
          api_id: api_id,
          api_body_id: q.api_body_id,
          api_header: q.api_header,
          api_definition: q.api_definition,
          api_url: q.api_url,
          show_message: q.show_message,
          message: q.message,
          type: q.type,
          thumbnail: q.thumbnail ? q.thumbnail : false,
          thumbnail_url: q.thumbnail_url,
        };
      }),
    };

    if (this.uid) {
      data.id = this.uid;
    }

    this.formsService.saveForm({ ...data }).subscribe((response) => {
      console.log('save form', {data, response})
      this.spinner.hide();
      if (response.status == "ok") {
        const form_json = this.form.getRawValue();

        this.id_doc = response.id_doc;

        if (this.new_name == "") {
          this.paramResponse = "AGREGADO";
          this.dialogRef.close("1");
        } else {
          this.new_name = form_json.title;
          this.modalGeneric
            .onModelexito(
              false, //disableClose
              this.uid
                ? this.translateService.instant(
                    "PAGES.FORM_ADD.SUCCESS.UPDATED"
                  )
                : this.translateService.instant("PAGES.FORM_ADD.SUCCESS.SAVED"), //message
              false, // cancelButton
              this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
              true, // info
              false //imagen info
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.paramResponse = "AGREGADO";
              this.dialogRef.close("2");
            });
        }
      } else if (response.status == "fail") {
        this.modalGeneric.onModelerror(
          true, //disableClose
          this.translateService.instant(
            "PAGES.FORM_ADD.ERRORS." +
              (response.message.code || "").toUpperCase()
          ), //message
          false, // cancelButton
          this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
          true, // info
          false //imagen info
        );
      }
    }, (error) => {
      console.log(error);
      this.modalGeneric.onModelerror(
        true, //disableClose
        this.translateService.instant(
          "PAGES.FORM_ADD.ERRORS." +
            (error.message.code || "").toUpperCase()
        ), //message
        false, // cancelButton
        this.translateService.instant("MESSAGE.ACEPTAR"), //confimButtonText
        true, // info
        false //imagen info
      );
    });
  }

  cleanForm() {
    while (this.questions.length) {
      this.questions.removeAt(0);
    }

    this.form.patchValue({
      title: "",
    });
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  cancelForm() {
    // const ruta = '/' + this.storageService.getCurrentDB() + '/formularios-list';
    // this.router.navigate([ruta]);
    this.dialogRef.close();
  }

  hasErrorEmptyAnswer(field, index): any {
    console.log('hasErrorEmptyAnswer =>', index);
    console.log('hasErrorEmptyAnswer =>', field);

    if (field.value !== "") {
      return false;
    }

    return true;
  }

  hasErrorNumericValid(field, min, max): any {
    if (field.value >= min && field.value <= max && field.value !== "") {
      return false;
    }

    return true;
  }

  hasErrorInstruction(field, input: string): any {
    if (
      field.get(input).invalid &&
      (field.get(input).dirty || field.get(input).touched)
    ) {
      return { required: true };
    }

    return false;
  }

  hasErrorQuestion(field, index): any {
    if (
      field.get("text").invalid &&
      (field.get("text").dirty || field.get("text").touched)
    ) {
      return { required: true };
    }

    /*

 switch (true) {
      case this.isDropDown(field):
        return this.getFilledAnswers(field).length < 2
          ? { minLength: true }
          : false;
        break;
      case this.isDropDownMultiple(field):
        return this.getFilledAnswers(field).length < 2
          ? { minLength: true }
          : false;
        break;
    }

   */

    return false;
  }

  getFilledAnswers(field): any[] {
    return (field.get("dropdown_answers") as FormArray).controls.filter(
      (f) => f.value !== "" && f.value !== null && f.value.trim().length > 0
    );
  }

  validForm() {
    let filledAnswers = true;
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
    console.log('INVALID ->',this.questions.controls.filter((item) => item.status === 'INVALID'))
    this.questions.controls.forEach((q) => {
      console.log("validForm", q);
      if (this.isDropDown(q) && this.getFilledAnswers(q).length < 2)
        filledAnswers = false;
      this.includeInvalidField = false;
      if (this.isDropDownWithSearch(q) && this.getFilledAnswers(q).length < 2)
        filledAnswers = false;
      this.includeInvalidField = false;
      if (this.isDropDownMultiple(q) && this.getFilledAnswers(q).length < 2)
        filledAnswers = false;
      this.includeInvalidField = false;
    });
    return this.form.valid && filledAnswers;
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("Reconociendo el drop");
    if (
      this.questions.controls.some(
        (item) => item.get("type").value === "instruction"
      )
    ) {
      moveItemInArray(this.photos_, event.previousIndex, event.currentIndex);
    }
    moveItemInArray(
      (this.form.get("questions") as FormArray).controls,
      event.previousIndex,
      event.currentIndex
    );
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }

  /**
   *Altura dinamica del cada tarea
   * @param event
   */
  cdkDragStarted(event: any) {
    this.height = 50; //event.source.element.nativeElement.offsetHeight / 3.5;
  }

  onChange(deviceValue, field, index) {
    var count = (field.get("dropdown_answers") as FormArray).length;
    console.log("COUNT " + count);
    console.log("deviceValue " + deviceValue);
    if (
      deviceValue == "dropdown" ||
      deviceValue == "multiple" ||
      deviceValue == "numeric" ||
      deviceValue == "dropdown_search"
    ) {
      if (count < 2) {
        (field.get("dropdown_answers") as FormArray).push(new FormControl(deviceValue == 'numeric' ? '2': 'Opción 1'));
        (field.get("dropdown_answers") as FormArray).push(new FormControl(deviceValue == 'numeric' ? '0': 'Opción 2'));
      }
    }

    if (deviceValue == "instruction") {
      this.photos_[index] = [];
      field.get("message").setValue("");
      field.get("message").setValidators([Validators.required]);
      field.get("text").setValidators(null);
      field.get("required").setValue(false);
    } else {
      field.get("message").setValidators(null);
      field.get("text").setValidators([Validators.required]);
      field.get("required").setValue(true);
    }
    field.updateValueAndValidity();
    this.validation = false;
    this.includeInvalidField = this.questions.controls.filter((item) => item.status === 'INVALID').length > 0;
  }
  //Lógica para subida de imágenes
  imagesUpload(index, isThumbnail: boolean = false, type) {
    const dialogRef = this.dialog
      .open(ModalImageComponent, {
        data: {
          cutout_shape: type,
          isThumbnailParam: isThumbnail,
          resize: isThumbnail ? true : false,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp: any) => {
        if (resp && resp.button == "aceptar") {
          if (isThumbnail) {
            this.questions.controls[index]
              .get("thumbnail_url")
              .setValue(resp.imageB64);
          } else {
            this.previewUrl = resp.imageB64;
            if (this.photos_[index].length === 0) {
              this.photos_[index] = [];
            }
            this.photos_[index].push({
              photo_instruction: this.previewUrl,
              photo_number: this.photos_.length + 1,
            });
          }
        }
      });
    // const input = document.getElementById('file');
    // input.click();
  }

  deleteImage(i: number, index: number) {
    this.photos_[i].splice(index, 1);
  }

  //Gallery thumbnail
  openGallery(thumbnailUrl, index) {
    this.thumbnailToShow = [];
    this.thumbnailToShow.push(new Image(index, { img: thumbnailUrl }));
    setTimeout(() => {
      const images: any = document.getElementsByClassName("image")[0];
      images.click();
    }, 350);
  }

  onCloseImageModal() {
    this.thumbnailToShow = [];
  }
}
