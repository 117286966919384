import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckMobileService {
    constructor() { }

    android() {
        return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/Nexus 7/i) || navigator.userAgent.match(/Nexus 10/i);
    }
    blackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
    }
    iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
    opera() {
        return navigator.userAgent.match(/Opera Mini/i);
    }
    windows() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    }
    tablet() {
        return navigator.userAgent.match(/Tablet/i) || navigator.userAgent.match(/KFAPWI/i);
    }
    any() {
      return (this.android() || this.blackBerry() || this.iOS() || this.opera() || this.windows() || this.tablet());
    }
    othersModels() {
        return (this.blackBerry() || this.opera() || this.windows() || this.tablet());
    }
}
