import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { PermissionService } from "../../core/permission.service";
import { StorageService } from "../../core/storage.service";
import { UserService } from "../../core/user.service";
import { AuthService } from "../../core/auth.service";
import { environment } from "environments/environment";

@Component({
  selector: "dashboard-item",
  templateUrl: "./dashboard-item.component.html",
  styleUrls: ["./dashboard-item.component.scss"],
})
export class DashboardItemComponent implements OnInit {
  @Input() order: any[] = [];
  @Input() name_category: string = "";
  @Input() name_subcategory: string = "";

  @Input() mode: string = "normal";

  @Output() detail_go = new EventEmitter<any>();
  @Output() open_share_Event = new EventEmitter<any>();
  @Output() go_to_pdf = new EventEmitter<any>();
  @Output() go_reports = new EventEmitter<any>();
  @Output() archived_order = new EventEmitter<any>();
  @Output() deleted_order = new EventEmitter<any>();
  @Output() transfer_order = new EventEmitter<any>();
  @Output() open_gallery = new EventEmitter<any>();

  photoUser: any;
  colorStatus: any;

  colors: any = {
    cerrado: {
      text: "Cerrada",
      color: "#06D6A0",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fcerrado.svg?alt=media&token=d9bb5ef8-bf16-48af-bc63-2c12d144eac2",
    },
    abierto: {
      text: "Abierta",
      color: "#118AB2",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fabierto.svg?alt=media&token=a91871f0-9422-4b71-a538-fa56614d05e6",
    },
    archivada: {
      text: "Archivada",
      color: "#073B4C",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Farchivado.svg?alt=media&token=bb2cce4f-f731-48bc-b1ba-4ac396740d30",
    },
    autorizada: {
      text: "Autorizada",
      color: "#4FBAD3",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fautorizado.svg?alt=media&token=7ed00fd9-d2cc-4a73-94ed-45edbe7e4848",
    },
    programada: {
      text: "Programada",
      color: "#FFD166",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fprogramado.svg?alt=media&token=736f4843-4be6-4c3b-9258-11637b3c10fa",
    },
    revision: {
      text: "En revisión",
      color: "#C9CC52",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Frevision.svg?alt=media&token=b9db4267-7de0-4885-8480-c8c871545710",
    },
    rechazada: {
      text: "Rechazada",
      color: "#CE5151",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Frechazado.svg?alt=media&token=8eacc472-ace3-47a5-8405-f8a6485b281b",
    },
    vencida: {
      text: "Vencida",
      color: "#EF476F",
      image:
        "https://firebasestorage.googleapis.com/v0/b/trackpoint-d2fa6.appspot.com/o/icons_default%2Fstatus_calendar%2Fvencido.svg?alt=media&token=0d28fdf5-2bf2-4a16-977a-f8109dce488c",
    },
  };

  all_reports = [];
  showEmptyReports: boolean = false;

  requested: boolean = false;

  constructor(
    public userService: UserService,
    public storageService: StorageService,
    public permisionService: PermissionService,
    public authService: AuthService
  ) {}

  DetailGo(order) {
    this.detail_go.emit(order);
  }

  openShareEvent(order) {
    this.open_share_Event.emit(order);
  }

  goToPdf(order) {
    this.go_to_pdf.emit(order);
  }

  async goReports(order) {
    if (!this.requested) {
      this.all_reports = [];
      this.showEmptyReports = false;
      let firebase_tenan = await this.authService.getTenancyParams();
      await firebase_tenan
        .collection("modules")
        .where("category", "==", order.category)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc: any) => {
            var reports = doc.data().reports;

            console.log(reports);

            if (reports) {
              await reports.forEach((i) => {
                i.get().then(async (property) => {
                  console.log(property);
                  if (property.data().available) {
                    this.all_reports.push(property.data());

                    if (this.all_reports.length <= 0) {
                      this.showEmptyReports = true;
                    }
                  } else {
                    this.showEmptyReports = true;
                  }
                });
              });
            } else {
              this.showEmptyReports = true;
            }
          });
        })
        .catch((err) => {
          console.log("Error Firebae -> " + err);
        });

      this.requested = true;
    }
  }

  getReportSelected(order, report) {
    const url_file = report.url_file + order.id;
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = url_file;
    link.download = "reporte.xlsx";
    link.setAttribute("visibility", "hidden");
    link.click();
  }

  archivedOrder(order) {
    this.archived_order.emit(order);
  }

  deletedOrder(order) {
    this.deleted_order.emit(order);
  }

  openGallery(order, id) {
    this.open_gallery.emit({ order: order, id: id });
  }

  ngOnInit() {
    console.log("ORDER", this.order);
    this.colorStatus = this.getColorStatus(
      this.order["status"],
      this.order["authorization_status"] == null ||
        this.order["authorization_status"] == undefined ||
        this.order["authorization_status"] == ""
        ? 0
        : this.order["authorization_status"]
    );

    this.userService
      .getUser({ uid: this.order["user_uid"] })
      .subscribe((user) => {
        console.log("USER", user);
        this.photoUser = user.data.photoURL;
      });

    // this.photoUser = this.order["history_transfer_names_array"][0].photo;
  }
  getColorStatus(status: any, authorization_status: any): [] {
    console.log("COLOR", status);
    console.log("COLOR", authorization_status);
    if (
      status === "2" &&
      (authorization_status === 0 ||
        authorization_status === null ||
        authorization_status === 1)
    ) {
      return this.colors.abierto;
    } else if (status === "3" && authorization_status === 0) {
      return this.colors.cerrado;
    } else if (status === "3" && authorization_status === 1) {
      return this.colors.revision;
    } else if (status === "3" && authorization_status === 2) {
      return this.colors.autorizada;
    } else if (status === "2" && authorization_status === 3) {
      return this.colors.rechazada;
    } else if (status === "5") {
      return this.colors.vencida;
    } else if (status === "4") {
      return this.colors.archivada;
    } else if (status === "1") {
      return this.colors.programada;
    }
  }

  transferOrder(order) {
    this.transfer_order.emit(order);
  }
}
