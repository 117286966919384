import { Component, OnInit } from '@angular/core';
import {AuthService} from "@app/core/auth.service";
import {StorageService} from "@app/core/storage.service";
import {Router} from "@angular/router";
import {PermissionService} from "@app/core/permission.service";

@Component({
  selector: 'app-classification-section',
  templateUrl: './classification-section.component.html',
  styleUrls: ['./classification-section.component.scss']
})
export class ClassificationSectionComponent implements OnInit {

  constructor( public authService: AuthService,
               public storageService: StorageService,
               private router: Router,
               public permisionService: PermissionService) { }

  ngOnInit() {
  }

  salir() {
    this.authService.doLogout().then(() => {
      if(this.storageService.getCurrentDB() != '') {
        const ruta = '/' + this.storageService.getCurrentDB() + '/login';
        this.storageService.logout();
        this.router.navigate([ruta]);
      }
    })
        .catch((err) => {
          // console.error('Error LogOut -> ' + err);
          if(this.storageService.getCurrentDB() != '') {
            const ruta = '/' + this.storageService.getCurrentDB() + '/login';
            this.storageService.logout();
            this.router.navigate([ruta]);
          }
        });
  }

}
