import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {
  api_customer_key = '';
  constructor(public http: HttpClient,) {
    this.api_customer_key = sessionStorage.getItem('api_customer_key');
  }

  getDataClassification(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/getClassifications';
    return this.http.get(url, { headers });
  }

  saveDataClassification(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': environment.headerAuthorization,
      'api-customer-key': this.api_customer_key,
    });
    const url = environment.urlEndpointMaster + '/saveUpdateClassification';
    return this.http.post(url, body, { headers });
  }
}
