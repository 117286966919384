import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth.service';
import { UtilsService } from '@app/core/helper/utils.helper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@app/core/storage.service';
import { CustomersService } from '@app/customers.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '@app/core/user.service';
import { formatDate, Location } from '@angular/common';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ItemEvent, Photos, questions } from '../../models/evidence';
import { extendMoment } from 'moment-range';
import * as Moment from 'moment';
import { TimezoneConversionService } from '@app/core/timezone-conversion.service';
import { PermissionService } from '../../core/permission.service';
import { OrdersService } from '../../orders.service';
import { ShareDialogComponent } from '@app/detail-order/components/share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material';

interface ordenesObj {
  id: string;
  order_number: string;
  customer_name: string;
  order_name: string;
  address: string;
  users_all: boolean;
  start_date: string;
  end_date: string;
  latitude: string;
  longitude: string;
  users: any[];
  status: string; //1 -> pendiente, 2 -> proceso, 3 -> completado
  authorization_status: number; //1 -> pendiente, 2 -> aceptado, 3 -> rechazado
  user_uid: string;
  departamento?: string;
  sucursal?: string;
  tipo?: string;
  qr_equipment: string;
  user_name: string;
  geolocation: any;
  address_checkIn?: string;
  category?: string;
  evidence: ItemEvent[];
  evidence_first_id: string;
  description_photo_evidence: string;
  photo_evidence: Photos[];
  classification_category_name?: string;
  classification_subcategory_name?: string;
  classification_category_uid?: string;
  classification_subcategory_uid?: string;
  customer_uid?: string;
  is_utc?: any;
  start_date_utc?: any;
  end_date_utc?: any;
  dynamic_url_web?: string;
  history_transfer_names: any[];
  history_transfer_names_array: any[];
}
interface itemsConfigDetails {
  id?: number;
  title: string;
  nextScreen: string;
  url_icon: string;
  questionary_id?: number;
  isExtraItem?: boolean;
  isDeleted?: boolean;
}
export interface PhotosTmp {
  item_evidence_id: string;
  photo_name: string;
  thumb_name: string;
  url_photo: string;
  url_thumb: string;
}
interface MultiPhotosObj {
  id: string;
  title: string;
  descripcion: string;
  evidencia: Photos[];
}

interface PreguntasObj {
  id?: number;
  title: string;
  questions: questions[];
}

interface itemsExtra {
  doc?: string;
  items_extra: itemsConfigDetails[];
}
export interface SignaturesTmp {
  item_evidence_id: string;
  signature_name: string;
  signed_customer_name?: string;
  url: string;
}
interface SignaturesObj {
  id?: string;
  title: string;
  firmas: SignaturesTmp[];
}
interface ordenes_pdf {
  id: string;
  order_number: string;
  customer_name: string;
  order_name: string;
  address: string;
  users_all: boolean;
  start_date: string;
  end_date: string;
  latitude: string;
  longitude: string;
  users: any[];
  status: string; //1 -> pendiente, 2 -> proceso, 3 -> completado
  user_uid: string;
  user_name: string;
  geolocation: any;
  address_checkIn?: string;
  evidence: ItemEvent[];
  photo_evidence: Photos[];
}

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss'],
})
export class CustomerAddComponent implements OnInit {
  uid: string = '';
  private sub: Subscription;
  isDisableds: boolean = false;
  ordenes: ordenesObj[] = [];

  title = '';
  in = '';
  out = '';
  user = '';
  address = '';
  description = '';
  photo_evidence: Photos[] = [];

  formulario = {
    name: '',
    status: '',
    contact: '',
    mail: '',
    telephone: '',
    address: '',
    checkinit: false,
    checkclose: false,
    checkscheduled: false,
    checkscheduledcalendar: false,
    inputvalue: '',
    selecttype: 'horas',
    customerid: '',
  };

  formularioNotify = {
    checkinit: false,
    checkclose: false,
    checkscheduled: false,
    checkscheduledcalendar: false,
    checknotify: false,
    inputvalue: '',
    selecttype: '',
  };

  formularioNotifydisabled = {
    inputvalue: true,
    selecttype: true,
  };

  firebase_uid_notify = '';
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  visible = true;
  correo_invalido = '';
  cel_invalido = '';
  emails = [];
  celphones = [];
  max = 24;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  moment = extendMoment(Moment);
  public orders_by_block = 12;
  pageActual: number = 1;
  selectedDate: string;
  currentTimeZone: any;
  selectMenu: boolean = false;
  totalitems: number;
  customer_id: number;
  public items_config: itemsConfigDetails[] = [];
  list_photos: PhotosTmp[] = [];
  list_signatures: SignaturesTmp[] = [];
  array_questionaries: PreguntasObj[] = [];
  array_comments: PreguntasObj[] = [];
  array_evidence: MultiPhotosObj[] = [];
  array_signatures: SignaturesObj[] = [];

  order_pdf: ordenes_pdf = {
    id: '',
    order_number: '',
    customer_name: '',
    order_name: '',
    address: '',
    users_all: false,
    start_date: '',
    end_date: '',
    latitude: '',
    longitude: '',
    users: [],
    status: '', //1 -> pendiente, 2 -> proceso, 3 -> completado
    user_uid: '',
    user_name: '',
    geolocation: null,
    address_checkIn: '',
    evidence: [],
    photo_evidence: [],
  };
  url_map = '';
  url_link = '';
  all_reports = [];
  showEmptyReports: boolean = false;
  name_event: string = '';

  fruits = [{ name: 'Lemon' }, { name: 'Lime' }, { name: 'Apple' }];

  form: FormGroup;
  formnotify: FormGroup;

  get isEdit(): Boolean {
    return this.uid !== '';
  }

  previewUrl: any = null;

  fileData: File = null;

  base64;

  validation_msg;

  previousUrl = '';

  constructor(
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public storageService: StorageService,
    public customerService: CustomersService,
    public userService: UserService,
    private location: Location,
    private modalGeneric: ModalGenericService,
    private timezoneConversionService: TimezoneConversionService,
    public permisionService: PermissionService,
    public ordersService: OrdersService,
    public dialog: MatDialog
  ) {
    this.currentTimeZone = this.storageService.getTimeZoneConfig();
  }

  ngOnInit() {
    if (this.authService.customerDB != null) {
      this.userService.customerDB = this.authService.customerDB;

      this.userService.customerSelected = this.authService.customerSelected;
    }
    this.sub = this.route.params.subscribe((params) => {
      this.customer_id = params['customer_id'] || '';
    });
    const finalUrl = this.router.url;
    this.isDisableds = finalUrl.includes('/customer-list/view/');
    this.setForm();
    this.setCustomerSelected();
    this.Period();
  }

  checkedstart() {
    this.formularioNotify.checkinit = !this.formularioNotify.checkinit;
  }

  checkedclose() {
    this.formularioNotify.checkclose = !this.formularioNotify.checkclose;
  }

  checkedscheduled() {
    this.formularioNotify.checkscheduled =
      !this.formularioNotify.checkscheduled;
  }

  checkedscheduledcalendar() {
    this.formularioNotify.checkscheduledcalendar =
      !this.formularioNotify.checkscheduledcalendar;
  }

  validarselectvalue() {
    let value = '';
    if (this.formularioNotify.selecttype == 'days') {
      if (parseInt(this.formularioNotify.inputvalue) > 24) {
        this.formularioNotify.inputvalue = '24';
      }
    } else {
      if (parseInt(this.formularioNotify.inputvalue) > 7) {
        this.formularioNotify.inputvalue = '7';
      }
    }
  }

  validarinputvalue() {
    let input = this.formularioNotify.inputvalue;
    this.formularioNotify.inputvalue = input.replace(/\D/g, '');
    if (parseInt(this.formularioNotify.inputvalue) < 0) {
      this.formularioNotify.inputvalue = '';
    }
    if (parseInt(this.formularioNotify.inputvalue) > this.max) {
      if (this.formularioNotify.selecttype == 'days') {
        this.formularioNotify.inputvalue = '24';
      } else {
        this.formularioNotify.inputvalue = '7';
      }
    }
    if (this.formularioNotify.selecttype == 'days') {
      this.max = 24;
    } else {
      this.max = 7;
    }
  }

  setCustomerSelected() {
    const customer$ = this.authService.customerSelected$.subscribe(
      (customer) => {
        console.log('~ customer', customer);
        if (customer) {
          if (customer$) customer$.unsubscribe();
          this.customerService.customerSelected =
            this.authService.customerSelected;
          this.getConfig();
        }
      }
    );
  }

  getConfig() {
    this.sub = this.route.params.subscribe((params) => {
      this.uid = params['id'] || '';
      console.log('~ this.uid', this.uid);
      if (this.uid) {
        this.spinner.show();
        const custSub = this.customerService
          .getCustomer({ id: this.uid })
          .subscribe((response) => {
            let selected_cust = response.data;
            if (selected_cust) {
              this.previousUrl = selected_cust.photoURL || '';
              this.formulario.name = selected_cust.name;
              this.formulario.status = selected_cust.status || 'A';
              this.formulario.address = selected_cust.address;
              this.formulario.contact = selected_cust.contact;
              this.formulario.mail = selected_cust.mail;
              this.formulario.telephone = selected_cust.telephone;
              this.previewUrl = selected_cust.photoURL;

              this.formulario.customerid = selected_cust.id;
              this.customerService.customerDB = this.authService.customerDB;

              if (response.modules_notify) {
                console.log(response.modules_notify);
                this.emails = [];
                this.celphones = [];
                response.modules_notify.data.emails.forEach((element) => {
                  this.emails.push({ name: element });
                });

                //this.celphones  = response.modules_notify.data.phones;
                response.modules_notify.data.phones.forEach((element) => {
                  this.celphones.push({ name: element });
                });

                this.formularioNotify.checkinit = response.modules_notify.data
                  .options[0]
                  ? response.modules_notify.data.options[0].status
                  : false;
                this.formularioNotify.checkclose = response.modules_notify.data
                  .options[1]
                  ? response.modules_notify.data.options[1].status
                  : false;
                this.formularioNotify.checkscheduled = response.modules_notify
                  .data.options[2]
                  ? response.modules_notify.data.options[2].status
                  : false;
                this.formularioNotify.checkscheduledcalendar = response
                  .modules_notify.data.options[3]
                  ? response.modules_notify.data.options[3].status
                  : false;
                this.formularioNotify.checknotify = response.modules_notify.data
                  .options[4]
                  ? response.modules_notify.data.options[4].status
                  : false;
                this.firebase_uid_notify = response.modules_notify.firebase_uid;
              }

              this.spinner.hide();
            } else {
              this.spinner.hide();
              this.cancelSave();
            }
            this.setForm();
            custSub.unsubscribe();
          });
      } else {
        this.setForm();
      }
    });
  }

  setForm() {
    this.form = new FormGroup({
      name: new FormControl(this.formulario.name || '', Validators.required),
      status: new FormControl(
        this.formulario.status || 'A',
        Validators.required
      ),

      contact: new FormControl(this.formulario.contact),
      mail: new FormControl(this.formulario.mail, Validators.required),
      telephone: new FormControl(this.formulario.telephone),
      address: new FormControl(this.formulario.address),
    });
    //this.validationMsg();
  }

  validationMsg() {
    this.validation_msg = {
      name: [
        {
          type: 'required',
          message: this.translateService.instant(
            'PAGES.CUSTOMER_LIST.ADD.EMPTY_NAME'
          ),
        },
      ],

      status: [
        {
          type: 'required',
          message: this.translateService.instant(
            'PAGES.CUSTOMER_LIST.ADD.EMPTY_STATUS'
          ),
        },
      ],
      contacto: [
        {
          type: 'required',
          message: 'Por favor, ingresa el contacto.',
        },
      ],
      mail: [
        {
          type: 'required',
          message: 'Por favor, ingresa el correo electrónico.',
        },
      ],
      telephone: [
        {
          type: 'required',
          message: 'Por favor, ingresa el teléfono.',
        },
      ],
      address: [
        {
          type: 'required',
          message: 'Por favor, ingresa la dirección.',
        },
      ],
    };
  }

  sendModelError(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
      true, // info
      false //imagen info
    );
  }

  private modules_notify_structure(type) {
    let emails = [];
    this.emails.forEach((e) => {
      emails.push(e.name);
    });

    let options = [
      {
        status: this.formularioNotify.checkinit,
        type_id: 1,
      },
      {
        status: this.formularioNotify.checkclose,
        type_id: 2,
      },
      {
        status: this.formularioNotify.checkscheduled,
        type_id: 3,
      },
      {
        status: this.formularioNotify.checkscheduledcalendar,
        type_id: 4,
      },
      {
        status: this.formularioNotify.checknotify,
        type_id: 5,
      },
    ];

    let phones = [];
    this.celphones.forEach((e) => {
      phones.push(e.name);
    });

    let modules_notify = {
      data: {
        emails: emails,
        options: options,
        phones: phones,
      },
      filters_uid: this.uid ? this.uid : '',
      firebase_uid: this.firebase_uid_notify,
      status: true,
      type_id: type,
    };
    return modules_notify;
  }
  async saveCustomer() {
    this.form.controls.name.markAsTouched();
    this.form.controls.status.markAsTouched();
    this.form.markAsDirty();
    if (this.form.invalid) {
      this.validationMsg();
      return;
    }

    let modules_notify = this.modules_notify_structure(1);

    this.spinner.show();

    let downloadUrl: any = this.previewUrl;
    if (this.base64) {
      downloadUrl = await this.customerService.uploadLogo(
        this.uid,
        this.base64
      );
    }

    let data: any = {
      data: {
        id: this.uid ? this.uid : '',
        name: this.form.controls.name.value,
        contact: this.form.controls.contact.value,
        mail: this.form.controls.mail.value,
        telephone: this.form.controls.telephone.value,
        address: this.form.controls.address.value,
        status: this.form.controls.status.value,
        user:
          this.authService.customerDB && this.authService.customerDB.app
            ? this.authService.customerDB.app.auth().currentUser.email
            : '',
        base64: '',
        photoURL: downloadUrl,
      },
      modules_notify: modules_notify,
    };

    console.log(data);
    this.customerService.saveCustomer(data).subscribe((response) => {
      this.spinner.hide();
      if (response.status == 'ok') {
        this.sendSuccess(
          this.uid
            ? this.translateService.instant(
                'PAGES.CUSTOMER_LIST.ADD.CUSTOMER_UPDATED'
              )
            : this.translateService.instant(
                'PAGES.CUSTOMER_LIST.ADD.CUSTOMER_SAVED'
              )
        );
      } else if (response.status == 'fail') {
        this.sendModelError(
          this.translateService.instant(
            'PAGES.USER_ADD.ERRORS.' +
              (response.message.code || '').toUpperCase()
          )
        );
      }
    });
  }

  sendSuccess(text: string) {
    this.modalGeneric
      .onModelexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        const ruta =
          '/' + this.storageService.getCurrentDB() + '/customer-list';
        this.router.navigate([ruta]);
      });
  }

  cancelSave() {
    // const ruta = '/' + this.storageService.getCurrentDB() + '/customer-list';
    // this.router.navigate([ruta]);
    this.location.back();
  }

  cleanSave() {
    this.formulario.name = '';
    this.formulario.status = '';
  }

  getImage() {
    const input = document.getElementById('file');
    input.click();
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.base64 = reader.result;
      console.log('~  this.previewUrl', this.previewUrl);
    };
  }

  clearValue(event) {
    event.target.value = '';
  }

  removeEmails(f: any): void {
    const index = this.emails.indexOf(f);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  addEmails(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    var EMAIL_REGEX =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.correo_invalido = '';
    const found = this.emails.find((element) => element.name === value.trim());

    if (value.trim() === '') {
      return;
    }

    if (found) {
      if (input) {
        input.value = '';
      }
      this.correo_invalido =
        'Por favor, ingresar un correo diferente este ya se encuentra registrado.';
      return;
    }

    if (!value.trim().match(EMAIL_REGEX)) {
      this.correo_invalido = 'Por favor, ingresar un correo valido.';
      return;
    }

    // Add our fruit
    if ((value || '').trim()) {
      this.emails.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removePhone(f: any): void {
    const index = this.celphones.indexOf(f);

    if (index >= 0) {
      this.celphones.splice(index, 1);
    }
  }

  addPhone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    var phone_REGEX = /^((\\+91-?)|0)?[0-9]{10}$/;
    this.cel_invalido = '';
    const found = this.celphones.find(
      (element) => element.name === value.trim()
    );

    if (value.trim() === '') {
      return;
    }

    if (found) {
      if (input) {
        input.value = '';
      }
      this.cel_invalido =
        'Por favor, ingresar un teléfono diferente este ya se encuentra registrado.';
      return;
    }
    if (!value.trim().match(phone_REGEX)) {
      this.cel_invalido = 'Por favor, ingresar un teléfono valido.';
      return;
    }

    // Add our fruit
    if ((value || '').trim()) {
      this.celphones.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  Period() {
    console.log('~ fecha', this.selectedDate);
    this.selectedDate = '2021-01-01';
    if (!this.selectedDate) {
      this.selectedDate = formatDate(
        this.timezoneConversionService.timezoneClientOffset(
          new Date().toDateString() + ' 00:00:00',
          this.currentTimeZone.reference
        ),
        'yyyy-MM-dd HH:mm:ss',
        'en'
      );
    } else {
      this.selectedDate = formatDate(
        this.timezoneConversionService.timezoneClientOffset(
          this.selectedDate.split(' ')[0] + ' 00:00:00',
          this.currentTimeZone.reference
        ),
        'yyyy-MM-dd HH:mm:ss',
        'en'
      );
    }

    let filters = {
      isRange: false,
      client: "'" + this.customer_id + "'",
      orders_by_block: this.orders_by_block,
      pageActual: this.pageActual,
      customerview: true,
      nameEvent: this.name_event,
    };

    this.spinner.show();

    this.ordersService.getOrdersApi(filters).subscribe((response: any) => {
      let data = response.data;
      this.ordenes = [];
      this.totalitems = response.totalitems;
      data.forEach((doc) => {
        let stardate: any = doc.start_date;
        let enddate: any = doc.end_date;
        let names_array_total = [];
        let names_array = {
          name: doc.user_name,
          uid: doc.user_uid,
        };
        names_array_total.push(names_array);

        if (
          doc.history_transfer_names &&
          doc.history_transfer_names.length > 0
        ) {
          for (let item_user_history of doc.history_transfer_names) {
            let search_uid = names_array_total.findIndex(
              (object) => object.uid === item_user_history.uid
            );
            if (search_uid === -1) {
              names_array_total.push({
                name: item_user_history.name,
                uid: item_user_history.uid,
              });
            }
          }
        }
        //console.log(stardate);
        this.ordenes.push({
          id: doc.id,
          order_number: doc.order_number,
          customer_uid: doc.customer,
          customer_name: doc.customer_name,
          order_name: doc.order_name,
          address: doc.address,
          users_all: doc.users_all,
          start_date: stardate.value,
          end_date: enddate != null ? enddate.value : '',
          latitude: doc.latitude,
          longitude: doc.longitude,
          users: doc.users,
          status: doc.status.toString(), //1 -> pendiente, 2 -> proceso, 3 -> completado
          authorization_status: doc.authorization_status, // 1-> Pendiente, 2 -> Aceptado, 3 -> Rechazado
          user_uid: doc.user_uid,
          departamento: doc.departamento,
          sucursal: doc.sucursal,
          user_name: doc.user_name,
          geolocation: null,
          address_checkIn: doc.address_checkIn,
          category: doc.category,
          tipo: doc.tipo,
          qr_equipment: doc.qr_equipment,
          evidence: [],
          evidence_first_id: '',
          description_photo_evidence: '',
          photo_evidence: [],
          classification_category_uid: doc.classification_category_uid,
          classification_subcategory_uid: doc.classification_subcategory_uid,
          classification_category_name: doc.classification_category_name,
          classification_subcategory_name: doc.classification_subcategory_name,
          history_transfer_names: doc.history_transfer_names
            ? doc.history_transfer_names
            : [],
          history_transfer_names_array: names_array_total,
          dynamic_url_web: doc.dynamic_url_web,

          is_utc: doc.is_utc,
          start_date_utc:
            doc.is_utc && doc.start_date_utc
              ? this.timezoneConversionService.getTimeConvertedTimeZone(
                  doc.start_date_utc.toDate(),
                  this.currentTimeZone.reference
                ).formatDashboard
              : '',
          end_date_utc:
            doc.is_utc && doc.end_date_utc
              ? this.timezoneConversionService.getTimeConvertedTimeZone(
                  doc.end_date_utc.toDate(),
                  this.currentTimeZone.reference
                ).formatDashboard
              : '',
        });
      });

      // this.updateCurrentPage((this.round(this.totalitems / this.orders_by_block) == 1));
      // if( filters.pageActual > this.pageActual  || ((this.pageActual > this.round(this.totalitems / this.orders_by_block) ) && this.totalitems > 0) ){
      //   this.pageActual = 1;
      //   this.Period();
      //   return false;
      // }
    });
    this.spinner.hide();
  }

  updateCurrentPage(value) {
    try {
      if (this.orders_by_block !== 1) {
        if (value == 1) {
          this.pageActual = 1;
        }
      }
    } catch (err) {}
  }

  round(value) {
    return Math.ceil(value);
  }
  pageChanged($event) {
    this.pageActual = $event;
    this.spinner.show();
    // this.loadImageEvidenceByBlock();
    this.Period();
  }

  orders_by_blockChanged() {
    this.pageActual = 1;
    this.Period();
  }

  filterSearch(filter) {
    this.name_event = filter;
    this.pageActual = 1;
    this.Period();
  }

  clearSearch() {
    this.name_event = '';
    this.pageActual = 1;
    this.Period();
  }

  DetailGo(order: ordenesObj) {
    if (this.storageService.getCurrentDB() != '') {
      const urlEvento = this.authService.customerSelected.url_report + order.id;
      window.open(urlEvento, 'blank');
    }
  }

  openShareEvent(order: ordenesObj) {
    console.error();

    if (order.dynamic_url_web) {
      let urlEvento = order.dynamic_url_web;

      this.dialog.open(ShareDialogComponent, {
        height: '230px',
        width: '600px',
        data: {
          urlEvento,
        },
      });
    } else {
      if (this.storageService.getCurrentDB() != '') {
        const urlEvento =
          this.authService.customerSelected.url_report + order.id;

        this.ordersService
          .getInfoOrderWithUrl(order.id, urlEvento)
          .subscribe((respose: any) => {
            console.error(respose);
            let urlEvento = respose.data.dynamic_url_web;
            console.log('~ urlEvento', urlEvento);

            this.dialog.open(ShareDialogComponent, {
              height: '230px',
              width: '600px',
              data: {
                urlEvento,
              },
            });
          });
      }
    }
  }

  goToPdf(order: ordenesObj) {
    this.list_photos = [];
    this.list_signatures = [];
    this.array_questionaries = [];
    this.array_comments = [];
    this.array_evidence = [];
    //this.url_logo_client = '';
    //this.userService.customerSelected = this.authService.customerSelected;
    this.userService.getInfoOrder(order.id).then((order_data: any) => {
      if (order_data != null) {
        this.order_pdf.id = order_data.firebase_id;
        this.order_pdf.order_number = order_data.order_number;
        this.order_pdf.customer_name = order_data.customer_name;
        this.order_pdf.order_name = order_data.order_name;
        this.order_pdf.address = order_data.address;
        this.order_pdf.users_all = order_data.users_all;
        this.order_pdf.start_date = order_data.start_date;
        this.order_pdf.end_date = order_data.end_date;
        this.order_pdf.latitude = order_data.latitude;
        this.order_pdf.longitude = order_data.longitude;
        this.order_pdf.users = order_data.users;
        this.order_pdf.status = order_data.status;
        this.order_pdf.user_uid = order_data.user_uid;
        this.order_pdf.user_name = order_data.user_name;
        this.order_pdf.geolocation = order_data.geolocation;
        this.order_pdf.address_checkIn = order_data.address_checkIn;

        this.url_map =
          '<img src= "https://maps.googleapis.com/maps/api/staticmap?center=' +
          this.order_pdf.latitude +
          ',' +
          this.order_pdf.longitude +
          '&amp;zoom=18&amp;size=400x400&amp;maptype=roadmap&amp;markers=color:green|label:Site|' +
          this.order_pdf.latitude +
          ',' +
          this.order_pdf.longitude +
          '&amp;key=AIzaSyDw4PN72JS0z_pPNNofyHvMdlIDEveb63Q' +
          '">';
        this.url_link =
          'http://trackpoint.dev.webpoint.com.mx/' +
          this.authService.customer_route +
          '/detail-order?order_uui=' +
          this.order_pdf.id;
        var url_customer = this.authService.customerSelected.url_pdf;
        const link = document.createElement('a');
        link.target = '_blank';
        var url_full = url_customer + this.order_pdf.id;
        console.log(url_full);
        link.href = url_full;
        link.setAttribute('visibility', 'hidden');
        link.click();
      } else {
        console.log('No se encontro la orden.');
      }
    });
  }

  async goReports(order: ordenesObj) {
    this.all_reports = [];
    this.showEmptyReports = false;

    //this.userService.customerSelected = this.authService.customerSelected;
    await //this.userService.customerDB.app
    //.firestore()
    this.userService
      .getTenancyParams()
      .collection('modules')
      .where('category', '==', order.category)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc: any) => {
          var reports = doc.data().reports;

          if (reports) {
            await reports.forEach((i) => {
              i.get().then(async (property) => {
                if (property.data().available) {
                  this.all_reports.push(property.data());

                  if (this.all_reports.length <= 0) {
                    this.showEmptyReports = true;
                  }
                } else {
                  this.showEmptyReports = true;
                }
              });
            });
          } else {
            this.showEmptyReports = true;
          }
        });
      })
      .catch((err) => {
        console.log('Error Firebae -> ' + err);
      });
  }

  archivedOrder(order): void {
    this.modalGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant('PAGES.DASHBOARD.ARCHIVED_CONFIRM'), //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp === 'aceptar') {
          this.spinner.show();

          this.ordersService
            .archivedOrder({ order: { id: order.id } })
            .subscribe((response) => {
              this.spinner.hide();
              if (response.status === 'ok') {
                this.modalGeneric
                  .onModelexito(
                    false, //disableClose
                    this.translateService.instant(
                      'PAGES.DASHBOARD.ARCHIVED_OK'
                    ), //message
                    false, // cancelButton
                    this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                    true, // info
                    false //imagen info
                  )
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.Period();
                  });
              } else {
                this.spinner.hide();
                this.modalGeneric.onModelerror(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.DASHBOARD.ARCHIVED_ERROR'
                  ), //message
                  false, // cancelButton
                  this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                  true, // info
                  false //imagen info
                );
              }
            });
        }
      });
  }

  deletedOrder(order): void {
    this.modalGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant('PAGES.DASHBOARD.DELETED_CONFIRM'), //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp === 'aceptar') {
          this.spinner.show();

          this.ordersService
            .deletedOrder({ order: { id: order.id } })
            .subscribe((response) => {
              this.spinner.hide();
              if (response.status === 'ok') {
                this.modalGeneric
                  .onModelexito(
                    false, //disableClose
                    this.translateService.instant('PAGES.DASHBOARD.DELETED_OK'), //message
                    false, // cancelButton
                    this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                    true, // info
                    false //imagen info
                  )
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.Period();
                  });
              } else {
                this.spinner.hide();
                this.modalGeneric.onModelerror(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.DASHBOARD.DELETED_ERROR'
                  ), //message
                  false, // cancelButton
                  this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                  true, // info
                  false //imagen info
                );
              }
            });
        }
      });
  }
}
