import { ProgrammingBulkloadComponent } from "./programming-list/programming-bulkload/programming-bulkload.component";
import { Routes } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { UserComponent } from "./user/user.component";
import { RegisterComponent } from "./register/register.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserResolver } from "./user/user.resolver";
import { AuthGuard } from "./core/auth.guard";
import { CustomerConfigComponent } from "./customer-config/customer-config.component";
import { PrincipalComponent } from "./principal/principal.component";
import { GeneratePdfComponent } from "./generate-pdf/generate-pdf.component";
import { UserListComponent } from "./user-list/user-list.component";
import { ListComponent } from "./user-list/list/list.component";
import { UserAddComponent } from "./user-list/user-add/user-add.component";
import { ChangePasswordComponent } from "./user-list/change-password/change-password.component";
import { FormulariosComponent } from "./formularios/formularios.component";
import { FormularioListComponent } from "./formularios/formulario-list/formulario-list.component";
import { FormularioAddComponent } from "./formularios/formulario-add/formulario-add.component";
import { EventsComponent } from "./events/events.component";
import { EventsListComponent } from "./events/events-list/events-list.component";
import { EventsAddComponent } from "./events/events-add/events-add.component";
import { DashboardComponentAdmin } from "./dashboard-admin/dashboardadmin.component";
import { EditCustomerComponent } from "./dashboard-admin/edit-customer/edit-customer.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RecoverPasswordComponent } from "./recover-password/recover-password.component";
import { CustomerTableComponent } from "./customer-list/customer-table/customer-table.component";
import { CustomerListComponent } from "./customer-list/customer-list.component";
import { CustomerAddComponent } from "./customer-list/customer-add/customer-add.component";
import { ProgrammingListComponent } from "./programming-list/programming-list.component";
import { ProgrammingTableComponent } from "./programming-list/programming-table/programming-table.component";
import { ProgrammingAddComponent } from "./programming-list/programming-add/programming-add.component";
import { ProgrammingSearchMapComponent } from "./programming-list/programming-search-map/programming-search-map.component";
import { ProgrammingViewComponent } from "./programming-list/programming-view/programming-view.component";
import { ProgrammingCalendarComponent } from "@app/programming-list/programming-calendar/programming-calendar.component";
import { ProgrammingAddressComponent } from "./programming-list/programming-address/programming-address.component";
import { EquipmentComponent } from "./equipment/equipment.component";
import { EquipmentDetailComponent } from "./equipment/equipment-detail/equipment-detail.component";
import { EquipmentAddComponent } from "./equipment/equipment-add/equipment-add.component";
import { EquipmentTableComponent } from "./equipment/equipment-table/equipment-table.component";
import { EquipmentTemplateComponent } from "./equipment/equipment-template/equipment-template.component";
import { EquipmentTemplateFormAddComponent } from "./equipment/equipment-template-form-add/equipment-template-form-add.component";
import { EquipmentConfirmCreateTemplateComponent } from "./equipment/equipment-confirm-create-template/equipment-confirm-create-template.component";
import { ModalImageComponent } from "./user-list/modal-image/modal-image.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { ConfigurationListComponent } from "./configuration/configuration-list/configuration-list.component";
import { ClassificationSectionComponent } from "@app/classification-section/classification-section.component";
import { DashboardreportlayoutComponent } from "./dashboardreport/components/dashboardreportlayout/dashboardreportlayout.component";
import { ConfigurationWebhookComponent } from "./configuration/configuration-webhook/configuration-webhook.component";
import { ConfigurationTimezoneComponent } from "@app/configuration/configuration-timezone/configuration-timezone.component";
import { HelpComponent } from "./help/help.component";
import { RecentActivitiesComponent } from "./help/pages/recent-activities/recent-activities.component";
import { CustomersComponent } from "./help/pages/customers/customers.component";
import { TypesOfEventsComponent } from "./help/pages/types-of-events/types-of-events.component";
import { ProgramationComponent } from "./help/pages/programation/programation.component";
import { UsersComponent } from "./help/pages/users/users.component";
import { DashboardComponent as DashboardHelpComponent } from "./help/pages/dashboard/dashboard.component";
import { ConfigurationComponent as ConfigurationHelpComponent } from "./help/pages/configuration/configuration.component";
import { DataSheetComponent } from "./help/pages/data-sheet/data-sheet.component";
import { ConfigurationHourlaborComponent } from "./configuration/configuration-hourlabor/configuration-hourlabor.component";
import { ProgrammingNewAddComponent } from "./programming-list/programming-new-add/programming-new-add.component";

export const rootRouterConfig: Routes = [
  { path: "", redirectTo: "principal", pathMatch: "full" },
  { path: "principal", component: PrincipalComponent },
  //{ path: 'register', component: RegisterComponent},
  //{ path: 'user', component: UserComponent},
  //{ path: 'customer-config', component: CustomerConfigComponent},
  //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  //{ path: 'detail-order', component: DetailOrderComponent, canActivate: [AuthGuard]},
  {
    path: "trackpoint/PrincipalAdmin",
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      {
        path: "dashboard-admin",
        component: DashboardComponentAdmin,
        canActivate: [AuthGuard],
      },
      {
        path: "edit-customer/:id/:mode",
        component: EditCustomerComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: ":client-alias",
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      { path: "reset-password/:jwt", component: ResetPasswordComponent },
      { path: "recover-password", component: RecoverPasswordComponent },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "dashboardreport",
        canActivate: [AuthGuard],
        component: DashboardreportlayoutComponent,
        loadChildren:
          "./dashboardreport/dashboardreport.module#DashboardreportModule",
      },
      {
        path: "help",
        component: HelpComponent,
        //canActivate: [AuthGuard],
        children: [
          { path: "", component: RecentActivitiesComponent },
          { path: "customers", component: CustomersComponent },
          { path: "types-of-events", component: TypesOfEventsComponent },
          { path: "programation", component: ProgramationComponent },
          { path: "users", component: UsersComponent },
          { path: "dashboard", component: DashboardHelpComponent },
          { path: "configuration", component: ConfigurationHelpComponent },
          { path: "data-sheet", component: DataSheetComponent },
        ],
      },

      /*{
        path: 'detail-order',
        component: DetailOrderComponent
      },*/
      {
        path: "detail-order",
        loadChildren:
          "./detail-order-lazy/detail-order-lazy.module#DetailOrderLazyModule",
      },
      { path: "generate-pdf", component: GeneratePdfComponent },
      {
        path: "report-order",
        loadChildren:
          "./view-report-pdf/view-report-pdf.module#ViewReportPdfModule",
      },
      {
        path: "user-list",
        component: UserListComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", component: ListComponent, canActivate: [AuthGuard] },
          {
            path: "add",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "view/:id",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "changepass/:id",
            component: ChangePasswordComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "customer-list",
        component: CustomerListComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: CustomerTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: CustomerAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: CustomerAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "view/:id/:customer_id",
            component: CustomerAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "formularios-list",
        component: FormulariosComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: FormularioListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: FormularioAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: FormularioAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "events-list",
        component: EventsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: EventsListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: EventsAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: EventsAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "equipments",
        component: EquipmentComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: EquipmentTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "view/:uid",
            component: EquipmentDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "templates",
            component: EquipmentTemplateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "templates/add",
            component: EquipmentTemplateFormAddComponent,
            canActivate: [AuthGuard],
          },

          {
            path: "templates/confirm",
            component: EquipmentConfirmCreateTemplateComponent,
            canActivate: [AuthGuard],
          },

          {
            path: "add",
            component: EquipmentAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add/:uid",
            component: EquipmentAddComponent,
            canActivate: [AuthGuard],
          },

          {
            path: "edit/:uidEquipment",
            component: EquipmentAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "programming-list",
        component: ProgrammingListComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: ProgrammingTableComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: ProgrammingAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "new-add",
            component: ProgrammingNewAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "search",
            component: ProgrammingSearchMapComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "bulkload",
            component: ProgrammingBulkloadComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "address",
            component: ProgrammingAddressComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "view/:order_uui",
            component: ProgrammingViewComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "calendar",
            loadChildren:
              "./programming-list/programming-calendar/programming-calendar.module#ProgrammingCalendarModule",
          },
          {
            path: "calendar2",
            loadChildren:
              "./programming-list/programming-fullcalendar/programming-fullcalendar.module#ProgrammingFullcalendarModule",
          },
          {
            path: "new-calendar",
            loadChildren:
              "./programming-list/programming-newcalendar/programming-newcalendar.module#ProgrammingNewCalendarModule",
          },
        ],
      },
      {
        path: "classification-list",
        component: ClassificationSectionComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            loadChildren:
              "./classification-section/classification-list/classification-list.module#ClassificationListModule",
          },
        ],
      },
      {
        path: "configuration",
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: ConfigurationListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "webhook",
            component: ConfigurationWebhookComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "time-zone",
            component: ConfigurationTimezoneComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "hour-labor",
            component: ConfigurationHourlaborComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  /*{
    path: 'Andamios', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'AndamiosDev', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'MGLift', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'trackpointdemos', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'reset-password/:jwt', component: ResetPasswordComponent },
      { path: 'recover-password', component: RecoverPasswordComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'Industronic', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'Bakter', 
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent},
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      },
    ]
  },
  {
    path: 'CasasUnika',  
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent },
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      }
    ]
  },
  {
    path: 'Kavak',  
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent },
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      }
    ]
  },
  {
    path: 'CrecEmpExpress',  
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'detail-order', component: DetailOrderComponent },
      { path: 'generate-pdf', component: GeneratePdfComponent },
      { 
        path: 'user-list', 
        component: UserListComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: UserAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: UserAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'formularios-list', 
        component: FormulariosComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: FormularioListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: FormularioAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: FormularioAddComponent, canActivate: [AuthGuard] }
        ]
      },
      { 
        path: 'events-list', 
        component: EventsComponent, 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: EventsListComponent, canActivate: [AuthGuard] },
          { path: 'add', component: EventsAddComponent, canActivate: [AuthGuard] },
          { path: 'edit/:id', component: EventsAddComponent, canActivate: [AuthGuard] }
        ]
      }
    ]
  },*/
  {
    path: "**",
    component: LoginComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "login", component: LoginComponent },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      /*{
        path: 'detail-order',
        component: DetailOrderComponent
      },*/
      {
        path: "detail-order",
        loadChildren:
          "./detail-order-lazy/detail-order-lazy.module#DetailOrderLazyModule",
      },
      { path: "generate-pdf", component: GeneratePdfComponent },
      {
        path: "report-order",
        loadChildren:
          "./view-report-pdf/view-report-pdf.module#ViewReportPdfModule",
      },
      {
        path: "user-list",
        component: UserListComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", component: ListComponent, canActivate: [AuthGuard] },
          {
            path: "add",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "view/:id",
            component: UserAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "image",
            loadChildren:
              "./user-list/modal-image/modal-image.module#ModalImageModule",
          },
          {
            path: "changepass/:id",
            component: ChangePasswordComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "formularios-list",
        component: FormulariosComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: FormularioListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: FormularioAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: FormularioAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: "events-list",
        component: EventsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: EventsListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "add",
            component: EventsAddComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "edit/:id",
            component: EventsAddComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];
