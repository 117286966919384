import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {
  secretKey: any = CryptoJS.enc.Utf8.parse('fSn3eafUZTdsh4g4');
  initializationVector: any = CryptoJS.enc.Utf8.parse('w6xSMWrPHZZNrt26');
  constructor() { }

  public encryptRerturnSimpleString(stringToEncrypt) {
      let encrypted = CryptoJS.AES.encrypt(stringToEncrypt.toString(), this.secretKey, {
          keySize: 16,
          iv: this.initializationVector,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.toString();
  }
  public decryptSimpleString(stringToDecrypt) {
      let decrypted = CryptoJS.AES.decrypt(stringToDecrypt.toString(), this.secretKey, {
          keySize: 16,
          iv: this.initializationVector,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
  }
  public encryptConfigFirebase(datos: any) {
      const customerDataEncrypt = {
          apiKey: this.encryptRerturnSimpleString(datos['config_firebase']['apiKey']),
          appId: this.encryptRerturnSimpleString(datos['config_firebase']['appId']),
          authDomain: this.encryptRerturnSimpleString(datos['config_firebase']['authDomain']),
          databaseURL: this.encryptRerturnSimpleString(datos['config_firebase']['databaseURL']),
          measurementId: this.encryptRerturnSimpleString(datos['config_firebase']['measurementId']),
          messagingSenderId: this.encryptRerturnSimpleString(datos['config_firebase']['messagingSenderId']),
          projectId: this.encryptRerturnSimpleString(datos['config_firebase']['projectId']),
          storageBucket: this.encryptRerturnSimpleString(datos['config_firebase']['storageBucket']),
          encrypt: true
      }
      datos.config_firebase = customerDataEncrypt;
      datos.path_login = datos.path_login ? datos.path_login : '';
      datos.url_report = datos.url_report ? datos.url_report : '';
      return datos;
  }
  public decryptConfigFirebase(datos: any) {
      const customerDataDecrypt = {
          apiKey: this.decryptSimpleString(datos['config_firebase']['apiKey']),
          appId: this.decryptSimpleString(datos['config_firebase']['appId']),
          authDomain: this.decryptSimpleString(datos['config_firebase']['authDomain']),
          databaseURL: this.decryptSimpleString(datos['config_firebase']['databaseURL']),
          measurementId: this.decryptSimpleString(datos['config_firebase']['measurementId']),
          messagingSenderId: this.decryptSimpleString(datos['config_firebase']['messagingSenderId']),
          projectId: this.decryptSimpleString(datos['config_firebase']['projectId']),
          storageBucket: this.decryptSimpleString(datos['config_firebase']['storageBucket']),
          encrypt: true
      }
      datos.config_firebase = customerDataDecrypt;
      return datos;
  }
}
