import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import { MatDialog, MatDialogRef } from  '@angular/material';
import { ModalGenericComponent } from '../components/modal-generic/modal-generic.component';

@Injectable({
  providedIn: 'root'
})
export class ModalGenericService {

  constructor(private  dialog:  MatDialog) { }
  private fooSubject = new Subject<any>();
  radiodelete: string;
  onclose(){
    this.dialog.closeAll();
  }

  onModelGeneric(disableClose: boolean = false, title: string, 
    mensage: string, cancelButton:boolean =  false, type: 
    string = "success", confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false
  ){
    const dialogRef =  this.dialog.open(ModalGenericComponent,{ width: '23%', data: {
      title: title,
      text: mensage,
      type: type,
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error


  onModelexito(disableClose: boolean = false,
    mensage: string, cancelButton:boolean =  false,  confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false, subtitle_message:string = '', not_add: any[] = [],
  mensaje_sub_foo: string = ''
  ){
    console.log('NOTTTT ', not_add);
    const dialogRef =  this.dialog.open(ModalGenericComponent,{panelClass: 'my-class',   data: {
      title: 'Éxito',
      text: mensage,
      type: 'success',
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo,
      subtitle_message: subtitle_message,
      not_add: not_add,
      mensaje_sub_foo: mensaje_sub_foo
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error

  onModelBulkexito(disableClose: boolean = false,
    mensage: string, cancelButton:boolean =  false,  confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false, subtitle_message:string = '', not_add: any[] = [],
  mensaje_sub_foo: string = ''
  ){
    console.log('NOTTTT ', not_add);
    const dialogRef =  this.dialog.open(ModalGenericComponent,{panelClass: 'my-class',   data: {
      title: 'Éxito',
      text: mensage,
      type: 'success',
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo,
      subtitle_message: subtitle_message,
      not_add: not_add,
      mensaje_sub_foo: mensaje_sub_foo,
      bulk: true
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error

  onModelconfirmacion(disableClose: boolean = false,
    mensage: string, cancelButton:boolean =  false, confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false, title?: string
  ){
    const dialogRef =  this.dialog.open(ModalGenericComponent,{panelClass: 'my-class',  data: {
      title: title || 'Confirmación',
      text: mensage,
      type: 'warning',
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error


  onModelconfirmacionEvent(typeevent: string, disableClose: boolean = false,
    mensage: string, cancelButton:boolean =  false, confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false, title?: string
  ,radio1: string = "Si, eliminar esta actividad."
  ,radio2: string = 'Si, eliminar todas las actividades de aquí en adelante.',
  subtitle: string = ""
  ){
    const dialogRef =  this.dialog.open(ModalGenericComponent,{ panelClass: 'my-class',  data: {
      title: title || 'Confirmación',
      text: mensage,
      type: 'warning',
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo,
      typeevent: typeevent,
      radio1: radio1,
      radio2: radio2,
      subtitle: subtitle
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error



  onModelerror(disableClose: boolean = false,
    mensage: string, cancelButton:boolean =  false, confirmButtonText:string = "Aceptar",
  info: boolean = true, imageninfo: boolean = false
  ){
    const dialogRef =  this.dialog.open(ModalGenericComponent,{ panelClass: 'my-class', data: {
      title: 'Error',
      text: mensage,
      type: 'danger',
      confirmButtonText: confirmButtonText,
      cancelButton: cancelButton,
      info: info,
      imageUrl:  'assets/images/search-app-icon.jpg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo
    }, 
      disableClose: disableClose
    });

    return dialogRef; 
  }//Éxito, Confirmación, Error

  onModalSearch(disableClose: boolean = false, title: string = '', 
  mensage: string = '', type: string = "success", info: boolean = false, imageninfo: boolean = true, 
  confirmButtonText:string = "Aceptar"){
    const dialogRef =  this.dialog.open(ModalGenericComponent,{ panelClass: 'my-class', data: {
      title: title,
      text: mensage,
      type: type,
      confirmButtonText: confirmButtonText,
      info: info,
      imageUrl:  'assets/magnify_loading.svg',
      imageAlt: 'Custom image',
      imagewidth: "300",
      imageheigth: "200",
      imageninfo: imageninfo
    }, 
      disableClose: disableClose
    });
    return  dialogRef;
  }

}
