import { Injectable } from "@angular/core";
import { AuthService } from "@app/core/auth.service";
import * as firebase from "firebase";
import { ConfigCustomer } from "@app/models/customer";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  public customerDB: firebase.database.Database = null;
  public customerSelected: ConfigCustomer = null;

  customer_route = "";
  api_customer_key = "";
  constructor(
    public http: HttpClient,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.api_customer_key = sessionStorage.getItem("api_customer_key");
  }

  getCustomersList(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });
    const url = environment.urlEndpointMaster + "/getAllCustomers";
    console.log(url);
    return this.http.get(url, { headers });
  }

  getCustomersListBigquery(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });
    const url = environment.urlEndpointMaster + "/getAllCustomersBigquery";
    console.log(url);
    return this.http.get(url, { headers });
  }

  getFilerNameCustomersBigquery(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });
    const url =
      environment.urlEndpointMaster + "/getFilerNameCustomersBigquery";
    console.log(url);
    return this.http.post(url, body, { headers });
  }

  getCustomer(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });
    const url = environment.urlEndpointMaster + "/getCustomerByID";
    return this.http.post(url, body, { headers });
  }

  saveCustomer(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });
    const url =
      environment.urlEndpointMaster +
      (!body.data.id ? "/createCustomer" : "/updateCustomer");
    return this.http.post(url, body, { headers });
  }

  inactivateCustomer(body: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: environment.headerAuthorization,
      "api-customer-key": this.api_customer_key,
      "sec-fetch-dest": "empty",
      "sec-fetch-mode": "cors",
      "sec-fetch-site": "sec-fetch-site",
    });

    const url = environment.urlEndpointMaster + "/disableCustomer";
    return this.http.post(url, body, { headers });
  }

  uploadLogo(uid: string, photo: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const ref = this.customerDB.app.storage().ref(`logo_customer/${uid}`);
      const part1 = photo.split(";")[1];
      const base64 = part1.split(",")[1];
      const task = ref.putString(base64, "base64", {
        contentType: "image/jpeg",
      });
      task
        .then(async () => {
          const downloadURL = await ref.getDownloadURL();
          resolve(downloadURL);
        })
        .catch((err) => {
          if (err.code == "storage/canceled") return resolve(null);
          console.log(err);
          reject(err.message);
        });
    });
  }
}
