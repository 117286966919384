export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCLmbfTyVIPQGFDmEJ1Babohrrb53wxHwI",
    authDomain: "trackpoint-d2fa6.firebaseapp.com",
    databaseURL: "https://trackpoint-d2fa6.firebaseio.com",
    projectId: "trackpoint-d2fa6",
    storageBucket: "trackpoint-d2fa6.appspot.com",
    messagingSenderId: "858055336962",
    appId: "1:858055336962:web:e3dd1856d8fe83afda331b",
    measurementId: "G-7Z7CFYKGTV",
  },
  urlEndpointMaster: "https://trackpoint-d2fa6.uc.r.appspot.com",
  // urlEndpointMaster: 'http://localhost:4000',
  headerAuthorization: "Bearer 975dd9f6-4a89-4825-9a6d-deae71304a30",
  consoleActive: false,
};
