import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from '../../detail-order/components/share-dialog/share-dialog.component';
import { StorageService } from '../../core/storage.service';
import { AuthService } from '../../core/auth.service';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../../core/helper/utils.helper';
import { UserService } from '../../core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramingService } from '../../services/programing.service';
import { CustomersService } from '../../customers.service';
import { ModalGenericService } from '../../services/modal-generic.service';
import { MatDialogRef } from '@angular/material';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
const { read, write, utils } = XLSX;
type AOA = any[][];
@Component({
  selector: 'app-programming-bulkload',
  templateUrl: './programming-bulkload.component.html',
  styleUrls: ['./programming-bulkload.component.scss']
})
export class ProgrammingBulkloadComponent implements OnInit {
  dataxls: AOA = [];
  programminglist: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialog: MatDialog,
  public storageService: StorageService,
  private spinner: NgxSpinnerService,
  public utilsService: UtilsService,
  public userService: UserService,
  public translateService: TranslateService,
  private programingservice: ProgramingService,
  public customerService: CustomersService,
  private modalGeneric: ModalGenericService,
  private router: Router,
  private dialogRef: MatDialogRef<ProgrammingBulkloadComponent>,
  public authService: AuthService) { }

  ngOnInit() {
  }

  donwloadXlsx(){
    if (this.storageService.getCurrentDB() != '') {
      const urlActividades = this.authService.customerSelected.url_excel_bulk ;
      console.log("urlActividades>>>>>>>>>>>", urlActividades)
      window.open(urlActividades, 'blank');
    }
  }

  returnerror(){
    return this.programminglist.filter((x) => x.estatus_row == "error");
  }
  deleteEvent(index){
    console.log(index);
    this.programminglist.splice(index, 1);
  }
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.dataxls = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 2 }));
      console.log(this.dataxls)
      //return false;
      if(this.dataxls.length > 0 ){
        let send = {
          data: this.dataxls,
          type: "validation"
        };
        this.spinner.show();
        this.programingservice
        .saveProgrammingBulkLoadEvent(send)
        .subscribe((response) => {
          this.spinner.hide();
          
          this.programminglist = response.data.data;
          if(this.programminglist.length > 3000 ){
            this.sendModelError('La cantidad de actividades supero el maximo permitido que son 3000.');
          }
          console.log('save this.programminglist', this.programminglist);
        });
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  public onCancelar() {
    this.dialogRef.close('cancelar');
  }
  upfile(){}

  sendSuccess(text: string) {
    this.modalGeneric
      .onModelBulkexito(
        false, //disableClose
        text, //message
        false, // cancelButton
        "Ver calendario", //confimButtonText
        true, // info
        false //imagen info
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef.close('aceptar');
        const ruta =
          '/' + this.storageService.getCurrentDB() + '/programming-list/calendar2';
        this.router.navigate([ruta]);
      });
  }

  sendModelError(msg: string) {
    this.spinner.hide();
    this.modalGeneric.onModelerror(
      false, //disableClose
      msg, //message
      false, // cancelButton
      this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
      true, // info
      false //imagen info
    );
  }

  saveMasive(){
    let send = {
      data: this.programminglist,
      type: "save"
    };
    this.spinner.show();
    this.programingservice
    .saveProgrammingBulkLoadEvent(send)
    .subscribe((response) => {
      this.spinner.hide();
      console.log(response);
      if (response.statusCode == 200) {
        this.sendSuccess('La actividad se ha programado correctamente.');
      } else {
        this.sendModelError('La actividad no pudo ser programada.');
      }
    });
  }

}
