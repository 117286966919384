import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ModalImageComponent } from '@app/user-list/modal-image/modal-image.component';
import { take } from 'rxjs/operators';

export interface DialogData {
  paramName: string;
}

@Component({
  selector: 'app-add-item-drag',
  templateUrl: './add-item-drag.component.html',
  styleUrls: ['./add-item-drag.component.scss']
})
export class AddItemDragComponent implements OnInit {

  public paramName_: string;
  public photos_: any[] = [];
  paramNameSend: string = '';
  errorName: string = '';
  text_title = 'Agregar tarea';
  previewUrl: any = '';
  fileData: File = null;
  is_clone = false;
  original_name = '';

  constructor(
    public dialogRef: MatDialogRef<AddItemDragComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public translateService: TranslateService,
  ) { 
    console.log('data passed in is:', this.data);
    this.paramNameSend = this.data['param_name_send'];
    this.paramName_ = this.data['name_item'];
    this.is_clone = this.data['is_clone'];
    if(this.data['name_item']){
      data.paramName = this.data['name_item'];
      this.text_title = 'Editar tarea';
      this.photos_ = this.data['photos_item'];
    }

    if(this.is_clone){
      this.text_title = 'Nombre de la tarea copiada:';
      this.original_name = this.data['param_name_send'];
      this.data['param_name_send'] = '';
      data.paramName = this.original_name + ' copia';
    }

  }

  ngOnInit() {
  }

  saveName(name){
    console.log('NAME ' + name.value);
    this.paramName_ = name.value;
    if(this.is_clone){
      if(this.paramName_ != ''){
        if(this.original_name == this.data.paramName){
          this.errorName =  this.translateService.instant('PAGES.ADD_ITEM_DIALOG.ERROR.DUPLCATE');
        }else{
          this.dialogRef.close();
        }
      }else{
        this.errorName =  this.translateService.instant('PAGES.ADD_ITEM_DIALOG.ERROR.EMPTY');
      }
    }else{
      if((this.paramNameSend == 'fotos de referencia' || this.paramNameSend == 'imágenes de referencia') && this.photos_.length == 0){
        this.errorName =  this.translateService.instant('PAGES.ADD_ITEM_DIALOG.ERROR.NO_PHOTOS');
        return; 
      }
      if(this.paramName_ != ''){
        this.dialog.closeAll();
      }else{
        this.errorName =  this.translateService.instant('PAGES.ADD_ITEM_DIALOG.ERROR.EMPTY');
      }
    }
  }

  imagesUpload(){


    const dialogRef =  this.dialog.open(ModalImageComponent,
    {
      data: {
        cutout_shape: 'square'
      }
    })
    .afterClosed()
    .pipe(take(1))
    .subscribe((resp: any) => {
      if(resp.button == "aceptar"){      
        this.previewUrl = resp.imageB64;
        this.photos_.push({photo_demo : this.previewUrl, photo_number: this.photos_.length + 1});
      }
    });
      // const input = document.getElementById('file');
      // input.click();
  }

  deleteImage(index: number){
    this.photos_.splice(index, 1);
  }

  closeDialog(){
    if(this.is_clone){
      this.dialogRef.close();
    }else{
      this.dialog.closeAll();
    }
  }

}
