import { Component, OnInit } from '@angular/core';
import { FormsService } from '../../core/forms.service';
import { AuthService } from '../../core/auth.service';
import { UtilsService } from '../../core/helper/utils.helper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../core/storage.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { FormularioAddComponent } from '../formulario-add/formulario-add.component';
import { take } from 'rxjs/operators';
import { AddItemDragComponent } from '@app/events/add-item-drag/add-item-drag.component';

@Component({
  selector: 'app-formulario-list',
  templateUrl: './formulario-list.component.html',
  styleUrls: ['./formulario-list.component.scss'],
})
export class FormularioListComponent implements OnInit {
  public forms_list: any = [];
  public forms_by_page: number = 10;
  public forms_current_page: number = 1;

  public forms_filter: string = '';

  public id_doc = '';

  get getFormsList() {
    return (
      this.forms_list &&
      this.forms_list
        .filter((f) => {
          return this.utilsService.search(f, 'displayName', this.forms_filter);
        })
        .filter((f) => {
          return !f.deleted;
        })
    );
  }

  round(value) {
    return Math.ceil(value);
  }

  updateCurrentPage(value) {
    try {
      if (this.forms_by_page !== 1) {
        if (value == 1) {
          this.forms_current_page = 1;
        }
      }
    } catch (err) {}
  }

  constructor(
    private dialogRef: MatDialogRef<AddItemDragComponent>,
    public formsService: FormsService,
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public modelGeneric: ModalGenericService
  ) {
    if (authService.customerDB != null) {
      formsService.customerDB = authService.customerDB;
      formsService.customerSelected = authService.customerSelected;
      this.spinner.show();
      console.log('PP formsService ');
      console.log(formsService);
      // let index = 0;
      formsService.getFormsList().subscribe((response) => {
        console.log('response GET FORM LIST');
        console.log(response);
        this.forms_list = response.data.map((r) => {
          console.log('RRRRR');
          console.log(r);

          if (r.date_created) {
            console.log('ENTER DATE CREATED');
            console.log(r.date_created);
            r.date_created = r.date_created;
          }

          // r.index = index;
          // index += 1;
          return { ...r, ...{ selected: false } };
        });
        this.spinner.hide();
      });
    }
  }

  ngOnInit() {
    // this.user_list = mockUsers;
  }

  pageChanged($event) {
    this.forms_current_page = $event;
  }

  updateFormDisabled(formulario) {
    formulario.disabled = !formulario.disabled;
    this.formsService
      .updateFormDisabled({
        id: formulario.uid,
        disabled: formulario.disabled,
      })
      .subscribe((response) => {
        this.toastr.success(
          'Form ' + (formulario.disabled ? 'disabled' : 'enabled'),
          'Succesfull update!'
        );
      });
  }

  cloneFormulario(formulario){

    let param_name_send = formulario.displayName;
    let is_clone = true;

    const dialogRef = this.dialog.open(AddItemDragComponent, {
      height: 'auto',
      width: '40%',
      data: {
        param_name_send,
        is_clone
      },
    });


    dialogRef.afterClosed().subscribe(() => {
      this.spinner.show();
      var param_name = dialogRef.componentInstance.paramName_;

      formulario.new_name = param_name;
      this.formsService.cloneQuestionary(formulario).subscribe((res) => {
        if(res.status == 'ok'){
          this.spinner.hide();
          this.modelGeneric
          .onModelexito(
            false, //disableClose
            this.translateService.instant('PAGES.FORM_DELETE.CLONED'), //message
            false, // cancelButton
            this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
            true, // info
            false //imagen info
          )
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => this.reloadForms());
        }else{
          this.spinner.hide();
          this.modelGeneric.onModelerror(
            true,
            this.translateService.instant(
              'PAGES.FORM_DELETE.ERRORS.CLONED'
            ),
            false
          );
        }
      })
    });
  }

  deleteFormulario(formulario) {
    this.modelGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant('PAGES.FORM_DELETE.R_U_SURE_TEXT'), //message
        true, // cancelButton
        'OK', //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp === 'aceptar') {
          this.spinner.show();
          this.formsService
            .deleteForm({ id: formulario.uid })
            .subscribe((response) => {
              this.spinner.hide();
              if (response.status === 'ok') {
                this.modelGeneric
                  .onModelexito(
                    false, //disableClose
                    this.translateService.instant('PAGES.FORM_DELETE.DELETED'), //message
                    false, // cancelButton
                    this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                    true, // info
                    false //imagen info
                  )
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(() => this.reloadForms());
              } else {
                this.modelGeneric.onModelerror(
                  true,
                  this.translateService.instant(
                    'PAGES.FORM_DELETE.ERRORS.RESPONSE'
                  ),
                  false
                );
              }
            });
        }
      });
  }

  cancelModal() {
    this.dialog.closeAll();
  }

  changueCheck(item) {
    if (item.check) {
      item.check = false;
    } else {
      item.check = true;
    }
    console.log('item');
    console.log(item);

    console.log('forms_list');
    console.log(this.forms_list);
  }

  saveCheck() {
    var enter = true;

    for (var i = 0; i < this.forms_list.length; i++) {
      if (this.forms_list[i].check) {
        enter = false;
        this.dialogRef.close('nuevo');

        break;
      }
    }

    if (enter) {
      console.log('NO CHECK');
      const dialogRef = this.modelGeneric.onModelerror(
        true,
        'Por favor, selecciona por lo menos una tarea a agregar.',
        false
      );

      dialogRef.afterClosed().subscribe((data) => {
        console.log('DATAA ' + data);

        if (data == 'aceptar') {
          dialogRef.close();
        }
      });
    }
  }

  addFormulario(type = "") {
    const dialogRef = this.dialog.open(FormularioAddComponent, {
      height: '80%',
      width: '80%',
      data: {
        id: '',
        type:type
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      var param_response = dialogRef.componentInstance.paramResponse;
      this.id_doc = dialogRef.componentInstance.id_doc;

      if (this.id_doc != '') {
      } else {
      }

      console.log('id_doc ' + this.id_doc);
      console.log('param_response ' + param_response);
      if (param_response === 'AGREGADO') {
        this.reloadForms();
      }
      // if(response == 'aceptar'){
      //   console.log('SI CHECK');
      //   this.dialog.closeAll();
      // }
    });
  }

  reloadForms() {
    this.spinner.show();
    this.formsService.getFormsList().subscribe((response) => {
      this.forms_list = response.data.map((r) => {
        return { ...r, ...{ selected: false } };
      });

      if (this.id_doc != '') {
        for (var i = 0; i < this.forms_list.length; i++) {
          if (this.forms_list[i].uid == this.id_doc) {
            this.forms_list[i].check = true;
            this.dialogRef.close('3');
            this.spinner.hide();
            break;
          }
        }
      } else {
        this.spinner.hide();
      }
    });
  }

  goEditQuestion(item) {
    console.log('EVVENT ' + JSON.stringify(item));

    const dialogRef = this.dialog.open(FormularioAddComponent, {
      height: '80%',
      width: '80%',
      data: {
        id: item.uid,
        type:""
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      var new_name = dialogRef.componentInstance.new_name;
      item.displayName = new_name;
    });
  }
}
