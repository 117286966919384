import { Component, OnInit } from '@angular/core';
import { FormsService } from '@app/core/forms.service';
import { AuthService } from '@app/core/auth.service';
import { UtilsService } from '@app/core/helper/utils.helper';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@app/core/storage.service';
import { CustomersService } from '../../customers.service';

import { take } from 'rxjs/operators';
import { ModalGenericService } from '@app/services/modal-generic.service';
import { PermissionService } from '../../core/permission.service';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss'],
})
export class CustomerTableComponent implements OnInit {
  public customers_by_page: number = 10;
  public customer_current_page: number = 1;

  public customer_filter: string = '';

  private customersList: any[];

  get getFormsList() {
    return (
      this.customersList &&
      this.customersList
        .filter((f) => {
          return this.utilsService.search(f, 'name', this.customer_filter);
        })
        .filter((f) => {
          return f.status !== 'D';
        }).sort(function (a, b) {
          if (a.name.toUpperCase()  > b.name.toUpperCase() ) {
            return 1;
          }
          if (a.name.toUpperCase()   < b.name.toUpperCase()  ) {
            return -1;
          }
          return 0;
        })
    );
  }

  pageChanged($event) {
    this.customer_current_page = $event;
  }

  round(value) {
    return Math.ceil(value);
  }

  updateCurrentPage(value) {
    try {
      if (this.customers_by_page !== 1) {
        if (value == 1) {
          this.customer_current_page = 1;
        }
      }
    } catch (err) {}
  }

  constructor(
    public authService: AuthService,
    public utilsService: UtilsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public translateService: TranslateService,
    public storageService: StorageService,
    public customerService: CustomersService,
    private modalGeneric: ModalGenericService,
    public permisionService: PermissionService,

  ) {
    if (authService.customerDB != null) {
      customerService.customerDB = authService.customerDB;
      customerService.customerSelected = authService.customerSelected;
      this.spinner.show();
      customerService.getCustomersList().subscribe(
        (response) => {
          console.log(response);
          if (response) {
            this.customersList = response.data.map((r) => {
              return { ...r, ...{ selected: false } };
            });
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  ngOnInit() {}

  deleteCustomer(cust) {
    this.modalGeneric
      .onModelconfirmacion(
        false, //disableClose
        this.translateService.instant(
          'PAGES.CUSTOMER_LIST.ADD.CUSTOMER_R_SURE'
        ), //message
        true, // cancelButton
        this.translateService.instant(
          'PAGES.USER_LIST.LIST.THUMBNAIL.CONFIRM_DELETE'
        ), //confimButtonText
        true, // info
        false //imagen info
        //title
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp === 'aceptar') {
          this.spinner.show();
          this.customerService
            .inactivateCustomer(cust)
            .subscribe((response) => {
              this.spinner.hide();
              if (response.status === 'ok') {
                this.modalGeneric
                  .onModelexito(
                    false, //disableClose
                    this.translateService.instant(
                      'PAGES.CUSTOMER_LIST.ADD.CUSTOMER_INACTIVATED'
                    ), //message
                    false, // cancelButton
                    this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                    true, // info
                    false //imagen info
                  )
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.spinner.show();
                    this.customerService
                      .getCustomersList()
                      .subscribe((response) => {
                        this.customersList = response.data.map((r) => {
                          return { ...r, ...{ selected: false } };
                        });
                        this.spinner.hide();
                      });
                  });
              } else {
                this.spinner.hide();
                this.modalGeneric.onModelerror(
                  false, //disableClose
                  this.translateService.instant(
                    'PAGES.FORM_DELETE.ERRORS.RESPONSE'
                  ), //message
                  false, // cancelButton
                  this.translateService.instant('MESSAGE.ACEPTAR'), //confimButtonText
                  true, // info
                  false //imagen info
                );
              }
            });
        }
      });
  }
}
